import React from "react";
import {
    Route,
    RouteProps,
    RouteComponentProps
} from "react-router";

import {AppLayout} from "src/views/layouts";


interface Props extends RouteProps {}

class AppRoute extends React.Component<Props, any> {
    render() {
        const {
            component: Component,
            ...rest
        } = this.props;

        return (
            <Route
              {...rest}
              render={(props:RouteComponentProps<any>) => {
                return (
                    <AppLayout>
                        {Component && (
                            <Component {...props} />
                        )}
                    </AppLayout>
                );
              }} />
        );
    }
}


export default AppRoute;