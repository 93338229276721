import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import * as lodash from "lodash";
import {
    Button,
    Grid
} from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from "@material-ui/icons";

import {
    IBounds
} from "src/types";

import {
    PageScroll,
    PageSidebarHeader,
    PageSidebarContent,
    CommentList
} from "src/views/blocks";

import RatingComment from "../../../../../RatingPage/blocks/RatingComment";

import store, {TileComment} from "src/store";


interface Props {
    parameterId?:string;
    bounds?:IBounds;
    quadKeys?:string[];
}

interface State {
    value:number|null;
    count:number;
    page:number;
    orderBy:string;
    orderWay:string;
}

interface RState {
    find:(name:string, query?:any) => any[];
    getMeta:(name:string, query?:any) => any;
}

interface RActions {
    search:(name:string, query?:any, count?:number, page?:number) => Promise<any[]>;
}

interface CProps extends Props, RState, RActions {}


class RatingViewComments extends React.Component<CProps, State> {
    get filters():any {
        const {
            parameterId,
            quadKeys,
            bounds
        } = this.props;

        const {
            value,
            orderBy,
            orderWay
        } = this.state;

        return {
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                quadKeys: quadKeys
            }),
            ...(value !== null ? {value: value} : {}),
            orderBy: orderBy,
            orderWay: orderWay
        };
    }

    get isLoading():boolean {
        const {
            loading = false
        } = this.props.getMeta(TileComment.modelName, this.filters);

        return loading;
    }

    get maxPage():number {
        const {
            count
        } = this.state;

        const {
            total = 0
        } = this.props.getMeta(TileComment.modelName, this.filters);

        return Math.ceil(total / count);
    }

    constructor(props:CProps) {
        super(props);

        this.state = {
            value: null,
            count: 10,
            page: -1,
            orderBy: "date",
            orderWay: "desc"
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps:Readonly<CProps>, prevState:Readonly<State>, snapshot?:any) {
        const {
            parameterId,
            quadKeys = []
        } = this.props;

        if(
            (quadKeys.length && !lodash.isEqual(quadKeys, prevProps.quadKeys)) ||
            parameterId !== prevProps.parameterId
        ) {
            this.load();
        }
    }

    setOrder(orderBy:string) {
        if(this.state.orderBy === orderBy) {
            this.setState({
                orderWay: this.state.orderWay === "asc" ? "desc" : "asc"
            }, () => {
                 this.load();
            });
        }
        else {
            this.setState({
                orderBy: orderBy,
                orderWay: "desc"
            }, () => {
                this.load();
            });
        }
    }

    setValue(value:number|null) {
        this.setState({
            value: value
        }, () => {
            this.load();
        });
    }

    load() {
        this.setState({
            page: -1
        }, () => {
            this.nextPage();
        });
    }

    nextPage() {
        const {
            count,
            page
        } = this.state;

        if(page === -1 || (page + 1 < this.maxPage && !this.isLoading)) {
            this.setState({
                page: page + 1
            });

            let ignore = this.props.search(TileComment.modelName, this.filters, count, page + 1);
        }
    }

    render() {
        const {
            value,
            orderBy,
            orderWay
        } = this.state;

        let comments:any = this.props.find(TileComment.modelName, this.filters);

        return (
            <Grid container
              style={{
                flex: 1
              }}
              direction="column">
                <PageSidebarContent
                  style={{
                    flex: 0,
                    flexBasis: "35px"
                  }}>
                    <PageSidebarHeader item
                      style={{
                        flex: 1,
                        flexBasis: "auto",
                        padding: 0
                      }}
                      alignItems="center"
                      justifyContent="space-between"
                      direction="row">
                        <Grid item>
                            {
                                [
                                    {
                                        title: "Popularity",
                                        name: "likes"
                                    },
                                    {
                                        title: "Date",
                                        name: "date"
                                    }
                                ].map((field, index:number) => {
                                    let endIcon;

                                    if(orderBy === field.name) {
                                        switch(orderWay) {
                                            case "asc":
                                                endIcon = <ArrowDownwardIcon />;
                                                break;

                                            case "desc":
                                                endIcon = <ArrowUpwardIcon />;
                                                break;
                                        }
                                    }

                                    return (
                                        <Button
                                          key={index}
                                          variant="text"
                                          size="small"
                                          endIcon={endIcon}
                                          onClick={() => this.setOrder(field.name)}>
                                            {field.title}
                                        </Button>
                                    );
                                })
                            }
                        </Grid>

                        <Grid item>
                            {
                                [
                                    {
                                        title: "All",
                                        value: null
                                    },
                                    {
                                        title: "Positive",
                                        value: 2
                                    },
                                    {
                                        title: "Neutral",
                                        value: 1
                                    },
                                    {
                                        title: "Negative",
                                        value: 0
                                    }
                                ].map((item, index:number) => {
                                    return (
                                        <Button
                                          key={index}
                                          variant="text"
                                          size="small"
                                          aria-selected={item.value === value}
                                          onClick={() => this.setValue(item.value)}>
                                            {item.title}
                                        </Button>
                                    );
                                })
                            }
                        </Grid>
                    </PageSidebarHeader>
                </PageSidebarContent>

                <PageScroll
                  loading={this.isLoading && comments.length === 0}
                  loadingBottom={this.isLoading && comments.length > 0}
                  onScrollEnd={() => this.nextPage()}>
                    <CommentList component={RatingComment}
                      ids={comments.map((comment:any) => comment.id)} />
                </PageScroll>
            </Grid>
        );
    }
}


const putState = ():RState => {
    return {
        find: store.orm.getters.find,
        getMeta: store.orm.getters.getMeta
    };
};

const putActions = ():RActions => {
    return {
        search: store.orm.search
    };
};

export default compose(
    connect(putState, putActions)
)(RatingViewComments);