import React from "react";
import {compose} from "redux";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Box,
    Grid,
    GridProps
} from "@material-ui/core";

import theme from "../../../../theme";


interface Props extends GridProps {
    deep?:number;
}

interface CProps extends Props {
    classes:any;
}


class Page extends React.Component<CProps, any> {
    render() {
        const {
            children,
            className,
            deep = 0,
            ...rest
        } = this.props;

        return (
            <Box component={Grid} container
              style={{
                padding: theme.spacing(deep),
                flexWrap: "unset"
              }}
              className={[
                "page",
                className
              ].join(" ")}
              direction="column"
              {...rest}>
                {children}
            </Box>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        // [theme.breakpoints.down("md")]: {
        //     padding: "0 20px"
        // }
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(Page);