import * as lodash from "lodash";

import {Bounds, IPoint, IBounds} from "./Bounds";


class QuadTile {
    x:number;
    y:number;
    zoom:number;

    constructor(x:number, y:number, zoom:number) {
        this.x = x;
        this.y = y;
        this.zoom = zoom;
    }

    getZoom():number {
        return this.zoom;
    }

    getQuadKey():string {
        let quadKey:number[] = [];

        for(let z = this.zoom; z > 0; z -= 1) {
            let digit = 0;
            let mask = 1 << (z - 1);

            if((this.x & mask) !== 0) {
                digit += 1;
            }

            if((this.y & mask) !== 0) {
                digit += 2;
            }

            quadKey.push(digit);
        }

        return quadKey.join("");
    }

    getBounds():Bounds {
        return Bounds.fromPoints(
            this.getTopLeftCorner(),
            this.getBottomRightCorner()
        ) as Bounds;
    }

    getSize() {
        return this.getBounds().getHeight();
    }

    getCenter():IPoint {
        const point1:IPoint = this.getTopLeftCorner();
        const point2:IPoint = this.getBottomRightCorner();

        return {
            lat: (point1.lat + point2.lat) / 2,
            lng: (point1.lng + point2.lng) / 2
        };
    }

    getTopLeftCorner():IPoint {
        let n = Math.pow(2, this.zoom);

        return {
            lng: this.x / n * 360 - 180,
            lat: Math.atan(Math.sinh(Math.PI * (1 - 2 * this.y / n))) / Math.PI * 180
        };
    }

    getBottomRightCorner():IPoint {
        return this.getBottomRightTile().getTopLeftCorner();
    }

    getOffsetTile(offsetX:number, offsetY:number):QuadTile {
        return new QuadTile(this.x + offsetX, this.y + offsetY, this.zoom);
    }

    getRightTile():QuadTile {
        return this.getOffsetTile(1, 0);
    }

    getBottomTile():QuadTile {
        return this.getOffsetTile(0, 1);
    }

    getBottomRightTile():QuadTile {
        return this.getOffsetTile(1, 1);
    }

    hasPoint(point:IPoint):boolean {
        return this.getBounds().hasPoint(point);
    }

    inBounds(areaBounds:IBounds):boolean {
        let bounds:IBounds = this.getBounds();

        return (
            (
                areaBounds.south <= bounds.south && bounds.south < areaBounds.north &&
                areaBounds.west <= bounds.west && bounds.west < areaBounds.east
            ) ||
            (
                bounds.south <= areaBounds.south && areaBounds.south < bounds.north &&
                bounds.west <= areaBounds.west && areaBounds.west < bounds.east
            ) ||
            (
                areaBounds.south <= bounds.south && bounds.south < areaBounds.north &&
                bounds.west <= areaBounds.west && areaBounds.west < bounds.east
            ) ||
            (
                bounds.south <= areaBounds.south && areaBounds.south < bounds.north &&
                areaBounds.west <= bounds.west && bounds.west < areaBounds.east
            )
        );
    }

    toString() {
        return [this.x, this.y, this.zoom].toString();
    }

    static fromQuadKey(quadKey:string):QuadTile {
        let x = 0;
        let y = 0;
        let zoom = 0;

        quadKey = quadKey.split("").reverse().join("");

        for(let i = 0; i < quadKey.length; i++) {
            let mask = 1 << i;
            let char:string = quadKey.charAt(i);

            switch(char) {
                case "0":
                    break;

                case "1":
                    x = x ^ mask;
                    break;

                case "2":
                    y = y ^ mask;
                    break;

                case "3":
                    x = x ^ mask;
                    y = y ^ mask;
                    break;

                default:
                    throw new Error("Unexpected QuadKey digit: '" + char + "'");
            }

            zoom = i;
        }

        return new QuadTile(x, y, zoom + 1);
    }

    static fromLatLng(lat:number, lng:number, zoom:number):QuadTile {
        return QuadTile.fromPoint({lat, lng}, zoom);
    }

    static fromPoint(point:IPoint, zoom:number):QuadTile {
        let quadKey = "";

        for(let i = 0; i < zoom; i++) {
            for(let j = 0; j <= 3; j++) {
                let quadTile:QuadTile = QuadTile.fromQuadKey(quadKey + j.toString());

                if(quadTile.hasPoint(point)) {
                    quadKey += j;
                    break;
                }
            }
        }

        return QuadTile.fromQuadKey(quadKey);
    }

    static fromBounds(bounds:IBounds, zoom:number):QuadTile[] {
        let limit = 500;

        let areaStart:IPoint = {
            lat: bounds.north,
            lng: bounds.west
        };

        let quadTile:QuadTile = QuadTile.fromPoint(areaStart, zoom);

        if(lodash.isEqual(quadTile.getBottomRightCorner(), areaStart)) {
            quadTile = quadTile.getBottomRightTile();
        }

        let selected:QuadTile[] = [];

        do {
            if(quadTile.inBounds(bounds)) {
                selected.push(quadTile);
            }

            let rightTile:QuadTile = quadTile;
            let rightLimit = 500;

            do {
                rightTile = rightTile.getRightTile();

                if(rightTile.inBounds(bounds)) {
                    selected.push(rightTile);
                }

                rightLimit--;

                if(rightLimit < 0) {
                    break;
                }
            } while(rightTile.inBounds(bounds));

            quadTile = quadTile.getBottomTile();
            limit--;

            if(limit < 0) {
                break;
            }
        } while(quadTile.inBounds(bounds));

        return selected;
    }
}


export {QuadTile};

export default QuadTile;