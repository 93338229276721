import * as fns from "date-fns";

import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";
import {calcRate} from "src/tools";


interface ISuggestAnalytic {
    id:string;
    suggestId:string;
    quadKeys:string[];
    rate:number;
    votes:number;
    votesPositive:number;
    votesNeutral:number;
    votesNegative:number;
    votesSurroundingTotal:number;
    date:string;
}


class SuggestAnalytic extends Model<ISuggestAnalytic> {
    static modelName = "suggest-analytic";

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ISuggestAnalytic> {
        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(res.httpCode === 200) {
            const {
                response: {
                    count: total,
                    data: items,
                    summary
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items,
                summary
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }

    static prepare(data:any, method:string):ISuggestAnalytic {
        const {
            votesTotal: votes,
            votesPositive,
            votesNeutral,
            votesNegative,
            ...other
        } = data;

        return {
            ...other,
            votes,
            votesPositive,
            votesNeutral,
            votesNegative,
            rate: calcRate(votesPositive, votesNeutral, votesNegative)
        };
    }

    static listSummary(analytics:ISuggestAnalytic[], res:any, query:any) {
        if(query && query.withSummary) {
            const {
                summary: {
                    dates = {},
                    ...summary
                } = {}
            } = res || {};

            const minDate = Object.keys(dates).sort((a, b) => {
                return a < b ? -1 : 1;
            }).find(() => true);

            let months = 0;

            if(minDate) {
                let date = fns.parse(minDate, "yyyy-MM", new Date());

                if(fns.isValid(minDate)) {
                    months = fns.differenceInCalendarMonths(new Date(), date) + 1;
                }
            }

            return {
                totals: Array(Math.max(months, 12)).fill(0).map((ignore, index:number, arr:any[]) => {
                    let date = fns.subMonths(new Date, arr.length - 1 - index);

                    return fns.format(date, "yyyy-MM");
                }).reduce((totals:any[], date:string) => {
                    const {
                        [date]: {
                            votes = 0,
                            rate = null
                        } = {}
                    } = dates;

                    return [
                        ...totals,
                        {
                            date,
                            votes,
                            rate
                        }
                    ];
                }, []).map((total:any, index, arr) => {
                    let {
                        rate
                    } = total;

                    if(rate === null) {
                        rate = 0;

                        for(let i = index; i > 0; i--) {
                            if(arr[i].rate !== null) {
                                rate = arr[i].rate;

                                break;
                            }
                        }
                    }

                    return {
                        ...total,
                        rate
                    };
                }),
                ...summary
            };
        }

        return null;
    }
}


export type {ISuggestAnalytic};

export {SuggestAnalytic};