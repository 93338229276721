import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";


interface ISuggestCategory {
    id:string;
    title:string;
}

class SuggestCategory extends Model<ISuggestCategory> {
    static modelName:string = "suggest-category";

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ISuggestCategory> {
        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(typeof res.count === "number") {
            const {
                count: total,
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }
}


export type {ISuggestCategory};

export {SuggestCategory};