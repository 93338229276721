import React from "react";
import {
    Button,
    Divider,
    Grid,
    Typography
} from "@material-ui/core";
import * as fns from "date-fns";

import PlotBar from "./../PlotBar";


interface Props {
    period?:any;
    periods?:{
        title:string;
        value:number;
    }[];
    totalLabel?:any;
    totalValue?:number;
    averageLabel?:any;
    averageValue?:number;
    data?:any[];
    onChangePeriod?:(period:any) => void;
}


const DataQuality:React.FC<Props> = (props:Props) => {
    const {
        period,
        totalLabel = null,
        totalValue = 0,
        averageLabel = "Average general votes received",
        averageValue,
        data = [],
        periods = [],
        onChangePeriod
    } = props;

    const handleChangePeriod = (period:any) => {
        if(onChangePeriod) {
            onChangePeriod(period);
        }
    };

    return (
        <Grid container direction="column">
            <Grid container alignItems="flex-end">
                <Grid item component={Typography} variant="h5">
                    Data quality
                </Grid>

                <Grid item>
                    {
                        periods.map((item, index:number) => {
                            const active = period === item.value;

                            return (
                                <Button
                                  key={index}
                                  variant="text"
                                  size="small"
                                  aria-selected={active}
                                  onClick={() => handleChangePeriod(item.value)}>
                                    {item.title}
                                </Button>
                            );
                        })
                    }
                </Grid>
            </Grid>

            <Grid container
              style={{paddingTop: "10px", paddingBottom: "10px"}}
              justifyContent="space-between">
                <Grid item component={Typography} variant="body1">
                    {totalLabel}
                </Grid>

                <Grid item component={Typography} variant="body1">
                    {totalValue}
                </Grid>
            </Grid>

            <Divider color={"#B9B9B9"} />

            <Grid container
              style={{paddingTop: "10px", paddingBottom: "10px"}}
              justifyContent="space-between">
                <Grid item>
                    <Typography variant="body1" display="inline">
                        {averageLabel}
                    </Typography>

                    <Typography
                      style={{
                        marginLeft: "5px",
                        color: "#A2A2A2"
                      }}
                      variant="caption"
                      display="inline">
                        (surrounding km<sup>2</sup>)
                    </Typography>
                </Grid>

                <Grid item component={Typography} variant="body1">
                    {averageValue}
                </Grid>
            </Grid>

            <Typography variant="h6">
                Votes {period > 0 ? "past " + period + " months" : "all time"}
            </Typography>

            <PlotBar
              data={data.map((total:any) => {
                let date = fns.parse(total.date, "yyyy-MM", 0);

                return {
                    title: fns.isValid(date) ? fns.format(date, "MMM") : "",
                    value: total.votes || 0
                };
              })} />
        </Grid>
    );
};

DataQuality.defaultProps = {
    data: [],
    periods: [
        {title: "All time", value: -1},
        {title: "12 months", value: 12},
        {title: "3 months", value: 3}
    ]
};


export default DataQuality;