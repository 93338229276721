import React, {useEffect, Suspense} from "react";
import {Switch} from "react-router";

import {
    useApp,
    useAuth,
    useDebug
} from "src/hooks";

import {
    AppRoute,
    DashboardRoute
} from "./routes";

import {
    AppLoader,
    Home,
    AuthLogin,
    AuthLogout,
    AuthSignup,
    AuthPWReset,
    AuthPWUpdate,
    RatingPage,
    RatingViewPage,
    SuggestPage,
    SuggestViewPage,
    DemandPage,
    Error404
} from "./pages";

import store from "src/store";


const App:React.FC = () => {
    const app = useApp();
    const auth = useAuth();

    useDebug();

    useEffect(() => {
        const delay = Math.max(0, app.expires - 30 * 1000 - new Date().getTime());

        const timeout = setTimeout(() => {
            store.app.register();
        }, delay);

        return () => clearTimeout(timeout);
    }, [app.token]);

    useEffect(() => {
        if(app.isRegistered && auth.isLoggedIn) {
            const delay = Math.max(0, auth.expires - 30 * 1000 - new Date().getTime());

            const timeout = setTimeout(() => {
                store.auth.refresh();
            }, delay);

            return () => clearTimeout(timeout);
        }
    }, [app.token, auth.token]);

    return (
        <Suspense fallback={<AppLoader />}>
            <Switch>
                <AppRoute exact path="/" component={Home} />
                <AppRoute exact path="/login" component={AuthLogin} />
                <AppRoute exact path="/signup" component={AuthSignup} />
                <AppRoute exact path="/logout" component={AuthLogout} />
                <AppRoute exact path="/reset" component={AuthPWReset} />
                <AppRoute exact path="/pw" component={AuthPWUpdate} />

                <DashboardRoute exact path="/rating" component={RatingPage} />
                <DashboardRoute exact path="/rating/:parameterId" component={RatingPage} />
                <DashboardRoute exact path="/rating-view" component={RatingViewPage} />
                <DashboardRoute exact path="/rating-view/filtered" component={RatingViewPage} />
                <DashboardRoute exact path="/rating-view/parameter" component={RatingViewPage} />
                <DashboardRoute path="/rating-view/parameter/:parameterId" component={RatingViewPage} />
                <DashboardRoute exact path="/suggestion" component={SuggestPage} />
                <DashboardRoute path="/suggestion/map/:suggestId" component={SuggestPage} />
                <DashboardRoute path="/suggestion/view/:suggestId" component={SuggestViewPage} />
                <DashboardRoute exact path="/demand" component={DemandPage} />
                <DashboardRoute exact path="/demand/:categoryId" component={DemandPage} />

                <AppRoute component={Error404} />
            </Switch>
        </Suspense>
    );
};


export default App;