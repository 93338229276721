import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";

import {LoginForm} from "src/views/forms";

import store from "src/store";


interface Props {}

interface RState {
    isLoggedIn:boolean;
}

interface RActions {
    login:(data:any) => Promise<void>;
}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class AuthLogin extends React.Component<CProps, any> {
    componentDidMount() {
        if(this.props.isLoggedIn) {
            this.props.history.push("/rating");
        }
    }

    async onSubmit(data:any) {
        const res = await this.props.login(data);

        if(this.props.isLoggedIn) {
            this.props.history.push("/rating");
        }
    }

    render() {
        return (
            <LoginForm
              onSubmit={(data:any) => {
                return this.onSubmit(data);
              }} />
        );
    }
}


const putState = ():RState => {
    return {
        isLoggedIn: store.auth.getters.isLoggedIn
    };
};

const putActions = ():RActions => {
    return {
        login: store.auth.login
    };
};

export default compose(
    connect(putState, putActions)
)(AuthLogin);