import React, {ReactNode} from "react";
import {
    Grid,
    List,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Typography
} from "@material-ui/core";
import {PlayArrow as PlayArrowIcon} from "@material-ui/icons";


type Props = {
    children?:ReactNode;
    disabled?:boolean;
    icon?:string;
    title?:string;
};

const CategoryList:React.FC<Props> = (props:Props) => {
    const {
        disabled = false,
        children,
        icon,
        title
    } = props;

    return (
        <Accordion disabled={disabled}>
            <AccordionSummary
              expandIcon={<PlayArrowIcon style={{transform: "rotate(90deg)"}} />}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Avatar
                          src={icon} />
                    </Grid>

                    <Grid item component={Typography}
                      style={{paddingLeft: "17px"}}
                      variant="body1">
                        {title}
                    </Grid>
                </Grid>
            </AccordionSummary>

            <AccordionDetails>
                <Grid component={List} container dense
                  direction="column">
                    {children}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};


export default CategoryList;