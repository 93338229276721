import {IModule} from "kpdux";
import url from "url";

import {QueryString} from "src/makes/QueryString";


type State = {
    API_URL:string;
};

const apiFactory = (API_URL:string):IModule<State> => {
    const api:IModule<State> = {
        state: {
            API_URL: API_URL
        },
        getters: {
            urlData(state:State) {
                return url.parse(state.API_URL);
            },
            getUrl(state:State, getters) {
                return (path:string = "/", query:any = {}) => {
                    const urlData = getters.urlData;

                    let sq = QueryString.stringify(query);

                    return url.format({
                        protocol: urlData.protocol,
                        hostname: urlData.hostname,
                        port: urlData.port,
                        pathname: path
                    }) + (sq ? "?" + sq : "");
                };
            },
            toFormData(state, getters) {
                return (data:any, key:string, form:FormData) => {
                    if(!form) {
                        form = new FormData();
                    }

                    for(let i in data) {
                        let value = data[i];
                        let k = key ? (key + "[" + i + "]") : i;

                        let lowTypes = ["string", "number", "boolean", "symbol", "undefined"];

                        if(lowTypes.includes(typeof value)) {
                            switch(typeof value) {
                                case "undefined":
                                    break;

                                case "boolean":
                                    if(value) {
                                        form.append(k, "1");
                                    }
                                    else {
                                        form.append(k, "0");
                                    }
                                    break;

                                default:
                                    form.append(k, value);
                                    break;
                            }
                        }
                        else if(typeof value === "object") {
                            if(Array.isArray(value)) {
                                for(let j in value) {
                                    let v = value[j];

                                    getters.toFormData(v, k + "[" + j + "]", form);
                                }
                            }
                            else if(value instanceof File) {
                                form.append(k, value);
                            }
                            else if(value instanceof Date) {
                                // TODO install moment
                                // let v = moment(value).utcOffset("+03:00").format("YYYY-MM-DDTHH:mm:ss.SSSZ");

                                // form.append(k, v);
                            }
                            else if(value) {
                                getters.toFormData(value, k, form);
                            }
                        }
                    }

                    return form;
                };
            }
        },
        actions: {
            async get(path:string, options:any = {}) {
                return this.send("get", path, options);
            },
            async post(path:string, options:any = {}) {
                return this.send("post", path, options);
            },
            async put(path:string, options:any = {}) {
                return this.send("put", path, options);
            },
            async send(type:string, path:string, options:any = {}) {
                const {
                    parse = "json",
                    data = {},
                    headers = []
                } = options;

                let url:string = this.getters.getUrl(path, type === "get" ? data : {});

                let headersInit:HeadersInit = new Headers();

                let contentType = "";

                for(let i in headers) {
                    if(i.toLowerCase() === "content-type") {
                        let [
                            ignore,
                            header,
                            params
                        ] = new RegExp("([\\w\\n/-]+)(?:;([\\w\\n\\s;=-]+))?").exec(headers[i]) || [];

                        if(header) {
                            contentType = header.toLowerCase();
                        }
                    }

                    headersInit.set(i, headers[i]);
                }

                let body = undefined;

                if(type !== "get") {
                    switch(contentType) {
                        case "application/x-www-form-urlencoded":
                            body = Object.keys(data).map((key:string) => {
                                return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                            }).join("&");
                            break;

                        case "multipart/form-data":
                        default:
                            body = this.getters.toFormData(data);
                            break;
                    }
                }

                return fetch(url, {
                    mode: "cors",
                    method: type,
                    headers: headers,
                    body: body
                }).then((res) => {
                    switch(parse) {
                        case "json":
                            return res.json().catch((err:any) => {
                                console.error(err);

                                return {};
                            });

                        case "raw":
                        case "text":
                            return res.text();
                    }
                });
            }
        }
    };

    return api;
};


export type {State as ApiState};

export {apiFactory};