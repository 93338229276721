import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import * as lodash from "lodash";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from "@material-ui/icons";

import {
    IBounds,
    ISuggest
} from "src/types";

import {Bounds} from "src/makes/Bounds";

import {
    PageColumn,
    PageScroll
} from "src/views/blocks";

import {
    SuggestListItem
} from "../../blocks";

import store, {Suggest} from "src/store";


interface Props {}

interface RState {
    mapQuadKeys?:string[];
    mapBounds?:IBounds;
    find:(name:string, query?:any) => any[];
    filter:(name:string, query?:any) => any[];
    getMeta:(name:string, query?:any) => any;
}

interface RActions {
    search:(name:string, query?:any, count?:number, page?:number) => Promise<any>;
}

interface CProps extends Props, RState, RActions, WithStyles {}


class SuggestList extends React.Component<CProps, any> {
    get query() {
        const {
            mapBounds
        } = this.props;

        const {
            orderBy,
            orderWay
        } = this.state;

        const bounds:Bounds|null = Bounds.fromBounds(...(mapBounds ? [mapBounds] : []));

        return {
            ...bounds ? {
                bounds: bounds.toOBJECT()
            } : {},
            orderBy,
            orderWay
        };
    }

    get loading() {
        const {
            loading = false
        } = this.props.getMeta(Suggest.modelName, this.query);

        return loading;
    }

    get total() {
        const {
            total = 0
        } = this.props.getMeta(Suggest.modelName, this.query);

        return total;
    }

    constructor(props:CProps) {
        super(props);

        this.state = {
            ids: [],
            count: 20,
            page: -1,
            orderBy: "rate",
            orderWay: "desc",
            isNeedToUpdateSuggestionList: true
        };
    }

    async componentDidMount() {
        await this.load();
    }

    async componentDidUpdate(prevProps:Readonly<CProps>) {
        const {
            mapQuadKeys,
            mapBounds
        } = this.props;

        let hasChanges = false;

        if(!lodash.isEqual(mapQuadKeys, prevProps.mapQuadKeys)) {
            hasChanges = true;
        }

        if(!lodash.isEqual(mapBounds, prevProps.mapBounds)) {
            hasChanges = true;
        }

        if(hasChanges) {
            await this.load();
        }
    }

    setOrder(orderBy:string) {
        if(this.state.orderBy === orderBy) {
            this.setState({
                orderWay: this.state.orderWay === "asc" ? "desc" : "asc"
            }, () => {
                this.load();
            });
        }
        else {
            this.setState({
                orderBy: orderBy,
                orderWay: "desc"
            }, () => {
                this.load();
            });
        }
    }

    load() {
        const {
            mapQuadKeys
        } = this.props;

        if(mapQuadKeys && mapQuadKeys.length > 0) {
            this.setState({
                page: -1
            }, () => {
                this.nextPage();
            });
        }
    }

    async nextPage() {
        const {
            page,
            count,
            isNeedToUpdateSuggestionList
        } = this.state;

        if(!this.loading && isNeedToUpdateSuggestionList) {
            const res = await this.props.search("suggest", this.query, count, page + 1);

            if(res.status === "OK") {
                this.setState({
                    page: page + 1
                });
            }
        }
    }

    render() {
        const {
            mapQuadKeys
        } = this.props;

        const {
            orderBy,
            orderWay
        } = this.state;

        const suggests:ISuggest[] = this.props.find(Suggest.modelName, this.query);

        const {
            total = 0
        } = this.props.getMeta("suggest", this.query);
        const bounds = (mapQuadKeys || []).length ? Bounds.fromQuadKeys(...(mapQuadKeys || [])) : null;

        return (
            <React.Fragment>
                {!bounds || bounds.getHeight() > 2000 ? (
                    <Grid container
                      style={{
                        height: "100%"
                      }}
                      direction="column"
                      justifyContent="center"
                      alignItems="center">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontWeight: "normal",
                            fontStyle: "italic"
                          }}
                          variant="h5">
                            Zoom in to see the results
                        </Typography>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <PageColumn>
                            <Grid container
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px"
                              }}
                              alignItems="center"
                              justifyContent="space-between">
                                <Grid item>
                                    <Typography
                                      style={{
                                        fontSize: "18px"
                                      }}>
                                        {total} suggestions
                                    </Typography>
                                </Grid>

                                <Grid item
                                  style={{
                                    paddingRight: "25px"
                                  }}>
                                    {
                                        [
                                            {
                                                title: "Popularity",
                                                name: "rate"
                                            },
                                            {
                                                title: "Date",
                                                name: "date"
                                            },
                                            {
                                                title: "Votes",
                                                name: "votes"
                                            }
                                        ].map((field, index:number) => {
                                            let endIcon;
                                            let selected = false;

                                            if(orderBy === field.name) {
                                                selected = true;

                                                switch(orderWay) {
                                                    case "asc":
                                                        endIcon = <ArrowDownwardIcon />;
                                                        break;

                                                    case "desc":
                                                        endIcon = <ArrowUpwardIcon />;
                                                        break;
                                                }
                                            }

                                            return (
                                                <Button
                                                  key={index}
                                                  style={selected ? {} : {
                                                    paddingLeft: "12px",
                                                    paddingRight: "35px"
                                                  }}
                                                  color="primary"
                                                  variant={selected ? "outlined" : "text"}
                                                  endIcon={endIcon}
                                                  onClick={() => this.setOrder(field.name)}>
                                                    {field.title}
                                                </Button>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </PageColumn>

                        <PageColumn style={{flex: 1}}>
                            <PageScroll
                              loading={this.loading && this.total === 0}
                              loadingBottom={this.loading && this.total !== 0}
                              onScrollEnd={() => this.nextPage()}>
                                {
                                    suggests.map((suggest:ISuggest, index:number) => {
                                        return (
                                            <SuggestListItem key={index}
                                              id={suggest.id} />
                                        );
                                    })
                                }
                            </PageScroll>
                        </PageColumn>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {},
    pageScrollContent: {
        [theme.breakpoints.up("lg")]: {
            paddingRight: 10
        }
    }
});

const putState = (state:any):RState => {
    return {
        mapQuadKeys: state.ui.mapQuadKeys,
        mapBounds: state.ui.mapBounds,
        find: store.orm.getters.find,
        getMeta: store.orm.getters.getMeta,
        filter: store.orm.getters.filter
    };
};

const putActions = ():RActions => {
    return {
        search: store.orm.search
    };
};

export default compose<React.ComponentType<Props>>(
    withStyles(styles),
    connect(putState, putActions)
)(SuggestList);