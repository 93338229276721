import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {SubmissionError} from "redux-form";
import {RouteComponentProps} from "react-router";
import {
    withSnackbar,
    WithSnackbarProps
} from "notistack";

import QueryString from "../../../makes/QueryString";

import {
    PWUpdateForm,
    PWResetFormData
} from "../../forms";

import store from "../../../store";


interface Props extends RouteComponentProps {}

interface RState {
    email?:string;
    code?:string;
    isLoggedIn:boolean;
}

interface RActions {
    passwordUpdate:(data:any) => Promise<any>;
}

interface CProps extends Props, RState, RActions, WithSnackbarProps {}


class AuthPWUpdate extends React.Component<CProps, any> {
    constructor(props:CProps) {
        super(props);

        this.state = {
            disabled: false
        };
    }

    componentDidMount() {
        const {
            history,
            code
        } = this.props;

        if(!code) {
            this.props.enqueueSnackbar("Error", {
                variant: "error"
            });

            history.replace("/login");
        }
    }

    async onSubmit(data:PWResetFormData) {
        const {
            code
        } = this.props;

        const res = await this.props.passwordUpdate({
            code: code,
            ...data
        });

        if(res.status === "OK") {
            if(res.message) {
                this.props.enqueueSnackbar(res.message, {
                    variant: "success"
                });
            }

            this.props.history.push("/");
        }
        else {
            if(res.message) {
                this.props.enqueueSnackbar(res.message, {
                    variant: "error"
                });
            }

            if(res.errors) {
                throw new SubmissionError(res.errors);
            }
        }
    }

    render() {
        return (
            <PWUpdateForm
              onSubmit={(data:PWResetFormData) => this.onSubmit(data)} />
        );
    }
}


const putState = (state:any, props:Props):RState => {
    const {
        email,
        c: code
    } = QueryString.parse(props.location.search);

    return {
        isLoggedIn: store.auth.getters.isLoggedIn,
        email: (email as string) || "",
        code: (code as string) || ""
    };
};

const putActions = ():RActions => {
    return {
        passwordUpdate: store.auth.passwordUpdate
    };
};

export default compose<React.ComponentType<Props>>(
    withSnackbar,
    connect(putState, putActions)
)(AuthPWUpdate);