import React, {forwardRef} from "react";
import {makeStyles} from "@material-ui/core";

import "./index.scss";


interface Props {
    className?:string;
    style?:React.CSSProperties;
    color?:string;
    src?:string;
}

// eslint-disable-next-line react/display-name
const ParameterIcon = forwardRef((props:Props, ref:any) => {
    const {
        className,
        color,
        src = "",
        ...rest
    } = props;

    const classes = useStyles(props);

    return (
        <div {...rest}
          ref={ref}
          className={[
            classes.root,
            classes.background,
            className || ""
          ].join(" ")} />
    );
});


const useStyles = makeStyles(() => {
    return {
        root: {},
        background: (props:Props) => {
            const {
                src,
                color
            } = props;

            return {
                ...src ? {
                    mask: `url("${src}")`,
                    maskSize: "contain",
                    maskPosition: "center",
                    maskRepeat: "no-repeat"
                } : {},
                ...color ? {
                    backgroundColor: color
                } : {}
            };
        }
    };
});

export default ParameterIcon;