import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    RouteComponentProps
} from "react-router";
import {
    CircularProgress
} from "@material-ui/core";

import store from "../../../store";


interface Props {}

interface RState {
    isLoggedIn:boolean;
}

interface RActions {
    logout:() => Promise<any>;
}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class AuthLogout extends React.Component<CProps, any> {
    async componentDidMount() {
        if(this.props.isLoggedIn) {
            await this.props.logout();
        }

        if(!this.props.isLoggedIn) {
            this.props.history.push("/");
        }
    }

    componentDidUpdate(prevProps:Readonly<CProps>) {
        if(!this.props.isLoggedIn) {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }
}


const putState = ():RState => {
    return {
        isLoggedIn: store.auth.getters.isLoggedIn
    };
};

const putActions = ():RActions => {
    return {
        logout: store.auth.logout
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions)
)(AuthLogout);