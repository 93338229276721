import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";

import {
    IBounds,
    IArea,
    // IMapTile,
    ICityCenter,
    ISuggest
} from "src/types";

import QuadTile from "src/makes/QuadTile";

import {
    Map,
    MapEventMove,
    MapEventClick,
    MapEventSelect
} from "src/views/blocks";

import store from "src/store";


interface Props {
    suggestId?:string;
    quadKeys?:string[];
    // getMapZoomHeight?:(height:number) => void;
    onClick?:(e:MapEventClick) => void;
}

interface State {
    zoom:number;
    height:number;
}

interface RState {
    mapBounds?:IBounds|null;
    mapQuadKeys?:string[];
    isDev:boolean;
    find:(type:string, query?:any) => any[];
    filter:(type:string, filters?:any) => any[];
    filterOne:(type:string, filters?:any) => any;
}

interface RActions {
    setMapBounds:(bounds:IBounds) => void;
    setMapQuadKeys:(quadKeys:string[]) => void;
    create:(type:string, data:any) => Promise<any>;
    update:(type:string, id:string, data:any) => Promise<any>;
    search:(type:string, query?:any) => Promise<any[]>;
}

interface CProps extends Props, RState, RActions {}


class SuggestMap extends React.Component<CProps, State> {
    refMap:any;

    get map() {
        if(this.refMap && this.refMap.current) {
            return this.refMap.current;
        }

        return null;
    }

    constructor(props:CProps) {
        super(props);

        this.state = {
            zoom: 0,
            height: 0
        };

        this.refMap = React.createRef();
    }

    onClickMarker(cityCenter:ICityCenter) {
        if(this.map) {
            this.map.flyTo(cityCenter.point, 1000);
        }
    }

    async onMove(e:MapEventMove) {
        if(e.height <= 2000) {
            const quadKeys:string[] = e.tiles.map((tile:QuadTile) => {
                return tile.getQuadKey();
            });

            this.props.setMapQuadKeys(quadKeys);

            for(let i in e.tiles) {
                let quadTile:QuadTile = e.tiles[i];

                let quadKey = quadTile.getQuadKey();
                let bounds = quadTile.getBounds();

                if(bounds) {
                    let area:IArea|null = this.props.filterOne("area", {
                        type: "suggestions",
                        quadKey: quadKey
                    });

                    if(!area) {
                        area = await this.props.create("area", {
                            type: "suggestions",
                            quadKey: quadKey,
                            loaded: false
                        });
                    }

                    if(area && !area.loaded) {
                        const res:any = await this.props.search("suggest", {
                            bounds: bounds
                        });

                        if(res.status === "OK") {
                            await this.props.update("area", area.id, {
                                loaded: true
                            });
                        }
                    }
                }
            }
        }

        // for(let i in suggest.top5Ids) {
        //     await this.props.fetchGet("suggest", suggest.top5Ids[i]);
        // }
    }

    onClick(e:MapEventClick) {
        const {
            onClick
        } = this.props;

        onClick && onClick(e);
    }

    onSelect(e:MapEventSelect) {
        //
    }

    render() {
        const {
            suggestId,
            quadKeys = [],
            mapBounds,
            mapQuadKeys,
            isDev
        } = this.props;

        let cityCenters:ICityCenter[] = this.props.find("city-center", {
            type: "suggestions"
        });

        let suggests = this.props.filter("suggest", {
            quadKey: mapQuadKeys
        });

        return (
            <Map
              ref={this.refMap}
              className="page-suggest__map"
              width="100%"
              height="100%"
              multiple={false}
              selectable={true}
              dragging={true}
              debug={isDev}
              initialBounds={mapBounds}
              markers={cityCenters.map((cityCenter:ICityCenter) => {
                return {
                    ...cityCenter,
                    onClick: () => this.onClickMarker(cityCenter)
                };
              })}
              tiles={suggests.reduce((tiles:any[], suggest:ISuggest) => {
                return [
                    ...tiles.map((tile) => {
                        const {
                            color
                        } = tile;

                        let includes = suggest.quadKeys.includes(tile.quadKey);

                        return {
                            ...tile,
                            color: suggest.id === suggestId && includes ? "#9ED8FF" : color,
                            count: includes ? tile.count + 1 : tile.count
                        };
                    }),
                    ...suggest.quadKeys.filter((quadKey:string) => {
                        return !tiles.find((tile) => {
                            return tile.quadKey === quadKey;
                        });
                    }).map((quadKey:string) => {
                        return {
                            quadKey: quadKey,
                            color: suggestId === suggest.id ? "#9ED8FF" : "#75AFE5",
                            count: 1
                        };
                    })
                ];
              }, [])}
              selectKeys={quadKeys}
              onMove={(e:MapEventMove) => this.onMove(e)}
              onClick={(e:MapEventClick) => this.onClick(e)}
              onSelect={(e:MapEventSelect) => this.onSelect(e)} />
        );
    }
}


const putState = (state:any):RState => {
    return {
        mapBounds: state.ui.mapBounds,
        mapQuadKeys: state.ui.mapQuadKeys,
        isDev: store.ui.getters.isDev,
        find: store.orm.getters.find,
        filter: store.orm.getters.filter,
        filterOne: store.orm.getters.filterOne
    };
};

const putActions = ():RActions => {
    return {
        setMapBounds: store.ui.setMapBounds,
        setMapQuadKeys: store.ui.setMapQuadKeys,
        create: store.orm.create,
        update: store.orm.update,
        search: store.orm.search
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions)
)(SuggestMap);