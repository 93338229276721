import React, {useState, useEffect, useCallback} from "react";
import {useParams} from "react-router-dom";
import {
    makeStyles,
    Theme,
    Grid,
    Typography,
    Slider
} from "@material-ui/core";

import {
    Page,
    PageHeader,
    PageNavbar,
    PageWrapper,
    PageContent
} from "src/views/blocks";

import {
    DemandMap,
    Sidebar
} from "./blocks";

import store from "src/store";

import "./index.scss";


type UrlParams = {
    categoryId?:string;
};

const DemandPage:React.FC = () => {
    const classes = useStyles();
    const {categoryId} = useParams<UrlParams>();

    const [precise, setPrecise] = useState(100);
    const [preciseChanged, setPreciseChanged] = useState(precise);
    const [isPreciseChanged, setIsPreciseChanged] = useState(false);

    useEffect(() => {
        const loadCityCenters = async () => {
            const res = await store.orm.search("city-center", {
                type: "demand"
            });

            if(res.status !== "OK") {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(loadCityCenters());
                    }, 100);
                });
            }

            return res;
        };

        loadCityCenters();
    }, []);

    useEffect(() => {
        if(isPreciseChanged) {
            setPrecise(preciseChanged);
        }
    }, [isPreciseChanged]);

    const handleMouseUpPrecise = useCallback(() => {
        setIsPreciseChanged(true);
    }, []);

    const handleMouseDownPrecise = useCallback(() => {
        setIsPreciseChanged(false);
    }, []);

    const handleChangePrecise = useCallback((e:any, precise:number|number[]) => {
        if(typeof precise === "number") {
            setPreciseChanged(precise);
        }
    }, [precise]);

    return (
        <Page className="page-demand">
            <PageHeader deep={3}>
                <PageNavbar item spacing={3}>
                    <Grid item className={classes.sidebarWidth}>
                        <Typography variant="h3">
                            Demand
                        </Typography>
                    </Grid>

                    <Grid item style={{flex: 1}}>
                        <Grid container
                          style={{paddingBottom: "10px"}}
                          alignItems="center"
                          alignContent="center">
                            <Grid item>
                                <Typography variant="body1">
                                    Less precise
                                </Typography>
                            </Grid>

                            <Grid item
                              style={{
                                flex: 1,
                                padding: "0 25px",
                                maxWidth: "200px"
                              }}>
                                <Slider
                                  color="secondary"
                                  valueLabelDisplay="auto"
                                  defaultValue={0}
                                  step={1}
                                  min={0}
                                  max={100}
                                  value={preciseChanged}
                                  onMouseUp={handleMouseUpPrecise}
                                  onMouseDown={handleMouseDownPrecise}
                                  onChange={handleChangePrecise} />
                            </Grid>

                            <Grid item>
                                <Typography variant="body1">
                                    More precise
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </PageNavbar>
            </PageHeader>

            <PageWrapper deep={3}>
                <PageContent item className={[classes.sidebar, classes.sidebarWidth].join(" ")}>
                    <Sidebar
                      selectedCategoryId={categoryId} />
                </PageContent>

                <PageContent item className={classes.content}>
                    <DemandMap
                      categoryId={categoryId}
                      precise={precise} />
                </PageContent>
            </PageWrapper>
        </Page>
    );
};


const useStyles = makeStyles((theme:Theme) => {
    return {
        sidebar: {
            [theme.breakpoints.down("md")]: {
                order: 2
            }
        },
        sidebarWidth: {
            flexGrow: 0,
            flexBasis: "33.333333%",
            maxWidth: "33.333333%",
            [theme.breakpoints.only("xl")]: {
                flexBasis: "544px",
                // width: "auto",
                maxWidth: "544px"
            },
            [theme.breakpoints.down("md")]: {
                flexBasis: "100%",
                maxWidth: "none"
            }
        },
        content: {
            width: "auto",
            flexGrow: 1,
            [theme.breakpoints.down("md")]: {
                order: 1,
                width: "100%",
                height: "500px"
            }
        }
    };
});

export default DemandPage;