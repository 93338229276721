import {MenuRouteItem} from "./types";


export const ROUTES = {
    demands: "/demand",
    home: "/",
    login: "/login",
    logout: "/logout",
    rating: "/rating",
    ratingParameter: "/rating/:parameterId",
    ratingView: "/rating-view",
    ratingViewParameters: "/rating-view/parameter",
    ratingViewParameter: "/rating-view/parameter/:parameterId",
    suggestions: "/suggestion",
    suggest: "/suggestion/map/:suggestId?",
    suggestView: "/suggestion/view/:suggestId",
    suggestViewReactions: "/suggestion/view/:suggestId/reactions",
    suggestViewComments: "/suggestion/view/:suggestId/comments"
};


export const MENU:MenuRouteItem[] = [
    {
        title: "Ratings",
        to: ROUTES.rating,
        icon: "vote-dashboard"
    },
    {
        title: "Suggestions",
        to: ROUTES.suggestions,
        icon: "suggestion-dashboard"
    },
    {
        title: "Demand",
        to: ROUTES.demands,
        icon: "demand-dashboard"
    }
];

export type {MenuRouteItem};
