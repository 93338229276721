import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";
import {IPoint} from "src/types";


interface ICityCenter {
    id:string;
    point:IPoint;
    total:number;
}


class CityCenter extends Model<ICityCenter> {
    static modelName = "city-center";

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ICityCenter> {
        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message
            }
        } = res;

        if(res.httpCode === 200) {
            const {
                response: items = []
            } = res;

            return {
                status: STATUS_SUCCESS,
                total: items.length,
                items
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }
}


export type {ICityCenter};

export {CityCenter};