import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {
    withStyles,
    createStyles,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@material-ui/core";
import {
    EmojiObjectsOutlined as EmojiObjectsOutlinedIcon,
    RoomOutlined as RoomOutlinedIcon,
    SentimentSatisfiedAlt as SentimentSatisfiedAltIcon
} from "@material-ui/icons";


interface Props {}

interface CProps {}


const DashboardList = withStyles(() => createStyles({
    root: {
        padding: 0
    }
}))(List);


class DashboardMenu extends React.Component<CProps, any> {
    render() {
        const items = [
            {
                title: "Ratings",
                path: "/rating",
                to: "/rating",
                icon: SentimentSatisfiedAltIcon
            },
            {
                title: "Suggestions",
                to: "/suggestion",
                icon: EmojiObjectsOutlinedIcon
            },
            {
                title: "Demand",
                to: "/demand",
                icon: RoomOutlinedIcon
            }
        ];

        return (
            <Grid>
                <DashboardList>
                    {
                        items.map((item, index:number) => {
                            return (
                                <ListItem key={index} button component={Link}
                                  to={item.to}
                                  selected={location.pathname.indexOf(item.path || item.to) === 0}>
                                    {
                                        item.icon && (
                                            <ListItemIcon>
                                                <item.icon />
                                            </ListItemIcon>
                                        )
                                    }

                                    <ListItemText
                                      className="dashboard-sidebar__title"
                                      primary={item.title} />
                                </ListItem>
                            );
                        })
                    }
                </DashboardList>
            </Grid>
        );
    }
}


export default compose<React.ComponentType<Props>>(
    withRouter
)(DashboardMenu);