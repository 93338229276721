import React from "react";
import {compose} from "redux";
import {
    createStyles,
    withStyles,
    WithStyles,
    Theme,
    Typography,
    FormControl,
    InputLabel,
    Select as UISelect,
    SelectProps as UiSelectProps
} from "@material-ui/core";


interface Props extends UiSelectProps {
    label?:string;
    name?:string;
    required?:boolean;
    message?:string;
    error?:boolean;
    helperText?:string;
}

interface CProps extends Props, WithStyles {
    classes:any;
}


class Select extends React.Component<CProps, any> {
    static COUNT = 0;

    constructor(props:CProps) {
        super(props);

        this.state = {
            id: "select-id-" + (Select.COUNT++)
        };
    }

    render() {
        const {
            children,
            classes,
            label,
            message,
            error,
            required,
            helperText,
            ...rest
        } = this.props;

        const {
            id
        } = this.state;

        return (
            <FormControl
              className={classes.input}
              fullWidth
              error={error}>
                <InputLabel id={id}>
                    <span>{label}</span>
                    <Typography color="error" display="inline">
                        {required ? "*" : ""}
                    </Typography>
                </InputLabel>

                <UISelect {...rest}>
                    {children}
                </UISelect>
            </FormControl>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    input: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(Select);