import {IModule} from "kpdux";

import {
    IPoint,
    IBounds
} from "src/types";


type State = {
    mode:"production"|"development"|"test",
    mapMethod:string;
    mapBounds?:IBounds|null;
    mapQuadKeys?:string[];
    mapHeight:number|null;
    isDrawerOpen:boolean;
    viewCenter:IPoint|null;
    viewHeight:number|null;
    center:IPoint|null;
    zoom:number|null;
    viewport:any;
};

const ui:IModule<State> = {
    state: (() => {
        const state:State = {
            mode: process.env.NODE_ENV,
            mapMethod: "single",
            mapBounds: null,
            mapQuadKeys: [],
            mapHeight: null,
            viewport: null,
            center: null,
            zoom: 3,
            isDrawerOpen: false,
            viewCenter: null,
            viewHeight: null
        };

        try {
            state.center = JSON.parse(localStorage.getItem("map:center") || "");
            state.mapBounds = JSON.parse(localStorage.getItem("map:bounds") || "");
            state.zoom = parseInt(localStorage.getItem("map:zoom") || "");
        } catch(ignore) {}

        return state;
    })(),
    getters: {
        isDev(state:State) {
            return state.mode === "development";
        }
    },
    actions: {
        setDrawerOpen(isDrawerOpen:boolean) {
            this.updateDrawerOpen(isDrawerOpen);
        },
        setMapView(point:IPoint, height:number) {
            this.updateCurrentPoint(point);
            this.updateCurrentHeight(height);
        },
        setViewport(viewport:any) {
            // const {
            //     center,
            //     zoom,
            //     bounds
            // } = viewport;
            //
            // this.dispatch("storage/setItem", "map:viewport", viewport);
            //
            // this.updateViewport(viewport);
        }
    },
    mutations: {
        updateDrawerOpen(state:State, isDrawerOpen:boolean) {
            state.isDrawerOpen = isDrawerOpen;
        },
        updateCurrentPoint(state:State, point:IPoint) {
            state.viewCenter = {...point};
        },
        updateCurrentHeight(state:State, height:number) {
            state.viewHeight = height;
        },
        setMode(state:State, mode) {
            state.mode = mode;
        },
        setMapMethod(state:State, mapMethod:string) {
            state.mapMethod = mapMethod;
        },
        setCenter(state:State, center:State["center"]) {
            if(center) {
                localStorage.setItem("map:center", JSON.stringify(center));
            }
            else {
                localStorage.removeItem("map:center");
            }

            state.center = center;
        },
        setMapBounds(state:State, bounds:State["mapBounds"]) {
            if(bounds) {
                localStorage.setItem("map:bounds", JSON.stringify(bounds));
            }
            else {
                localStorage.removeItem("map:bounds");
            }

            state.mapBounds = bounds;
        },
        setMapQuadKeys(state:State, quadKeys:string[]) {
            state.mapQuadKeys = quadKeys;
        },
        updateViewport(state:State, viewport:any) {
            state.viewport = viewport;
        },
        setZoom(state:State, zoom:State["zoom"]) {
            if(typeof zoom === "number") {
                localStorage.setItem("map:zoom", zoom.toString());
            }
            else {
                localStorage.removeItem("map:center");
            }

            state.zoom = zoom;
        }
    }
};


export type {State as UiState};

export {ui};