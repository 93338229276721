import React from "react";
import {compose} from "redux";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles
} from "@material-ui/core";

import {rateToColor} from "src/tools";

import HappyEmotion from "./blocks/HappyEmotion";
import NeutralEmotion from "./blocks/NeutralEmoticon";
import SadEmotion from "./blocks/SadEmoticon";


interface Props {
    style?:React.CSSProperties;
    alt?:string;
    type?:"happy"|"neutral"|"sad";
    size:"large"|"medium"|"small";
    rate?:number;
    color?:"primary"|"secondary"|"light"|string;
}

interface CProps extends Props, WithStyles {
    alt:string;
}


class Emotion extends React.Component<CProps, any> {
    static defaultProps:any = {
        alt: "",
        size: "medium"
    };

    render() {
        const {
            style,
            classes,
            rate,
            type,
            size,
            color
            // alt
        } = this.props;

        let rateColor = "";

        let IconEmotion = null;

        if(typeof rate !== "undefined") {
            rateColor = rateToColor(rate);

            if(Math.round(rate) < 50) {
                IconEmotion = SadEmotion;
            }
            else {
                IconEmotion = HappyEmotion;
            }
        }

        if(typeof type !== "undefined") {
            switch(type) {
                case "happy":
                    IconEmotion = HappyEmotion;
                    break;

                case "neutral":
                    IconEmotion = NeutralEmotion;
                    break;

                case "sad":
                    IconEmotion = SadEmotion;
                    break;
            }
        }

        if(color) {

        }

        return (
            IconEmotion ? (
                <IconEmotion
                  style={{
                    ...(style || {}),
                    ...(rateColor && !color ? {
                        fill: rateColor
                    } : {}),
                    ...(color && !classes["color" + color.substr(0, 1).toUpperCase() + color.substr(1)] ? {
                        fill: color
                    } : {})
                  }}
                  className={[
                    classes.root,
                    classes["size" + size.substr(0, 1).toUpperCase() + size.substr(1)],
                    color ? classes["color" + color.substr(0, 1).toUpperCase() + color.substr(1)] : ""
                  ].join(" ")} />
            ) : null
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        fill: "#E9E9E9"
    },
    sizeLarge: {
        width: "100px",
        height: "100px"
    },
    sizeMedium: {
        width: "47px",
        height: "47px"
    },
    sizeSmall: {
        width: "25px",
        height: "25px"
    },
    colorPrimary: {
        fill: theme.palette.primary.main
    },
    colorSecondary: {
        fill: "#D8D8D8"
    },
    colorLight: {
        fill: "#E9E9E9"
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(Emotion);