import React, {CSSProperties} from "react";
import {
    Badge
} from "@material-ui/core";

import "./index.scss";


interface RateCircleProps {
    color?:string;
    value?:string;
    title?:string;
    style?:CSSProperties;
}

class RateCircle extends React.Component<RateCircleProps, any> {
    render() {
        const {
            color,
            value,
            title,
            style
        } = this.props;

        return (
            <Badge
              className="rate-circle"
              style={{
                ...style,
                backgroundColor: color
              }}
              overlap="circular"
              badgeContent=""
              variant="dot">
                <span className="rate-circle__value">
                    {value}
                </span>

                <span className="rate-circle__title">
                    {title}
                </span>
            </Badge>
        );
    }
}


export default RateCircle;