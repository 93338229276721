import React from "react";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    PageSidebarContent
} from "../Page";


interface Props {
    component:any;
    ids:string[];
}


class CommentList extends React.Component<Props, any> {
    render() {
        const {
            component: Component,
            ids
        } = this.props;

        return ids.length > 0 ? (
            <PageSidebarContent>
                {
                    ids.map((id:string, index:number) => {
                        return (
                            <Component
                              key={index}
                              id={id} />
                        );
                    })
                }
            </PageSidebarContent>
        ) : (
            <Grid container
              style={{
                height: "100%"
              }}
              justifyContent="center"
              alignItems="center">
                <Typography>Comments list is empty</Typography>
            </Grid>
        );
    }
}


export default CommentList;