import React, {useState, useEffect, useRef, useCallback} from "react";

import {
    // IPoint,
    ICityCenter,
    IDemandVote
} from "src/types";

import {
    useSelector,
    useORM
} from "src/hooks";

import {QuadTile} from "src/makes/QuadTile";
import {Bounds} from "src/makes/Bounds";

import {
    Map,
    MapRef,
    MapEventMove
} from "src/views/blocks";

import store, {
    IArea,
    CityCenter,
    DemandVote
} from "src/store";


type Props = {
    categoryId?:string;
    precise?:number;
};

const DemandMap:React.FC<Props> = (props:Props) => {
    const {
        categoryId,
        precise = 0
    } = props;

    const {
        mapBounds,
        mapQuadKeys = []
    } = useSelector((state) => state.ui);
    const orm = useORM();

    const [showTile, setShowTile] = useState<IDemandVote|null>(null);

    const cityCenters:ICityCenter[] = orm.find(CityCenter.modelName, {
        type: "demand"
    });

    const tiles:IDemandVote[] = (() => {
        if(!mapBounds) {
            return [];
        }

        const bounds = Bounds.fromBounds(mapBounds);

        if(bounds) {
            const tiles = orm.filter(DemandVote.modelName, {
                categoryId,
                bounds
            });

            let maxDemand:number|undefined = undefined;

            return tiles.map((tile:IDemandVote) => {
                const {
                    demand
                } = tile;

                if(maxDemand === undefined || maxDemand < demand) {
                    maxDemand = demand;
                }

                return tile;
            }).filter((tile:IDemandVote) => {
                const {
                    demand
                } = tile;

                // @ts-ignore
                return demand >= maxDemand / 100 * precise;
            });
        }

        return [];
    })();

    const refMap = useRef<MapRef>(null);

    useEffect(() => {
        let cancel = false;

        const loadData = async () => {
            for(let i in mapQuadKeys) {
                if(cancel) {
                    return;
                }

                const quadKey = mapQuadKeys[i];
                const quadTile:QuadTile = QuadTile.fromQuadKey(quadKey);

                const bounds = quadTile.getBounds();

                if(bounds) {
                    let area:IArea|null = store.orm.getters.filterOne("area", {
                        type: "demand" + categoryId,
                        quadKey: quadKey
                    });

                    if(!area) {
                        area = await store.orm.create("area", {
                            type: "demand" + categoryId,
                            quadKey: quadKey,
                            loaded: false
                        });
                    }

                    if(area && !area.loaded) {
                        const res:any = await store.orm.search(DemandVote.modelName, {
                            categoryId,
                            bounds
                        });

                        if(res.status === "OK") {
                            await store.orm.update("area", area.id, {
                                loaded: true
                            });
                        }

                        await new Promise((resolve) => setTimeout(resolve, 500));
                    }
                }
            }
        };

        const timeout = setTimeout(() => {
            loadData();
        }, 500);

        return () => {
            clearTimeout(timeout);
            cancel = true;
        };
    }, [categoryId, JSON.stringify(mapQuadKeys)]);

    const handleMove = useCallback((e:MapEventMove) => {
        const {
            zoom,
            tiles
        } = e;

        if(zoom > 16) {
            const quadKeys:string[] = tiles.map((tile:QuadTile) => {
                return tile.getQuadKey();
            });

            store.ui.setMapQuadKeys(quadKeys);
        }
        else {
            store.ui.setMapQuadKeys([]);
        }
    }, []);

    const handleClickMarker = useCallback((cityCenter:ICityCenter) => {
        if(refMap.current) {
            refMap.current.flyTo(cityCenter.point, 1000);
        }
    }, []);

    return (
        <React.Fragment>
            <Map
              ref={refMap}
              width="100%"
              height="100%"
              selectable={false}
              dragging={true}
              heatmap={true}
              markers={cityCenters.map((cityCenter:any) => {
                return {
                    ...cityCenter,
                    onClick: () => {
                        handleClickMarker(cityCenter);
                    }
                };
              })}
              points={tiles.map((vote:IDemandVote) => {
                const {
                    center,
                    score
                } = vote;

                return {
                    center,
                    count: score
                };
              })}
              {...{
                  ...showTile ? {
                      tiles: [
                          {
                              // @ts-ignore
                              quadKey: QuadTile.fromPoint(showTile.center, 22).getQuadKey(),
                              count: showTile.demand,
                              opacity: 0.5,
                              color: "#FFFFFF"
                          }
                      ]
                  } : {}
              }}
              onMove={handleMove} />
        </React.Fragment>
    );
};


export default DemandMap;