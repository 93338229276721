import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    Typography,
    Divider
} from "@material-ui/core";

import {
    IBounds,
    ITileParameter
} from "src/types";

import {
    PageRow,
    Emotion,
    RatingTrend,
    NoVotesEmoji
} from "src/views/blocks";

import store from "src/store";


interface Props {
    parameterId?:string;
    bounds?:IBounds|undefined;
    quadKeys?:string[];
}

interface RState {
    parameter:ITileParameter;
    getSummary:(type:string, query?:any) => any;
}

interface CProps extends Props, RState {}


class RatingViewOverview extends React.Component<CProps, any> {
    constructor(props:CProps) {
        super(props);

        this.state = {
            period: 12
        };
    }

    setPeriod(period:any) {
        this.setState({
            period: period
        });
    }

    render() {
        const {
            quadKeys,
            parameterId,
            parameter,
            bounds
        } = this.props;

        const {
            votes = 0,
            votesLocal = 0,
            votesPositive = 0,
            votesNegative = 0,
            rate = 0,
            rateLocal = 0,
            rateNonLocal = 0,
            rateSurrounding = 0
        } = this.props.getSummary("tile", {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        const {
            totals = []
        } = this.props.getSummary("tile-analytic", {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        return (
            <Grid container direction="column">
                <PageRow alignItems="center">
                    <Grid item style={{flex: 0, flexBasis: "225px"}}>
                        <Grid container alignItems="center">
                            <Grid item>
                                {
                                    votes > 0 ? (
                                        <Emotion
                                          size="large"
                                          rate={rate} />
                                    ) : (
                                        <Emotion
                                          size="large"
                                          type="neutral"
                                          color="light" />
                                    )
                                }
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                paddingLeft: "20px",
                                  fontSize: 39
                              }}
                              variant="h1">
                                {votes ? rate + "%" : ""}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item style={{flex: 1}}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item style={{padding: "10px"}}>
                                <Grid container
                                  direction="column"
                                  justifyContent="center">
                                    <Grid item>
                                        <Emotion
                                          // this rate is only for coloring emoji
                                          size="medium"
                                          rate={100}
                                          type="happy" />
                                    </Grid>

                                    <Grid item container justifyContent="center">
                                        <Typography>
                                            {votesPositive}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item style={{padding: "10px"}}>
                                <Grid container
                                  direction="column"
                                  justifyContent="center">
                                    <Grid item>
                                        <Emotion
                                          size="medium"
                                          // this rate is only for coloring emoji
                                          rate={0}
                                          type="sad" />
                                    </Grid>

                                    <Grid item container justifyContent="center">
                                        <Typography>
                                            {votesNegative}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PageRow>

                <Divider />

                <PageRow>
                    <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: "17px",
                            color: "#707070",
                            paddingBottom: "15px"
                          }}
                          variant="body1">
                            Locals
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item>
                                {votesLocal > 0 ? (
                                    <Emotion
                                      size="large"
                                      {...votesLocal > 0 ? {
                                        rate: rateLocal
                                      } : {
                                        type: "neutral",
                                        color: "light"
                                      }} />
                                ) : (
                                    <NoVotesEmoji size="large" />
                                )}
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                fontSize: 18,
                                paddingLeft: "15px"
                              }}
                              variant="body1">
                                {votesLocal ? rateLocal + "%" : ""}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography
                          style={{
                            fontSize: "17px",
                            color: "#707070",
                            paddingBottom: "15px"
                          }}
                          variant="body1">
                            Non-locals
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item>
                                {
                                    votes > 0 ? (
                                        <Emotion
                                          rate={rateNonLocal}
                                          size="large" />
                                    ) : (
                                        <NoVotesEmoji size="large" />
                                    )
                                }
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                fontSize: 18,
                                paddingLeft: "15px"
                              }}
                              variant="body1">
                                {votes > 0 ? rateNonLocal + "%" : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                </PageRow>

                <Divider />

                <PageRow alignItems="center" wrap="nowrap" justifyContent="space-between">
                    <Grid item component={Typography} variant="body2">
                        {"Percentile '" + parameter.title + "' popularity in surrounding km"}<sup>2</sup>
                    </Grid>

                    <Grid item component={Typography}
                      style={{
                        fontWeight: "normal"
                      }}
                      variant="h5">
                        {rateSurrounding}
                    </Grid>
                </PageRow>

                <Divider />

                <RatingTrend
                  data={totals.map((total:any) => {
                    return {
                        title: total.date,
                        value: total.rate
                    };
                  })} />
            </Grid>
        );
    }
}


const putState = (state:any, props:Props):RState => {
    return {
        parameter: store.orm.getters.byId("tile-parameter", props.parameterId),
        getSummary: store.orm.getters.getSummary
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(RatingViewOverview);