import React from "react";
import {compose} from "redux";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Grid,
    GridProps
} from "@material-ui/core";

import theme from "../../../../theme";


interface Props extends GridProps {
    deep?:number;
}

interface CProps extends Props, WithStyles {
    classes:any;
}


class PageHeader extends React.Component<CProps, any> {
    render() {
        const {
            children,
            classes,
            className,
            deep = 0,
            ...rest
        } = this.props;

        return (
            <Grid container
              style={{
                padding: theme.spacing(deep) / 2
              }}
              className={[
                "page-header",
                classes.root,
                className
              ].join(" ")}
              direction="column"
              {...rest}>
                {children}
            </Grid>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        flex: 0,
        // flexBasis: "70px",
        // height: "70px",
        [theme.breakpoints.down("md")]: {
            flexBasis: "auto",
            height: "auto",
            minHeight: "70px"
        }
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(PageHeader);