import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";

import {
    ISuggest
} from "../../../../../types";

import Bounds from "../../../../../makes/Bounds";
import QuadTile from "../../../../../makes/QuadTile";

import {
    DataQuality
} from "../../../../blocks";

import store from "../../../../../store";


interface Props {
    suggestId:string;
}

interface RState {
    mapBounds:any;
    find:(type:string, query?:any) => any[];
    getSummary:(type:string, query?:any) => any;
}

interface CProps extends Props, RState {}


class SuggestDataQuality extends React.Component<CProps, any> {
    state = {
        period: 3
    };

    setPeriod(period:number) {
        this.setState({
            period: period
        });
    }

    render() {
        const {
            suggestId,
            mapBounds
        } = this.props;

        const {
            period
        } = this.state;

        const {
            votes = 0,
            votes3 = 0,
            votes12 = 0,
            votesSurrounding = 0,
            votesSurrounding3 = 0,
            votesSurrounding12 = 0,
            totals = []
        } = this.props.getSummary("suggest-analytic", {
            withSummary: true,
            suggestId: suggestId
        });

        const items = period > 0 ? totals.slice(-period) : totals;

        let totalVotes = votes;
        let totalSurrounding = votesSurrounding;

        switch(period) {
            case 3:
                totalVotes = votes3;
                totalSurrounding = votesSurrounding3;
                break;

            case 12:
                totalVotes = votes12;
                totalSurrounding = votesSurrounding12;
                break;
        }

        return (
            <React.Fragment>
                <DataQuality
                  period={period}
                  totalLabel={"Total votes received"}
                  totalValue={totalVotes}
                  averageLabel={"Average general votes received"}
                  averageValue={totalSurrounding}
                  data={items}
                  onChangePeriod={(period:number) => this.setPeriod(period)} />
            </React.Fragment>
        );
    }
}


const putState = (state:any):RState => {
    return {
        mapBounds: state.ui.mapBounds,
        find: store.orm.getters.find,
        getSummary: store.orm.getters.getSummary
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(SuggestDataQuality);