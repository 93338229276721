import React, {Fragment} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    Typography,
    Divider
} from "@material-ui/core";

import {
    ISuggest
} from "src/types";

import {
    PageRow,
    Emotion,
    RatingTrend,
    NoVotesEmoji
} from "src/views/blocks";

import store from "src/store";


interface Props {
    suggestId:string;
}

interface RState {
    mapBounds:any;
    byId:(type:string, id:any) => any;
    getSummary:(type:string, query?:any) => any;
}

interface CProps extends Props, RState {}


class SuggestReactions extends React.Component<CProps, any> {
    render() {
        const {
            suggestId
        } = this.props;

        const suggest:ISuggest = this.props.byId("suggest", suggestId);
        const {
            totals = []
        } = this.props.getSummary("suggest-analytic", {
            withSummary: true,
            suggestId: suggestId
        });

        const {
            votes = 0,
            votesPositive = 0,
            votesNeutral = 0,
            votesNegative = 0,
            votesLocal = 0,
            votesNonLocal = 0,
            rate = 0,
            rateLocal = 0,
            rateNonLocal = 0,
            percentile = 0
        } = suggest || {};

        return (
            <Fragment>
                <PageRow alignItems="center">
                    <Grid item xs={5}>
                        <Grid container alignItems="center">
                            <Grid item>
                                {
                                    votes > 0 ? (
                                        <Emotion
                                          size="large"
                                          {...votes > 0 ? {
                                            rate: rate
                                        } : {
                                            type: "neutral",
                                            color: "secondary"
                                        }} />
                                    ) : (
                                        <NoVotesEmoji size="large" />
                                    )
                                }
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                paddingLeft: "10px"
                              }}
                              variant={votes > 0 ? "h1" : "body1"}>
                                {votes > 0 ? rate + "%" : ""}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={5}>
                        <Grid container justifyContent="space-between">
                            <Grid item className="text-center">
                                <Emotion
                                  type="happy"
                                  // this rate is only for coloring emoji
                                  rate={100}
                                  size="medium" />

                                <Typography>
                                    {votesPositive}
                                </Typography>
                            </Grid>

                            <Grid item className="text-center">
                                <Emotion
                                  type="neutral"
                                  size="medium" />

                                <Typography>
                                    {votesNeutral}
                                </Typography>
                            </Grid>

                            <Grid item className="text-center">
                                <Emotion
                                  type="sad"
                                  rate={0}
                                  // this rate is only for coloring emoji
                                  size="medium" />

                                <Typography>
                                    {votesNegative}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </PageRow>

                <Divider />

                <PageRow>
                    <Grid item xs={5}>
                        <Typography
                          style={{
                            fontSize: "17px",
                            color: "#707070",
                            paddingBottom: "15px"
                          }}
                          variant="body1">
                            Locals
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item>
                                {
                                    votesLocal > 0 ? (
                                        <Emotion
                                          size="large"
                                          {...votesLocal > 0 ? {
                                            rate: rateLocal
                                        } : {
                                            type: "neutral",
                                            color: "secondary"
                                        }} />
                                    ) : (
                                        <NoVotesEmoji size="large" />
                                    )
                                }
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                paddingLeft: "15px",
                                fontSize: "20px"
                              }}
                              variant="body1">
                                {votesLocal > 0 ? rateLocal + "%" : ""}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={5}>
                        <Typography
                          style={{
                            fontSize: "17px",
                            color: "#707070",
                            paddingBottom: "15px"
                          }}
                          variant="body1">
                            Non-locals
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item>
                                {
                                    votesNonLocal > 0 ? (
                                        <Emotion
                                          size="large"
                                          {...votesNonLocal > 0 ? {
                                            rate: rateNonLocal
                                        } : {
                                            type: "neutral",
                                            color: "secondary"
                                        }} />
                                    ) : (
                                        <NoVotesEmoji size='large' />
                                    )
                                }
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                paddingLeft: "15px",
                                fontSize: "20px"
                              }}
                              variant="body1">
                                {votesNonLocal > 0 ? rateNonLocal + "%" : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                </PageRow>

                <Divider />

                <PageRow alignItems="center" justifyContent="space-between">
                    <Grid item component={Typography} variant="body2">
                        Percentile suggestion popularity in surrounding km<sup>2</sup>
                    </Grid>

                    <Grid item component={Typography} variant="h5">
                        {percentile}
                    </Grid>
                </PageRow>

                {totals.length > 0 && (
                    <Fragment>
                        <Divider />

                        <PageRow>
                            <RatingTrend
                              data={totals.map((total:any) => {
                                return {
                                    title: total.date,
                                    value: total.rate
                                };
                              })} />
                        </PageRow>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}


const putState = (state:any):RState => {
    return {
        mapBounds: state.ui.mapBounds,
        byId: store.orm.getters.byId,
        getSummary: store.orm.getters.getSummary
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(SuggestReactions);