import React, {
    Fragment
} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    ISuggest
} from "../../../../../types";

import {
    PageScroll
} from "../../../../blocks";

import SuggestTop5Item from "../SuggestTop5Item";

import store from "../../../../../store";


interface Props {
    suggestId:string;
}

interface RState {
    byId:(type:string, id:any) => any;
}

interface CProps extends Props, RState {}


class SuggestTop5 extends React.Component<CProps, any> {
    render() {
        const {
            suggestId
        } = this.props;

        const suggest:ISuggest = this.props.byId("suggest", suggestId);

        const {
            top5Ids = []
        } = suggest || {};

        return (
            <Fragment>
                <Typography variant="h4">
                    Top 5 suggestions this km<sup>2</sup>
                </Typography>

                <PageScroll>
                    {
                        top5Ids.map((id, index:number) => {
                            return (
                                <SuggestTop5Item key={index}
                                  id={id} />
                            );
                        })
                    }
                </PageScroll>
            </Fragment>
        );
    }
}


const putState = ():RState => {
    return {
        byId: store.orm.getters.byId
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(SuggestTop5);