import React from "react";
import {
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from "@material-ui/icons";


interface Props {
    total?:number;
    value?:null|number;
    fields?:{
        title:string;
        name:string;
    }[];
    valueFields?:{
        title:string,
        value:null|number;
    }[],
    orderBy?:string;
    orderWay?:string;
    onChangeOrder?:(orderBy:string, orderWay:string) => void;
    onChangeValue?:(value:null|number) => void;
}


class ToolbarComment extends React.Component<Props, any> {
    static defaultProps = {
        fields: [
            {
                title: "Popularity",
                name: "likes"
            },
            {
                title: "Date",
                name: "date"
            }
        ],
        valueFields: [
            {
                title: "All",
                value: null
            },
            {
                title: "Positive",
                value: 2
            },
            {
                title: "Neutral",
                value: 1
            },
            {
                title: "Negative",
                value: 0
            }
        ]
    };

    constructor(props:Props) {
        super(props);

        this.state = {};
    }

    setOrder(nextOrderBy:string) {
        const {
            orderBy,
            orderWay,
            onChangeOrder
        } = this.props;

        onChangeOrder && onChangeOrder(
            nextOrderBy,
            nextOrderBy === orderBy
                ? (orderWay === "asc" ? "desc" : "asc")
                : "desc"
        );
    }

    setValue(value:null|number) {
        const {
            onChangeValue
        } = this.props;

        onChangeValue && onChangeValue(value);
    }

    render() {
        const {
            fields = [],
            valueFields = [],
            total,
            value,
            orderBy,
            orderWay
        } = this.props;

        return (
            <React.Fragment>
                <Grid item>
                    {fields.map((field:any, index:number) => {
                        let endIcon;

                        if(orderBy === field.name) {
                            switch(orderWay) {
                                case "asc":
                                    endIcon = <ArrowDownwardIcon />;
                                    break;

                                case "desc":
                                    endIcon = <ArrowUpwardIcon />;
                                    break;
                            }
                        }

                        return (
                            <Button
                              key={index}
                              variant="text"
                              size="small"
                              endIcon={endIcon}
                              onClick={() => this.setOrder(field.name)}>
                                {field.title}
                            </Button>
                        );
                    })}
                </Grid>

                <Grid item>
                    <Typography
                      style={{fontSize: "12px"}}
                      display="inline">
                        {total} comments
                    </Typography>

                    {valueFields.map((field:any, index:number) => {
                        return (
                            <Button
                              key={index}
                              variant="text"
                              size="small"
                              aria-selected={field.value === value}
                              onClick={() => this.setValue(field.value)}>
                                {field.title}
                            </Button>
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    }
}


export default ToolbarComment;