import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";
import {calcRate} from "src/tools";


interface ITile {
    id:string;
    quadKey:string;
    // votesTotal:number;
    // votesGeneral:number;
    votesNegative:number;
    votesNeutral:number;
    votesPositive:number;
    // votesLocalNegative:number;
    // votesLocalNeutral:number;
    // votesLocalPositive:number;
    // votesSurroundingGeneral:number;
    // votesSurroundingNegative:number;
    // votesSurroundingNeutral:number;
    // votesSurroundingPositive:number;
    // votesSurroundingTotal:number;
    commentsTotal:number;
    commentsGeneral:number;
    commentsNegative:number;
    commentsNeutral:number;
    commentsPositive:number;
    // percentile:number;
    rateSurrounding:number;
    voteParameters:{
        [parameterId:string]:{
            positive:number;
            neutral:number;
            negative:number;
            localPositive:number;
            localNeutral:number;
            localNegative:number;
            percentile:number;
            surroundingTotal:number;
            surroundingNegative:number;
            surroundingNeutral:number;
            surroundingPositive:number;
        }
    }
}

interface ITileSummary {
    address?:string;
    votes?:number;
    rate?:number;
    commentsTotal?:number;
    commentsGeneral?:number;
    commentsPositive?:number;
    commentsNeutral?:number;
    commentsNegative?:number;
    commentsParameterPositive?:number;
    commentsParameterNegative?:number;
    localVotesNegative?:number;
    totals?:{
        data:string;
        rate:number;
    }[];
    relative?:any[];
}

class Tile extends Model<ITile> {
    static modelName:string = "tile";
    static queryCount:string = "count";
    static queryPage:string = "page";
    static filters = {
        quadKey: (tile:ITile, quadKey:string|string[]):boolean => {
            if(Array.isArray(quadKey)) {
                return quadKey.reduce((includes:boolean, quadKey:string) => {
                    return includes || tile.quadKey.indexOf(quadKey) === 0;
                }, false);
            }
            else {
                return tile.quadKey.indexOf(quadKey) === 0;
            }
        }
    };

    toString():string {
        return "";
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ITile> {
        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(res.httpCode === 200) {
            const {
                response: {
                    count: total,
                    data: items,
                    summary
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items,
                summary
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }

    static prepare(data:any):any {
        const {
            percentile,
            ...otherData
        } = data;

        return {
            ...otherData,
            rateSurrounding: percentile
        };
    }

    static listSummary(tiles:ITile[], res:any, query:any):ITileSummary {
        if(query && query.withSummary) {
            const {
                summary: {
                    address = "",
                    votes = 0,
                    localVotesNegative = 0,
                    localVotesNeutral = 0,
                    localVotesPositive = 0,
                    parameters = {},
                    ...summary
                } = {}
            } = res || {};

            const {
                votesPositive = 0,
                votesNeutral = 0,
                votesNegative = 0,
                rateSurrounding = 0
            } = tiles.filter((tile:ITile) => {
                if(query.parameterId) {
                    return !!tile.voteParameters[query.parameterId];
                }

                return true;
            }).reduce((res:any, tile:ITile, index:number, tiles:ITile[]) => {
                let votesPositive = tile.votesPositive;
                let votesNeutral = tile.votesNeutral;
                let votesNegative = tile.votesNegative;
                let tileRateSurrounding = tile.rateSurrounding;

                if(query.parameterId) {
                    votesPositive = tile.voteParameters[query.parameterId].positive;
                    votesNeutral = tile.voteParameters[query.parameterId].neutral;
                    votesNegative = tile.voteParameters[query.parameterId].negative;
                    tileRateSurrounding = tile.voteParameters[query.parameterId].percentile;
                }

                res.votesPositive += votesPositive;
                res.votesNeutral += votesNeutral;
                res.votesNegative += votesNegative;
                res.sumRateSurrounding += tileRateSurrounding;
                res.rateSurrounding = res.sumRateSurrounding / tiles.length;

                return res;
            }, {
                sumRateSurrounding: 0,
                rateSurrounding: 0,
                votesPositive: 0,
                votesNeutral: 0,
                votesNegative: 0
            });

            let groups:any = Tile.getTotalsBy("voteGroups", tiles);
            let genders:any = Tile.getTotalsBy("voteGenders", tiles);
            let transports:any = Tile.getTotalsBy("voteTransports", tiles);

            const getMaxTotal = (totals:any[]):any[] => {
                return totals.sort((a:any, b:any) => {
                    if(a.rate === b.rate) {
                        const votesA = a.positive + a.neutral + a.negative;
                        const votesB = b.positive + b.neutral + b.negative;

                        return votesA < votesB ? 1 : -1;
                    }

                    return a.rate < b.rate ? 1 : -1;
                }).filter((t, index) => index === 0);
            };

            const votesNonLocalPositive = summary.votesPositive - localVotesPositive;
            const votesNonLocalNeutral = summary.votesNeutral - localVotesNeutral;
            const votesNonLocalNegative = summary.votesNegative - localVotesNegative;

            const votesNonLocal = votesNonLocalPositive + votesNonLocalNeutral + votesNonLocalNegative;
            const votesLocal = votes - votesNonLocal;

            return {
                ...summary,
                address: address || "No address",
                votes,
                votesLocal,
                votesNonLocal: votesNonLocalPositive + votesNonLocalNeutral + votesNonLocalNegative,
                rate: calcRate(votesPositive, votesNeutral, votesNegative),
                rateLocal: calcRate(localVotesPositive, localVotesNeutral, localVotesNegative),
                rateNonLocal: calcRate(votesNonLocalPositive, votesNonLocalNeutral, votesNonLocalNegative),
                rateSurrounding: Math.round(rateSurrounding),
                parameters: Object.keys(parameters).map((parameterId:string) => {
                    const {
                        votes = 0,
                        rate
                    } = parameters[parameterId] || {};

                    const {
                        positive,
                        neutral,
                        negative
                    } = tiles.reduce((summary, tile:ITile) => {
                        const {
                            voteParameters: {
                                [parameterId]: {
                                    positive = 0,
                                    neutral = 0,
                                    negative = 0
                                } = {}
                            } = {}
                        } = tile;

                        summary.positive += positive;
                        summary.neutral += neutral;
                        summary.negative += negative;

                        return summary;
                    }, {
                        positive: 0,
                        negative: 0,
                        neutral: 0
                    });

                    return {
                        parameterId: parameterId,
                        votes: votes,
                        rate: typeof rate !== "undefined" ? rate : calcRate(positive, neutral, negative),
                        positive,
                        neutral,
                        negative
                    };
                }),
                relativeByGender: genders,
                relativeByGroup: groups,
                relativeByTransport: transports,
                log: {
                    genders,
                    groups,
                    transports
                },
                relative: [
                    ...getMaxTotal(genders),
                    ...getMaxTotal(groups),
                    ...getMaxTotal(transports)
                ].map((total:any) => {
                    const {
                        positive,
                        neutral,
                        negative
                    } = total;

                    let rate = calcRate(votesPositive, votesNeutral, votesNegative, false);
                    let totalRate = calcRate(positive, neutral, negative, false);

                    return {
                        ...total,
                        rate: rate > 0 ? Math.round(((totalRate - rate) / rate) * 100) : 0,
                        rateLog: `(${totalRate} - ${rate}) / ${rate}`
                    };
                })
            };
        }

        return {};
    }

    static getTotalsBy(by:string, tiles:ITile[]):any[] {
        let totals:{[key:string]:any} = {};

        for(let i in tiles) {
            let tile:ITile = tiles[i];

            for(let key in (tile as any)[by]) {
                let {
                    positive,
                    neutral,
                    negative
                } = (tile as any)[by][key];

                if(!totals[key]) {
                    totals[key] = {
                        key: key,
                        rate: 0,
                        vote: 0,
                        positive: 0,
                        negative: 0,
                        neutral: 0,
                        positiveLog: "",
                        neutralLog: "",
                        negativeLog: ""
                    };
                }

                totals[key].positive += positive;
                totals[key].neutral += neutral;
                totals[key].negative += negative;
                totals[key].positiveLog += ` + ${positive}`;
                totals[key].neutralLog += ` + ${neutral}`;
                totals[key].negativeLog += ` + ${negative}`;
            }
        }

        return Object.values(totals).map((total:any) => {
            const {
                positive,
                neutral,
                negative
            } = total;

            return {
                ...total,
                vote: positive + neutral + negative,
                rate: calcRate(positive, neutral, negative)
            };
        });
    }
}


export type {
    ITile,
    ITileSummary
};

export {Tile};