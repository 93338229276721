const STATUS_SUCCESS = "OK";
const STATUS_ERROR = "ERROR";

type STATUS_SUCCESS = typeof STATUS_SUCCESS;
type STATUS_ERROR = typeof STATUS_ERROR;

const METHOD_GET_ONE = "getOne";
const METHOD_GET_LIST = "getList";
const METHOD_CREATE_ONE = "createOne";
const METHOD_UPDATE_ONE = "updateOne";
const METHOD_REMOVE_ONE = "removeOne";

type METHOD_GET_ONE = typeof METHOD_GET_ONE;
type METHOD_GET_LIST = typeof METHOD_GET_LIST;
type METHOD_CREATE_ONE = typeof METHOD_CREATE_ONE;
type METHOD_UPDATE_ONE = typeof METHOD_UPDATE_ONE;
type METHOD_REMOVE_ONE = typeof METHOD_REMOVE_ONE;
type METHOD = METHOD_GET_ONE|METHOD_GET_LIST|METHOD_CREATE_ONE|METHOD_UPDATE_ONE|METHOD_REMOVE_ONE;

type IResponseSuccess = {
    status:STATUS_SUCCESS|STATUS_ERROR;
    message?:string;
};
type IResponseError = {
    status:STATUS_SUCCESS|STATUS_ERROR;
    message?:string;
};

type IResponse = IResponseSuccess | IResponseError;

type IResponseGetOne<IModel> = Promise<IResponseSuccess & {
    item?:IModel;
} | IResponseError>;
type IResponseGetList<IModel> = Promise<IResponseSuccess & {
    total:number;
    items:IModel[];
    summary?:any;
} | IResponseError>;
type IResponseCreateOne<IModel> = Promise<IResponseSuccess & {
    item:IModel;
} | IResponseError>;
type IResponseUpdateOne<IModel> = Promise<IResponseSuccess & {
    item?:IModel;
} | IResponseError>;
type IResponseRemoveOne<IModel> = Promise<IResponseSuccess & {
} | IResponseError>;

class Model<IModel = any> {
    static modelName:string = "";
    static accessType:string = "dashboard";

    orm:any;

    get getters() {
        return this.orm.getters;
    }

    get rootGetters() {
        return this.orm.rootGetters;
    }

    get authHeaders() {
        return this.rootGetters.auth.authHeaders;
    }

    get modelName() {
        // @ts-ignore
        return this.constructor.modelName;
    }

    get accessType() {
        // @ts-ignore
        return this.constructor.accessType;
    }

    constructor(orm:any) {
        this.orm = orm;
    }

    async dispatch(...args:any[]) {
        return this.orm.dispatch(...args);
    }

    async _getOne(id:any):Promise<any> {
        return this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}/${id}`, {
            headers: this.authHeaders
        });
    }

    async getOne(id:any):IResponseGetOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _getList(query:any = {}):Promise<any> {
        return this.dispatch("api/get", `/api/${this.accessType}/${this.modelName}`, {
            headers: this.authHeaders,
            data: query
        });
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _createOne(data:any) {
        //
    }

    async createOne(data:any):IResponseCreateOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async _updateOne(id:any, data:any) {
        //
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    async removeOne(id:any):IResponseRemoveOne<IModel> {
        return {
            status: STATUS_ERROR
        };
    }

    toString():string {
        return "";
    }

    static prepare(item:any, method:METHOD) {
        return item;
    }

    static listSummary(items:any, res:any, query:any):any {
        return {};
    }
}


export {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR
};

export type {
    IResponse,
    IResponseGetOne,
    IResponseGetList,
    IResponseCreateOne,
    IResponseUpdateOne,
    IResponseRemoveOne
};

export default Model;