import React from "react";
import {Grid} from "@material-ui/core";

import "./index.scss";


class AppLayout extends React.Component<any, any> {
    render() {
        const {
            children
        } = this.props;

        return (
            <Grid container
              className="main-layout"
              direction="row"
              justifyContent="center"
              alignItems="center">
                <Grid item>
                    {children}
                </Grid>
            </Grid>
        );
    }
}


export default AppLayout;