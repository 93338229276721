import React from "react";

import {
    BaseFieldProps,
    Field
} from "redux-form";

import SelectWrapper from "../SelectWrapper";


interface Props extends BaseFieldProps {
    label?:string;
    options?:{
        title?:any;
        value?:any;
    }[];
    name:string;
    required?:boolean;
}

interface CProps extends Props {}


class SelectField extends React.Component<CProps, any> {
    render() {
        const {
            children,
            options,
            label,
            name,
            required
        } = this.props;

        return (
            <Field component={SelectWrapper}
              options={options}
              label={label}
              name={name}
              required={required}>
                {children}
            </Field>
        );
    }
}


export default SelectField;