import React from "react";

import "./styles.scss";

type Props = {
  size:"small" | "large";
}

const NoVotesEmoji = (props:Props) => {
  const { size } = props;

    return (
        <div className="NoVotesEmojiWrapper">
            <div className={`NoVotesEmoji NoVotesEmoji-${size}`}>
                <div/>
            </div>

            <span className={`noVotesPhrase noVotesPhrase-${size}`}>No votes</span>
        </div>
    );
};


export default NoVotesEmoji;