import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    CircularProgress
} from "@material-ui/core";

import {
    IBounds,
    ITileParameter
} from "src/types";

import {DataQuality} from "src/views/blocks";

import store, {TileAnalytic} from "src/store";


interface Props {
    parameterId?:string;
    bounds?:IBounds|null;
    quadKeys?:string[];
}

interface RState {
    parameter?:ITileParameter;
    getMeta:(name:string, query?:any) => any;
    getSummary:(type:string, query?:any) => any;
}

interface RActions {}

interface CProps extends Props, RState, RActions {}


class RatingViewDataQuality extends React.Component<CProps, any> {
    constructor(props:CProps) {
        super(props);

        this.state = {
            period: 3
        };
    }

    setPeriod(period:string) {
        this.setState({
            period: period
        });
    }

    render() {
        const {
            parameterId,
            parameter,
            bounds,
            quadKeys
        } = this.props;

        const {
            period
        } = this.state;

        const query = {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys: quadKeys
                } : {})
            })
        };

        const {
            loaded = false
        } = this.props.getMeta(TileAnalytic.modelName, query);

        const summary = this.props.getSummary(TileAnalytic.modelName, query);

        const {
            votes = 0,
            votes3 = 0,
            votes12 = 0,
            votesSurrounding = 0,
            votesSurrounding3 = 0,
            votesSurrounding12 = 0,
            totals = []
        } = summary;

        const items = period > 0 ? totals.slice(-period) : totals;

        let totalVotes = votes;
        let totalSurrounding = votesSurrounding;

        switch(period) {
            case 3:
                totalVotes = votes3;
                totalSurrounding = votesSurrounding3;
                break;

            case 12:
                totalVotes = votes12;
                totalSurrounding = votesSurrounding12;
                break;
        }

        if(!loaded) {
            return (
                <Grid container
                  justifyContent="center"
                  alignItems="center">
                    <Grid item
                      style={{
                        marginTop: 10,
                        marginBottom: 10
                      }}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            );
        }

        return (
            <DataQuality
              period={period}
              totalLabel={
                !parameterId
                    ? "Total general votes received"
                    : "Total " + (parameter ? parameter.title : "") + " votes received"
              }
              totalValue={totalVotes}
              averageLabel={
                !parameterId
                    ? "Average general votes received"
                    : "Average parameter votes received"
              }
              averageValue={totalSurrounding}
              data={items}
              onChangePeriod={(period:any) => this.setPeriod(period)} />
        );
    }
}


const putState = (state:any, props:Props):RState => {
    return {
        parameter: store.orm.getters.byId("tile-parameter", props.parameterId),
        getMeta: store.orm.getters.getMeta,
        getSummary: store.orm.getters.getSummary
    };
};

const putActions = ():RActions => {
    return {};
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions)
)(RatingViewDataQuality);