import {useSnackbar} from "notistack";
import {
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponse
} from "src/makes/Model";


const useShowMessages = () => {
    const {enqueueSnackbar} = useSnackbar();

    return (res:IResponse) => {
        const {
            status = "",
            message = ""
        } = res || {};

        if(message) {
            switch(status) {
                case STATUS_SUCCESS:
                    enqueueSnackbar(message, {
                        variant: "info"
                    });
                    break;

                case STATUS_ERROR:
                    enqueueSnackbar(message, {
                        variant: "error"
                    });
                    break;

                default:
                    break;
            }
        }
    };
};


export {useShowMessages};