import React, {useEffect} from "react";
import {
    Route,
    RouteProps,
    Redirect
} from "react-router-dom";

import {useAuth} from "src/hooks";

import {PleaseWaitForApprove} from "src/views/blocks";
import {DashboardLayout} from "src/views/layouts";

import {ROUTES} from "src/routing";
import store from "src/store";


type Props = RouteProps;

const DashboardRoute:React.FC<Props> = (props:Props) => {
    const {
        children,
        component,
        render,
        ...rest
    } = props;

    const auth = useAuth();

    useEffect(() => {
        store.auth.getUserRoles();
    }, []);

    return (
        <Route {...rest}>
            {!auth.isLoggedIn && (
                <Redirect to={ROUTES.home} />
            )}

            {auth.isLoggedIn && (
                <DashboardLayout>
                    {!auth.isAdmin && (
                        <PleaseWaitForApprove />
                    )}

                    {auth.isAdmin && (
                        <Route {...rest} component={component} render={render}>
                            {children}
                        </Route>
                    )}

                    {/*<pre>{JSON.stringify(auth.tokenData, null, 4)}</pre>*/}
                </DashboardLayout>
            )}
        </Route>
    );
};

export default DashboardRoute;