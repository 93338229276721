import React, {useCallback} from "react";
import {Link} from "react-router-dom";
import {
    AppBar,
    Toolbar,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Hidden
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    PersonOutline as PersonOutlineIcon
} from "@material-ui/icons";

import {ROUTES} from "src/routing";
import {useAuth} from "src/hooks";
import store from "src/store";

import {Menu} from "./blocks";


const DashboardHeader:React.FC = () => {
    const {
        isLoggedIn
    } = useAuth();

    const handleOpenDrawer = useCallback(() => {
        store.ui.setDrawerOpen(true);
    }, []);

    return (
        <AppBar
          className="header"
          position="fixed">
            <Toolbar className="header__toolbar">
                <Hidden only={["xl"]}>
                    <IconButton
                      onClick={handleOpenDrawer}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>

                <Link
                  className="header__logo"
                  to={ROUTES.home}>
                    <img
                      alt="CityChange Logo"
                      src="/cc-logo-positive-wide.png" />
                </Link>

                <span className="header__space" />

                <Menu>
                    {
                        !isLoggedIn ? (
                            <ListItem
                              component={Link}
                              button
                              to={ROUTES.login}>
                                <ListItemIcon
                                  style={{
                                    justifyContent: "center"
                                  }}>
                                    <PersonOutlineIcon />
                                </ListItemIcon>

                                <ListItemText
                                  primary="Login" />
                            </ListItem>
                        ) : (
                            <ListItem
                              component={Link}
                              button
                              to={ROUTES.logout}>
                                <ListItemText
                                  primary="Logout" />
                            </ListItem>
                        )
                    }
                </Menu>
            </Toolbar>
        </AppBar>
    );
};


export default DashboardHeader;