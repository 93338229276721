import {IModule} from "kpdux";

import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetOne,
    IResponseGetList,
    IResponseCreateOne
} from "src/makes/Model";
import {QueryString} from "src/makes/QueryString";


export interface OrmOptions {
    models?:any[];
}

type State = {
    [name:string]:Data;
}

interface Data {
    byId?:{[id:string]:any;},
    queryIds?:{[query:string]:string[]};
    queryCount?:{[query:string]:number;};
    queryMeta?:{[query:string]:any;};
    querySummary?:{[query:string]:any;};
}

interface Meta {
    total:number;
    count?:number;
    page?:number;
}


const ormFactory = (options:OrmOptions = {}):IModule<State> => {
    const {
        models = []
    } = options;

    const getModel = (name:string):any => {
        const model = models.find((model:any) => {
            return model.modelName === name;
        });

        return model ? model : Model;
    };

    const getQueryKey = (query:any = {}):string => {
        const queryData = JSON.parse(JSON.stringify(query));

        return QueryString.stringify(queryData);
    };

    return {
        state: {},
        getters: {
            getModel() {
                return (name:string) => {
                    const Model = models.find((Model) => {
                        return Model.modelName === name;
                    });

                    if(Model) {
                        return new Model(this);
                    }

                    return null;
                };
            },
            byId(state:State) {
                return (type:string, id:any) => {
                    const {
                        byId = {}
                    } = state[type] || {};

                    return byId[id] || null;
                };
            },
            find(state:State) {
                return (name:string, query:any = {}, count:number = 0, page:number = 0) => {
                    const key = getQueryKey(query);

                    const {
                        byId = {},
                        queryIds: {
                            [key]: ids = []
                        } = {}
                    } = state[name] || {};

                    return ids.filter((id:any, index:number) => {
                        if(count > 0 || page > 0) {
                            return (
                                index >= count * page &&
                                index < count * page + count
                            );
                        }

                        return true;
                    }).map((id) => {
                        return byId[id];
                    });
                };
            },
            filter(state:State) {
                return (type:string, filters:any = {}) => {
                    const Model = getModel(type);

                    if(Model) {
                        const {
                            byId = {}
                        } = state[type] || {};

                        return Object.keys(byId).filter((id) => {
                            if(!byId[id]) {
                                return false;
                            }

                            for(let key in filters) {
                                if(Model.filters && Model.filters[key]) {
                                    let bool = Model.filters[key](byId[id], filters[key]);

                                    if(!bool) {
                                        return bool;
                                    }
                                }
                            }

                            return true;
                        }).map((id) => {
                            return byId[id];
                        });
                    }

                    return [];
                };
            },
            filterOne(state, getters) {
                return (type:string, filters:any) => {
                    const [item] = getters.filter(type, filters);

                    return item || null;
                };
            },
            getMeta(state:State) {
                return (type:string, query:any = {}) => {
                    const key = getQueryKey(query);

                    const {
                        queryMeta: {
                            [key]: {
                                loading = false,
                                loaded = false,
                                ...other
                            } = {}
                        } = {}
                    } = state[type] || {};

                    return {
                        loading,
                        loaded,
                        ...other
                    };
                };
            },
            getSummary(state:State) {
                return (type:string, query:any = {}) => {
                    const key = getQueryKey(query);

                    const {
                        querySummary: {
                            [key]: summary = {}
                        } = {}
                    } = state[type] || {};

                    return summary;
                };
            }
        },
        actions: {
            async create(name:string, data:any):IResponseCreateOne<any> {
                const model = this.getters.getModel(name);

                if(model) {
                    try {
                        const res = await model.createOne(data);

                        if(res.status === STATUS_SUCCESS) {
                            const item = model.constructor.prepare
                                ? model.constructor.prepare(res.item)
                                : res.item;

                            this.saveOne(name, item.id, item);

                            return {
                                ...res,
                                item
                            };
                        }

                        return res;
                    }
                    catch(err:any) {
                        return {
                            status: STATUS_ERROR,
                            message: err.message
                        };
                    }
                }

                return {
                    status: STATUS_ERROR
                };
            },
            async update(name:string, id:any, data:any) {
                this.updateOne(name, id, data);

                return this.getters.byId(name, id);
            },
            async fetch(name:string, id:any):IResponseGetOne<any> {
                const model = this.getters.getModel(name);

                if(model) {
                    this.setMeta(name, {id}, {loading: true});

                    try {
                        const res = await model.getOne(id);

                        if(res.status === STATUS_SUCCESS) {
                            const item = typeof model.constructor.prepare === "function"
                                ? await model.constructor.prepare(res.item, "getOne")
                                : res.item;

                            this.setOne(name, item);
                            this.setMeta(name, {id}, {});

                            return {
                                ...res,
                                item
                            };
                        }

                        return res;
                    }
                    catch(err:any) {
                        return {
                            status: STATUS_ERROR,
                            message: err.message
                        };
                    }
                }

                return {
                    status: STATUS_ERROR
                };
            },
            async search(name:string, query:any = {}, count:number = 0, page:number = 0):IResponseGetList<any> {
                const model = this.getters.getModel(name);

                this.setMeta(name, {
                    ...this.getters.getMeta(name, query),
                    loading: true
                }, query);

                if(model) {
                    try {
                        const res = await model.getList(query, count, page);

                        if(res.status === STATUS_SUCCESS) {
                            const meta = {
                                total: res.total,
                                loaded: true
                            };

                            const items = [];

                            for(let i in res.items) {
                                const item = typeof model.constructor.prepare === "function"
                                    ? await model.constructor.prepare(res.items[i], "getList")
                                    : res.items[i];

                                items.push(item);
                            }

                            this.setList(name, items, query, count, page);

                            this.setMeta(name, meta, query);
                            this.saveSummary(
                                name,
                                model.constructor.listSummary(items, res, query),
                                query
                            );

                            return {
                                ...res,
                                items
                            };
                        }

                        return res;
                    }
                    catch(err:any) {
                        return {
                            status: STATUS_ERROR,
                            message: err.message
                        };
                    }
                }

                return {
                    status: STATUS_ERROR
                };
            }
        },
        mutations: {
            setMeta(state:State, name:string, meta:Meta, query:any) {
                const key = getQueryKey(query);

                state[name] = {
                    ...state[name] || {},
                    queryMeta: {
                        ...(state[name] || {}).queryMeta || {},
                        [key]: meta
                    }
                };
            },
            setOne(state:State, name:string, item:any) {
                if(item && typeof item.id !== "undefined") {
                    const {
                        [name]: {
                            byId = {}
                        } = {}
                    } = state;

                    if(item && item.id) {
                        byId[item.id] = item;

                        state[name] = {
                            ...state[name] || {},
                            byId
                        };
                    }
                }
            },
            setList(state:State, name:string, items:any[], query:any, count:number = 0, page:number = 0) {
                const key = getQueryKey(query);

                const {
                    byId = {},
                    queryIds: {
                        [key]: ids = []
                    } = {}
                } = state[name] || {};

                let startIndex = 0;
                let endIndex;

                if(count > 0 || page > 0) {
                    startIndex = count * page;
                    endIndex = startIndex + count;
                }
                else {
                    startIndex = 0;
                    endIndex = items.length;
                }

                for(let i = 0; i < endIndex - startIndex; i++) {
                    const item = items[i];

                    if(item) {
                        ids[i + startIndex] = item.id;
                        byId[item.id] = item;
                    }
                    else if(ids[i + startIndex]) {
                        delete ids[i + startIndex];
                    }
                }

                state[name] = {
                    ...state[name] || {},
                    byId: {...byId},
                    queryIds: {
                        ...(state[name] || {}).queryIds || {},
                        [key]: ids
                    }
                };
            },

            saveOne(state:State, type:string, id:any, item:any) {
                const {
                    byId = {}
                } = state[type] || {};

                byId[id] = item;

                state[type] = {
                    ...(state[type] || {}),
                    byId: {...byId}
                };
            },
            saveSummary(state:State, type:string, summary:any, query:any) {
                const key = getQueryKey(query);

                state[type] = {
                    ...(state[type] || {}),
                    querySummary: {
                        ...(state[type] || {}).querySummary || {},
                        [key]: summary
                    }
                };
            },
            updateOne(state:State, type:string, id:any, data:any) {
                const {
                    byId = {}
                } = state[type] || {};

                byId[id] = {
                    ...byId[id],
                    ...data
                };

                state[type] = {
                    ...(state[type] || {}),
                    byId: {...byId}
                };
            },
            removeOne(state, type:string, id:any) {
                //
            }
        }
    };
};


export type {State as OrmState};

export {ormFactory};