import React from "react";
import {
    WrappedFieldProps
} from "redux-form";
import {
    MenuItem
} from "@material-ui/core";

import {
    Select
} from "../../controls";


interface Props extends WrappedFieldProps {
    options?:{
        title?:any;
        value?:any;
    }[];
    required?:boolean;
    label?:string;
}


class SelectWrapper extends React.Component<Props, any> {
    render() {
        const {
            children,
            required,
            meta,
            input,
            label,
            options = []
        } = this.props;

        return (
            <Select
              {...input}
              required={required}
              label={label}
              error={meta.touched && meta.invalid}
              helperText={meta.touched && meta.error}>
                {(Array.isArray(options) ? options : []).length > 0 ? (
                    options.map((option, index:number) => {
                        return (
                            <MenuItem key={index} value={option.value}>
                                {option.title}
                            </MenuItem>
                        );
                    })
                ) : (children)}
            </Select>
        );
    }
}


export default SelectWrapper;