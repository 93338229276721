import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    withRouter,
    RouteComponentProps,
    Route
} from "react-router-dom";
import {
    Grid,
    Typography
} from "@material-ui/core";
import {
    EmojiObjectsOutlined as EmojiObjectsOutlinedIcon
} from "@material-ui/icons";

import {
    ISuggest,
    ISuggestCategory
} from "../../../../../types";

import QueryString from "../../../../../makes/QueryString";

import {
    PageSidebar,
    PageSidebarHeader,
    PageSidebarContent,
    PageScroll,
    PageTabs,
    PageTab,
    Emotion, NoVotesEmoji
} from "../../../../blocks";

import {
    SuggestContent,
    SuggestReactions,
    SuggestComments
} from "../../blocks";

import store from "../../../../../store";

import "./index.scss";


interface Props {
    suggestId:string;
    quadKeys:string[];
}

interface RState {
    byId:(type:string, id:any) => any;
}

interface CProps extends Props, RState, RouteComponentProps {}


class SuggestOverview extends React.Component<CProps, any> {
    render() {
        const {
            location: {
                search
            },
            suggestId,
            quadKeys
        } = this.props;

        const suggest = this.props.byId("suggest", suggestId);
        const category = suggest
                            ? this.props.byId("suggest-category", suggest.categoryId)
                            : null;

        const {
            title = "",
            rate = 0,
            votes
        } = suggest || {};

        // const quadKeysQuery = quadKeys && quadKeys.length > 0 ? "?" + QueryString.stringify({
        //     quadKeys: quadKeys
        // });

        return (
            <PageSidebar className="suggest-view-content">
                <PageSidebarHeader>
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#707070",
                        letterSpacing: "2.4px",
                        textTransform: "uppercase"
                      }}>
                        {category ? category.title : ""}
                    </Typography>

                    <Grid container
                      justifyContent="space-between"
                      alignItems="center">
                        <Grid item>
                            <Grid container component={Typography}
                              variant="h5"
                              alignItems="center">
                                <EmojiObjectsOutlinedIcon />

                                <span style={{paddingLeft: "5px"}}>
                                    {title}
                                </span>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {
                                        votes > 0 ? (
                                            <Emotion
                                              size="medium"
                                              rate={rate} />
                                        ) : (
                                            <NoVotesEmoji size="small" />
                                        )
                                    }
                                </Grid>

                                <Grid item component={Typography}
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    letterSpacing: "1px",
                                    paddingLeft: "10px"
                                  }}>
                                    {votes > 0 ? `${rate}%` : ""}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <PageTabs>
                        <PageTab exact
                          path="/suggestion/view/:suggestId"
                          to={"/suggestion/view/" + suggestId + search}
                          label="Suggestion" />

                        <PageTab
                          path="/suggestion/view/:suggestId/reactions"
                          to={"/suggestion/view/" + suggestId + "/reactions" + search}
                          label="Reactions" />

                        <PageTab
                          path="/suggestion/view/:suggestId/comments"
                          to={"/suggestion/view/" + suggestId + "/comments" + search}
                          label="Comments" />
                    </PageTabs>
                </PageSidebarHeader>

                <Route exact path="/suggestion/view/:suggestId">
                    <PageScroll>
                        <PageSidebarContent>
                            <SuggestContent
                              suggestId={suggestId} />
                        </PageSidebarContent>
                    </PageScroll>
                </Route>

                <Route path="/suggestion/view/:suggestId/reactions">
                    <PageScroll>
                        <PageSidebarContent>
                            <SuggestReactions
                              suggestId={suggestId} />
                        </PageSidebarContent>
                    </PageScroll>
                </Route>

                <Route path="/suggestion/view/:suggestId/comments">
                    <SuggestComments
                      suggestId={suggestId} />
                </Route>
            </PageSidebar>
        );
    }
}


const putState = ():RState => {
    return {
        byId: store.orm.getters.byId
    };
};

export default compose<React.ComponentType<Props>>(
    withRouter,
    connect(putState)
)(SuggestOverview);