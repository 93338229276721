import React, {
    Fragment
} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    Typography,
    Avatar
} from "@material-ui/core";
import ModalImage from "react-modal-image";

import {ISuggest} from "src/types";

import {
    PageRow,
    PageHtml
} from "src/views/blocks";

import store from "src/store";


interface Props {
    suggestId:string;
}

interface RState {
    byId:(type:string, id:any) => any;
}

interface CProps extends Props, RState {}


class SuggestContent extends React.Component<CProps, any> {
    render() {
        const {
            suggestId
        } = this.props;

        const suggest:ISuggest = this.props.byId("suggest", suggestId);

        const {
            description = "",
            user = null,
            image = null,
            votes = 0,
            commentsTotal = 0,
            updateDays = 0,
            date = ""
        } = suggest || {};

        return (
            <Fragment>
                <Grid container className="suggest-view-content__table" justifyContent="space-between">
                    <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                            Date
                        </Typography>

                        <Typography variant="body1">
                            {date}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                            Votes
                        </Typography>

                        <Typography variant="body1">
                            {votes}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                            Comments
                        </Typography>

                        <Typography variant="body1">
                            {commentsTotal}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                            Days since last activity
                        </Typography>

                        <Typography variant="body1">
                            {updateDays}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                            Suggested by
                        </Typography>

                        <Typography variant="body1">
                            {user ? user.fullName : ""}
                        </Typography>
                    </Grid>
                </Grid>

                <PageRow>
                    <Grid item xs={12}>
                        <PageHtml html={description} />
                    </Grid>

                    <Grid item xs={12}>
                        <ModalImage
                          className="suggest-view-content__image"
                          variant="square"
                          modalOpen
                          hideDownload
                          hideZoom
                          alt=""
                          large={image ? image.src : ""}
                          small={image ? image.src : ""} />
                    </Grid>
                </PageRow>
            </Fragment>
        );
    }
}


const putState = ():RState => {
    return {
        byId: store.orm.getters.byId
    };
};

export default compose(
    connect(putState)
)(SuggestContent);