import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";
import {Bounds, IBounds, IPoint} from "src/makes/Bounds";
import {QuadTile} from "src/makes/QuadTile";


interface IDemandVote {
    id:string;
    categoryId:string;
    quadKey:string;
    location:{
        lat:number;
        lon:number;
    };
    center:IPoint;
    score:number;
    demand:number;
}


class DemandVote extends Model<IDemandVote> {
    static modelName = "demand-vote";
    static filters = {
        score: (vote:IDemandVote, score:number) => {
            return vote.score === score;
        },
        scoreMoreOrEqualThan: (vote:IDemandVote, score:number) => {
            return vote.score >= score;
        },
        categoryId: (vote:IDemandVote, categoryId:string) => {
            return vote.categoryId === categoryId;
        },
        quadKey: (vote:IDemandVote, quadKey:string|string[]) => {
            if(Array.isArray(quadKey)) {
                return quadKey.reduce((includes:boolean, quadKey:string) => {
                    return includes || vote.quadKey.indexOf(quadKey) === 0;
                }, false);
            }
            else {
                return vote.quadKey.indexOf(quadKey) === 0;
            }
        },
        bounds: (vote:IDemandVote, bounds:IBounds) => {
            const ob = Bounds.fromBounds(bounds);

            if(ob) {
                return ob.hasPoint(vote.center);
            }

            return false;
        }
    };

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<IDemandVote> {
        const {
            categoryId,
            quadKey
        } = query;

        if(!categoryId) {
            return {
                status: STATUS_ERROR
            };
        }

        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        const {
            response: {
                message = ""
            } = {}
        } = res;

        if(res.httpCode === 200) {
            const {
                response: {
                    count: total,
                    data: items
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: items.map((item:any) => {
                    const {
                        id = null,
                        categoryId,
                        quadKey = "",
                        demand = 0,
                        center: {
                            lat,
                            lon
                        },
                        score
                    } = item;

                    return {
                        id: id || (`${categoryId}-${quadKey}`),
                        categoryId,
                        score,
                        demand,
                        center: {
                            lat,
                            lng: lon
                        }
                    };
                })
            };
        }

        return {
            status: STATUS_ERROR,
            message
        };
    }
}


export type {IDemandVote};

export {DemandVote};