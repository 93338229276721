import React, {useRef} from "react";
import {
    useHistory,
    useLocation
} from "react-router-dom";

import {IBounds, IArea} from "src/types";
import {
    useSelector,
    useUI
} from "src/hooks";

import {
    Map,
    MapRef,
    MapEventMove,
    MapEventClick,
    MapEventSelect
} from "src/views/blocks";

import {
    rateToColor,
    calcRate
} from "src/tools";
import QueryString from "src/makes/QueryString";
import QuadTile from "src/makes/QuadTile";

import store, {
    useOrmGetters,
    useORM,
    ICityCenter,
    CityCenter,
    ITile,
    Tile
} from "src/store";


type Props = {
    parameterId?:string;
    quadKeys?:string[];
    bounds?:IBounds;
};

const RatingMap:React.FC<Props> = (props:Props) => {
    const {
        parameterId,
        bounds,
        quadKeys
    } = props;

    const history = useHistory();
    const {pathname} = useLocation();

    const refMap = useRef<MapRef>(null);
    const {
        mapMethod,
        mapBounds,
        mapQuadKeys
    } = useSelector((state) => state.ui);
    const orm = useOrmGetters();
    const ui = useUI();

    const isDev = ui.isDev;

    const cityCenters:ICityCenter[] = useORM((orm) => {
        return orm.find(CityCenter.modelName, {
            type: "overview"
        });
    });

    const tiles:ITile[] = useORM((orm) => {
        return orm.filter(Tile.modelName, {
            quadKey: mapQuadKeys
        });
    });

    const handleMove = async (e:MapEventMove) => {
        if(e.height <= 2000) {
            const quadKeys:string[] = e.tiles.map((tile:QuadTile) => {
                return tile.getQuadKey();
            });

            store.ui.setMapQuadKeys(quadKeys);

            for(let i in e.tiles) {
                let quadTile:QuadTile = e.tiles[i];

                let quadKey = quadTile.getQuadKey();
                let bounds = quadTile.getBounds();

                if(bounds) {
                    let area:IArea|null = orm.filterOne("area", {
                        type: "overview",
                        quadKey: quadKey
                    });

                    if(!area) {
                        area = await store.orm.create("area", {
                            type: "overview",
                            quadKey: quadKey,
                            loaded: false
                        });
                    }

                    if(area && !area.loaded) {
                        const res:any = await store.orm.search("tile", {
                            bounds: bounds
                        });

                        if(res.status === "OK") {
                            await store.orm.update("area", area.id, {
                                loaded: true
                            });
                        }
                    }
                }
            }
        }
    };

    const handleClick = (e:MapEventClick) => {
        const {
            tile
        } = e;

        const bounds = tile.getBounds();
        const query = QueryString.stringify({
            ...(bounds || {})
        });

        history.replace(pathname + (query ? "?" + query : ""));
    };

    const handleSelect = (e:MapEventSelect) => {
        const {
            selectBounds
        } = e;

        const query = QueryString.stringify({
            ...(selectBounds || {})
        });

        history.replace(pathname + (query ? "?" + query : ""));
    };

    const handleClickMarker = (cityCenter:ICityCenter) => {
        if(refMap.current) {
            refMap.current.flyTo(cityCenter.point, 1000);
        }
    };

    return (
        <Map
          ref={refMap}
          width="100%"
          height="100%"
          selectable={true}
          multiple={mapMethod !== "single"}
          dragging={mapMethod === "single"}
          initialBounds={mapBounds}
          debug={isDev}
          markers={cityCenters.map((cityCenter:ICityCenter) => {
            return {
                ...cityCenter,
                onClick: () => {
                    handleClickMarker(cityCenter);
                }
            };
          })}
          tiles={tiles.filter((tile:ITile) => {
              if(parameterId) {
                  const {
                      voteParameters: {
                          [parameterId]: total = null
                      } = {}
                  } = tile;

                  if(!total) {
                      return false;
                  }
              }

              return true;
          }).map((tile:ITile) => {
            const {
                quadKey,
                votesPositive,
                votesNeutral,
                votesNegative,
                voteParameters: {
                    [parameterId || ""]: parameter = null
                } = {}
            } = tile;

            return {
                quadKey: quadKey,
                color: rateToColor(
                    parameterId
                            ? (
                                parameter
                                    ? calcRate(parameter.positive, parameter.neutral, parameter.negative)
                                    : 0
                            )
                            : calcRate(votesPositive, votesNeutral, votesNegative)
                )
            };
          })}
          selectBounds={bounds}
          selectKeys={quadKeys}
          onMove={handleMove}
          onClick={handleClick}
          onSelect={handleSelect} />
    );
};


export default RatingMap;