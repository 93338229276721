import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Typography
} from "@material-ui/core";
import {
    ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";

import {
    IBounds
} from "../../../../../types";

import {
    PageSidebar,
    PageSidebarHeader,
    PageSidebarContent,
    PageScroll,
    PageTabs,
    PageTab
} from "../../../../blocks";

import {
    ParameterTable
} from "./blocks";

import store from "../../../../../store";
import Tooltip from "@material-ui/core/Tooltip";


interface Props {
    parameterId?:string;
    bounds?:IBounds|undefined;
    quadKeys?:string[];
}

interface RState {
    byId:(type:string, id:string) => any;
    getSummary:(type:string, query?:any) => any;
}

interface RActions {}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class RatingViewParameterList extends React.Component<CProps, any> {
    onClickItem(item:any) {
        const {
            location: {
                search
            },
            history
        } = this.props;

        const {
            parameterId
        } = item;

        history.push("/rating-view/parameter/" + parameterId + search);
    }

    render() {
        const {
            parameterId,
            bounds,
            quadKeys
        } = this.props;

        const {
            parameters = []
        } = this.props.getSummary("tile", {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        let parameterTotals = parameters.map((total:any) => {
            const {
                parameterId
            } = total;

            const parameter = this.props.byId("tile-parameter", parameterId);

            return {
                title: parameter ? parameter.title : parameterId,
                ...total
            };
        });

        return (
            <PageSidebar className="page-rating-view__parameters">
                <PageSidebarHeader>
                    <Typography variant="h5">
                        Parameters
                    </Typography>

                    <PageTabs
                      value={0}>
                        <PageTab
                          label="Total" />

                        <PageTab
                          inDevelopment
                          icon={<ArrowDropDownIcon style={{width: "30px", height: "30px", marginBottom: 0}} />}
                          label="Filtered" />
                    </PageTabs>
                </PageSidebarHeader>

                <PageScroll>
                    <PageSidebarContent>
                        <ParameterTable
                          items={parameterTotals}
                          onClickItem={(item:any) => this.onClickItem(item)} />
                    </PageSidebarContent>
                </PageScroll>
            </PageSidebar>
        );
    }
}


const putState = ():RState => {
    return {
        byId: store.orm.getters.byId,
        getSummary: store.orm.getters.getSummary
    };
};

const putActions = ():RActions => {
    return {};
};

export default compose<React.ComponentType<Props>>(
    withRouter,
    connect(putState, putActions)
)(RatingViewParameterList);