import React from "react";
import {
    CircularProgress
} from "@material-ui/core";

import "./index.scss";


interface Props {}

interface State {}


class AppLoaderPage extends React.Component<Props, State> {
    render() {
        return (
            <div className="app-loader">
                <div className="app-loader__progress">
                    <CircularProgress />
                </div>
            </div>
        );
    }
}


export default AppLoaderPage;