import React from "react";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {RateCircle} from "src/views/blocks";

import "./index.scss";


interface Props {
    title:string;
    icon?:any;
    variant:string;
    border:boolean;
    parameters:any[];
}


class SidebarParameters extends React.Component<Props, any> {
    static defaultProps = {
        title: "",
        variant: "border",
        border: true,
        parameters: []
    };

    render() {
        const {
            variant,
            title,
            icon,
            border,
            parameters
        } = this.props;

        return (
            <Grid container
              className="sidebar-parameters">
                {
                    title && (
                        <Grid item xs={12} className="sidebar-parameters__title">
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h6">
                                        {title}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h6">
                                        {icon}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }

                {
                    (() => {
                        switch(variant) {
                            case "circular":
                                return (
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={title === "Comments" ? "flex-start" : "space-between"}>
                                            {
                                                parameters.map((parameter:any, index:number) => {
                                                    return (
                                                        <RateCircle
                                                          key={index}
                                                          style={{ margin: title === "Comments" ? "0 8px 8px 0" : 0 }}
                                                          title={parameter.title}
                                                          color={parameter.color}
                                                          value={parameter.value} />
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                );

                            case "no-border":
                            default:
                                return parameters.map((parameter:any, index:number) => {
                                    return (
                                        <Grid
                                          key={index}
                                          className={
                                            [
                                                "sidebar-parameters__item",
                                                border ? "" : "sidebar-parameters__item--noborder"
                                            ].join(" ")
                                          }
                                          container
                                          justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="body1">
                                                    {parameter.title}
                                                </Typography>
                                            </Grid>

                                            <Grid item>
                                                <Typography variant="body1">
                                                    {parameter.rate}%
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                });
                        }
                    }) ()
                }
            </Grid>
        );
    }
}


export default SidebarParameters;