import React from "react";
import {compose} from "redux";
import {
    reduxForm,
    Form,
    ConfigProps,
    InjectedFormProps,
    DecoratedFormProps,
    FormErrors
} from "redux-form";
import {
    Box,
    Grid,
    Typography,
    Button
} from "@material-ui/core";

import {
    isRequired
} from "../../../tools";

import {
    Logo,
    DebugPanel
} from "../../blocks";

import {
    InputField
} from "../../fields";

import "./index.scss";


export interface FormData {
    email:string;
    password:string;
    passwordConfirm:string;
}

interface FProps {}

interface Props extends Partial<ConfigProps<FormData, FProps>> {}

interface CProps extends InjectedFormProps<FormData, FProps> {}


class PWUpdateForm extends React.Component<CProps, any> {
    debugFillForm() {
        this.props.autofill("password", "1234567890");
        this.props.autofill("passwordConfirm", "1234567890");
    }

    render() {
        const {
            handleSubmit,
            submitting
        } = this.props;

        return (
            <Form
              className="form-pw-update"
              noValidate={true}
              onSubmit={handleSubmit}>
                <Box p={3 / 2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Logo
                                  size="large"
                                  alt="Logo"
                                  variant="circular"
                                  src="/logo.svg" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Typography component={Box}
                                  variant="h5"
                                  textAlign="center"
                                  pb={1}>
                                    Set new password
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              //disabled
                              required
                              type="text"
                              fullWidth
                              label="Email"
                              name="email" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              required
                              type="password"
                              fullWidth
                              label="New password"
                              name="password" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              required
                              type="password"
                              fullWidth
                              label="Confirm new password"
                              name="passwordConfirm" />
                        </Grid>

                        <Grid item xs={12}>
                            <Box pt={2} pb={1}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  disabled={submitting}>
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <DebugPanel>
                    <Box p={3 / 2}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item>
                                <Button type="button"
                                  variant="contained"
                                  onClick={() => this.debugFillForm()}>
                                    Fill form
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DebugPanel>
            </Form>
        );
    }
}


export default compose<React.ComponentType<Props>>(
    reduxForm<FormData, FProps>({
        form: "pw-update",
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: false,
        validate(values:FormData, props:DecoratedFormProps<FormData, FProps>):FormErrors<FormData> {
            const errors:FormErrors<FormData> = {};

            if(values.password && values.passwordConfirm) {
                if(values.password !== values.passwordConfirm) {
                    errors.passwordConfirm = "Passwords do not match";
                }
            }

            return errors;
        }
    })
)(PWUpdateForm);