import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
    PropsWithChildren
} from "react";
import {batch} from "react-redux";
import {Button} from "@material-ui/core";

import {
    MAP_TERRAIN,
    MAP_SATELLITE,
    MapType,
    IMapTile,
    IMapPoint,
    IMarker,
    MapMouseEvent,
    MapEventClick,
    MapEventMove,
    MapEventSelect,
    MapMouseEventMove
} from "./types";

import {
    Map2D,
    Map2DRef
} from "./blocks";

import {IBounds, IPoint} from "src/makes/Bounds";
import store, {useStorageGetters} from "src/store";

import "./index.scss";


type Props = {
    className?:string;
    width?:string;
    height?:string
    dragging?:boolean;
    selectable?:boolean;
    multiple?:boolean;
    debug?:boolean;
    areaType?:string;
    heatmap?:boolean;
    markers?:IMarker[];
    points?:IMapPoint[];
    tiles?:IMapTile[];
    polygons?:any[];
    selectKeys?:string[];
    selectBounds?:IBounds;
    initialBounds?:IBounds|null|undefined;
    onMove?:(e:MapEventMove) => void;
    onMouseMove?:(e:MapMouseEventMove) => void;
    onClick?:(e:MapEventClick) => void;
    onSelect?:(e:MapEventSelect) => void;
};

type MapRef = {
    flyTo:(center:IPoint, height:number) => any;
};

const Map = forwardRef<MapRef, Props>((props:PropsWithChildren<Props>, ref) => {
    const {
        width,
        height,
        className,
        selectable = false,
        dragging = true,
        multiple = false,
        heatmap = false,
        selectBounds,
        selectKeys,
        debug,
        areaType,
        markers,
        points,
        tiles,
        polygons,
        onMove,
        onClick,
        onSelect,
        onMouseMove
    } = props;

    const storage = useStorageGetters();

    const [mapType, setMapType] = useState<MapType>(MAP_TERRAIN);
    const [zoom] = useState(parseInt(storage.getItem("map:zoom")) || 3);
    const [center] = useState(storage.getItem("map:center", {lat: 0, lng: 0}));
    const [mapHeight, setMapHeight] = useState(0);
    // const [lastCursorPosition, setLastCursorPosition] = useState<IPoint|null>(null);

    const refMap2D = useRef<Map2DRef>(null);

    const handleMouseMove = useCallback((e:MapMouseEvent) => {
        if(onMouseMove) {
            onMouseMove(e);
        }
    }, [onMouseMove]);

    const handleMove = useCallback((e:MapEventMove) => {
        const {
            height,
            zoom,
            center,
            bounds
        } = e;

        setMapHeight(height);

        batch(() => {
            store.ui.setZoom(zoom);
            store.ui.setCenter(center);
            store.ui.setMapBounds(bounds);
        });

        if(onMove) {
            onMove(e);
        }
    }, [onMove]);

    const handleClick = useCallback((e:MapEventClick) => {
        if(onClick) {
            onClick(e);
        }
    }, [onClick]);

    const handleSelect = useCallback((e:MapEventSelect) => {
        if(onSelect) {
            onSelect(e);
        }
    }, [onSelect]);

    const flyTo = useCallback((center:IPoint, height:number) => {
        if(refMap2D.current) {
            // @ts-ignore
            refMap2D.current.flyTo(center, height);
        }
    }, []);

    const toggleMapType = useCallback(() => {
        setMapType(mapType === MAP_SATELLITE ? MAP_TERRAIN : MAP_SATELLITE);
    }, [mapType]);

    const is3D = !mapHeight || mapHeight > 2020;

    useImperativeHandle(ref, () => {
        return {
            flyTo
        };
    });

    return (
        <div
          style={{width, height}}
          className={["map", className || ""].join(" ")}>
            <Map2D
              ref={refMap2D}
              width="100%"
              height="100%"
              mapType={mapType}
              selectable={selectable}
              debug={debug}
              heatmap={heatmap}
              zoom={zoom}
              center={center}
              {...is3D ? {
                markers,
                selectable: false
              } : {
                multiple,
                dragging,
                points,
                tiles,
                polygons
              }}
              selectBounds={selectBounds}
              selectKeys={selectKeys}
              onMouseMove={handleMouseMove}
              onMove={handleMove}
              onClick={handleClick}
              onSelect={handleSelect} />

            <Button
              className="map-view-switcher"
              variant="contained"
              color="default"
              onClick={toggleMapType}>
                {mapType === MAP_TERRAIN ? "Satellite" : "Terrain"}
            </Button>
        </div>
    );
});

Map.displayName = "Map";


export type {
    MapRef,
    MapEventClick,
    MapEventMove,
    MapEventSelect,
    MapMouseEvent,
    MapMouseEventMove
};

export default Map;