import React from "react";
import {compose} from "redux";
import {
    Form,
    reduxForm,
    ConfigProps,
    InjectedFormProps,
    DecoratedFormProps,
    FormErrors
} from "redux-form";
import {
    Link
} from "react-router-dom";
import {
    Box,
    Grid,
    Button,
    Typography
} from "@material-ui/core";

import {
    isRequired
} from "../../../tools";

import {
    Logo,
    DebugPanel
} from "../../blocks";

import {
    InputField
} from "../../fields";

import "./index.scss";


export interface FormData {
    login:string;
    password:string;
}

interface FProps {}

interface Props extends Partial<ConfigProps<FormData, FProps>> {}

interface CProps extends InjectedFormProps<FormData, FProps> {}

class LoginForm extends React.Component<CProps, any> {
    debugFillForm() {
        this.props.autofill("username", process.env.REACT_APP_TEST_EMAIL);
        this.props.autofill("password", process.env.REACT_APP_TEST_PASSWORD);
    }

    render() {
        const {
            handleSubmit,
            submitting
        } = this.props;

        return (
            <Form
              className="form-login"
              noValidate={true}
              onSubmit={handleSubmit}>
                <Box p={3 / 2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Logo
                                  size="large"
                                  variant="circular"
                                  alt="Logo"
                                  src="/favicon-96x96.png" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Typography component={Box}
                                  variant="h5"
                                  textAlign="center"
                                  pb={1}>
                                    Sign in to CityChange
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={isRequired}
                              type="text"
                              required
                              fullWidth
                              label="Login"
                              name="username" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={isRequired}
                              type="password"
                              required
                              fullWidth
                              label="Password"
                              name="password" />
                        </Grid>

                        <Grid item xs={12}>
                            <Box pt={2} pb={1}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  disabled={submitting}>
                                    Sign In
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Button component={Link} to="/signup">
                                        Sign up
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button component={Link} to="/reset">
                                        Forgot password
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <DebugPanel>
                    <Box p={3 / 2}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item>
                                <Button type="button"
                                  variant="contained"
                                  onClick={() => this.debugFillForm()}>
                                    Fill form
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DebugPanel>
            </Form>
        );
    }
}


export default compose<React.ComponentType<Props>>(
    reduxForm<FormData, FProps>({
        form: "login",
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: false,
        validate(values:FormData, props:DecoratedFormProps<FormData, FProps>):FormErrors<FormData> {
            const errors:FormErrors<FormData> = {};

            return errors;
        }
    })
)(LoginForm);