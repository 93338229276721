import React from "react";
import {Link} from "react-router-dom";
import {
    ListItem,
    ListItemText
} from "@material-ui/core";


type Props = {
    selected?:boolean;
    disabled?:boolean;
    id:string;
    title:string;
};

const CategoryItem:React.FC<Props> = (props:Props) => {
    const {
        selected = false,
        disabled = false,
        id,
        title
    } = props;

    return (
        <ListItem component={Link} button dense
          selected={selected}
          disabled={disabled}
          to={`/demand/${id}`}>
            <ListItemText
              primary={title} />
        </ListItem>
    );
};


export default CategoryItem;