import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {
    useLocation,
    useParams,
    useHistory
} from "react-router-dom";
import {
    makeStyles,
    Theme,
    Grid,
    Typography
} from "@material-ui/core";
import {ArrowDropDown as ArrowDropDownIcon} from "@material-ui/icons";

import {
    parseQueryBounds,
    parseQuadKeys
} from "src/tools";

import {
    DebugPanel,
    DebugTable,
    Page,
    PageHeader,
    PageNavbar,
    PageWrapper,
    PageContent,
    PageColumn,
    PageTabs,
    PageTab
} from "src/views/blocks";

import {Switch} from "src/views/controls";

import {
    ParameterList,
    RatingMap,
    RatingSidebar
} from "./blocks";

import store, {
    useORM,
    CityCenter,
    Tile,
    TileAnalytic,
    TileParameter
} from "src/store";

import "./index.scss";


interface Props {}

interface UrlParams {
    parameterId?:string;
}

const RatingPage:React.FC<Props> = (props:Props) => {
    const classes = useStyles();
    const history = useHistory();
    const {search} = useLocation();
    const {parameterId} = useParams<UrlParams>();
    const {mapMethod} = useSelector((state:any) => state.ui);
    const [quadKeys, setQuadKeys] = useState(parseQuadKeys(search));
    const [bounds, setBounds] = useState(parseQueryBounds(search));
    const [activeTab, setActiveTab] = useState(parameterId ? 1 : 0);
    const [isOpenParameters, setIsOpenParameters] = useState(false);

    // this.state = {
    //     load: true,
    //     activeTab: parameterId ? 1 : 0,
    //     isOpenParameters: false
    // };

    const summary = useORM((orm) => {
        return orm.getSummary("tile", {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys: quadKeys
                } : {})
            })
        });
    });

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(CityCenter.modelName, {
                type: "overview"
            });

            await store.orm.search(TileParameter.modelName);
        };

        loadData();
    }, []);

    useEffect(() => {
        const quadKeys = parseQuadKeys(search);
        const bounds = parseQueryBounds(search);

        setQuadKeys(quadKeys);
        setBounds(bounds);
    }, [search]);

    useEffect(() => {
        const loadData = async () => {
            await store.orm.search(Tile.modelName, {
                withSummary: true,
                parameterId: parameterId,
                ...(bounds ? {
                    bounds: bounds
                } : {
                    ...(quadKeys ? {
                        quadKeys: quadKeys
                    } : {})
                })
            });

            await store.orm.search(TileAnalytic.modelName, {
                withSummary: true,
                parameterId: parameterId,
                ...(bounds ? {
                    bounds: bounds
                } : {
                    ...(quadKeys ? {
                        quadKeys: quadKeys
                    } : {})
                })
            });
        };

        if(bounds || (quadKeys || []).length > 0) {
            loadData();
        }
    }, [parameterId, quadKeys, bounds]);

    const handleChangeTab = (event:React.ChangeEvent<any>, newActiveTab:number) => {
        if(newActiveTab === 0) {
            setActiveTab(newActiveTab);
            setIsOpenParameters(false);

            history.push("/rating" + search);
        }

        if(newActiveTab === 1) {
            if(newActiveTab === activeTab) {
                setActiveTab(!parameterId && isOpenParameters ? 0 : newActiveTab);
                setIsOpenParameters(!isOpenParameters);
            }
            else {
                setActiveTab(newActiveTab);
                setIsOpenParameters(true);
            }
        }
    };

    const handleChangeMethod = (e:any) => {
        if(e.target.checked) {
            store.ui.setMapMethod("multiple");
        }
        else {
            store.ui.setMapMethod("single");
        }
    };

    return (
        <Page className="page-rating">
            <PageHeader deep={3}>
                <PageNavbar item spacing={3}>
                    <Grid item className={classes.sidebarWidth}>
                        <Typography variant="h3">
                            Tiles overview
                        </Typography>
                    </Grid>

                    <Grid item style={{flex: 1}}>
                        <Grid container
                          justifyContent="space-between"
                          alignItems="flex-end">
                            <PageTabs
                              value={activeTab}
                              onChange={handleChangeTab}>
                                <PageTab
                                  label="General" />

                                <PageTab
                                  icon={<ArrowDropDownIcon style={{width: "30px", height: "30px", marginBottom: 0}} />}
                                  label="Parameter" />
                            </PageTabs>

                            <div className="page-rating__switch-wrapper">
                                <Typography variant="body2" display="inline" gutterBottom>
                                    Pan / select tile
                                </Typography>

                                <Switch
                                  checked={mapMethod === "multiple"}
                                  onChange={handleChangeMethod} />

                                <Typography variant="body2" display="inline" gutterBottom>
                                    Select area
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </PageNavbar>
            </PageHeader>

            <PageWrapper deep={3}>
                <PageContent item
                  className={[
                    classes.sidebar,
                    classes.sidebarWidth
                  ].join(" ")}>
                    <PageColumn className="page-rating__sidebar">
                        <RatingSidebar
                          parameterId={parameterId}
                          bounds={bounds}
                          quadKeys={quadKeys} />
                    </PageColumn>
                </PageContent>

                <PageContent item className={classes.content}>
                    <PageColumn className="page-rating__content">
                        <div className="page-rating__tab-content">
                            <ParameterList
                              parameterId={parameterId}
                              open={isOpenParameters}
                              onClose={() => setIsOpenParameters(false)} />
                        </div>

                        <div className="page-rating__map">
                            <RatingMap
                              parameterId={parameterId}
                              bounds={bounds}
                              quadKeys={quadKeys} />
                        </div>
                    </PageColumn>
                </PageContent>
            </PageWrapper>

            <DebugPanel>
                <Grid container>
                    {summary.log && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <DebugTable
                                  title="Genders"
                                  headers={[
                                    {name: "key"},
                                    {name: "positive"},
                                    {name: "neutral"},
                                    {name: "negative"},
                                    {name: "rate"},
                                    {name: "relativeCalc"},
                                    {name: "relativeRate"}
                                  ]}
                                  data={summary.log.genders.map((item:any) => {
                                      const rel = summary.relative.find((rel:any) => {
                                          return rel.key === item.key;
                                      });

                                      if(rel) {
                                          return {
                                              ...item,
                                              relativeRate: rel.rate,
                                              relativeCalc: rel.rateLog
                                          };
                                      }

                                      return item;
                                  })} />

                                <DebugTable
                                  title="Groups"
                                  headers={[
                                    {name: "key"},
                                    {name: "positive"},
                                    {name: "neutral"},
                                    {name: "negative"},
                                    {name: "rate"},
                                    {name: "relativeCalc"},
                                    {name: "relativeRate"}
                                  ]}
                                  data={summary.log.groups.map((item:any) => {
                                      const rel = summary.relative.find((rel:any) => {
                                          return rel.key === item.key;
                                      });

                                      if(rel) {
                                          return {
                                              ...item,
                                              relativeRate: rel.rate,
                                              relativeCalc: rel.rateLog
                                          };
                                      }

                                      return item;
                                  })} />

                                <DebugTable
                                  title="Transports"
                                  headers={[
                                    {name: "key"},
                                    {name: "positive"},
                                    {name: "neutral"},
                                    {name: "negative"},
                                    {name: "rate"},
                                    {name: "relativeCalc"},
                                    {name: "relativeRate"}
                                  ]}
                                  data={summary.log.transports.map((item:any) => {
                                      const rel = summary.relative.find((rel:any) => {
                                          return rel.key === item.key;
                                      });

                                      if(rel) {
                                          return {
                                              ...item,
                                              relativeRate: rel.rate,
                                              relativeCalc: rel.rateLog
                                          };
                                      }

                                      return item;
                                  })} />
                            </Grid>
                        </React.Fragment>
                    )}

                    <Grid item xs={6}>
                        <pre>{JSON.stringify({
                            rate: summary.rate,
                            relative: summary.relative
                        }, null, 4)}</pre>
                    </Grid>

                    <Grid item xs={6}>
                        <pre>{JSON.stringify(summary.log, null, 4)}</pre>
                    </Grid>
                </Grid>
            </DebugPanel>
        </Page>
    );
};


const useStyles = makeStyles((theme:Theme) => {
    return {
        sidebar: {
            [theme.breakpoints.down("md")]: {
                order: 2
            }
        },
        sidebarWidth: {
            display: "flex",
            flexGrow: 0,
            flexBasis: "33.333333%",
            maxWidth: "33.333333%",
            [theme.breakpoints.only("xl")]: {
                flexBasis: "544px",
                maxWidth: "544px"
            },
            [theme.breakpoints.down("md")]: {
                flexBasis: "100%",
                maxWidth: "none"
            }
        },
        content: {
            width: "auto",
            flexGrow: 1,
            [theme.breakpoints.down("md")]: {
                order: 1,
                width: "100%",
                height: "500px"
            }
        },
        contentWidth: {
            width: "auto",
            flexGrow: 1
        }
    };
});

export default RatingPage;