import React from "react";
import {
    Grid,
    GridProps
} from "@material-ui/core";

import {toHtmlContent} from "../../../../tools";


interface Props extends GridProps {
    html?:string;
}


class PageHtml extends React.Component<Props, any> {
    render() {
        const {
            className,
            html,
            ...rest
        } = this.props;

        return (
            <Grid container
              className={[
                "page-html",
                className
              ].join(" ")}
              dangerouslySetInnerHTML={{
                __html: toHtmlContent(html)
              }}
              {...rest} />
        );
    }
}


export default PageHtml;