import React from "react";
import {compose} from "redux";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Tab,
    TabProps,
    createStyles,
    withStyles, Tooltip
} from "@material-ui/core";

import {getRestProps} from "../../../tools";

import "./styles.scss";

interface Props extends TabProps {
    exact?:boolean;
    path?:string;
    to?:string;
    inDevelopment?:boolean;
}

interface CProps extends Props, RouteComponentProps {}


const PageTab:React.FC<CProps> = (props:CProps) => {
    const rest = getRestProps(props, [
        "icon",
        "label",
        // "fullWidth",
        "indicator",
        "selected",
        "selectionFollowsFocus",
        "onChange",
        "textColor",
        "value"
    ]);

    const {
        to,
        history,
        inDevelopment
    } = props;

    return (
        <>
            {inDevelopment ? (
                <Tooltip title="In development" arrow placement="top">
                    <Tab
                      style={{
                          cursor: "default"
                      }}
                      className="custom-tab"
                      {...rest} />
                </Tooltip>
        ) : (
            <Tab
              onClick={() => to && history.push(to)}
              className="custom-tab"
              {...rest} />
        )}
        </>
    );
};


const styles = () => createStyles({});

export default compose<React.ComponentType<Props>>(
    withStyles(styles),
    withRouter
)(PageTab);