import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Route, withRouter, RouteComponentProps} from "react-router-dom";
import * as lodash from "lodash";
import {
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon
} from "@material-ui/icons";

import {
    IBounds,
    ITileComment
} from "src/types";

import {
    PageSidebar,
    PageSidebarHeader,
    PageScroll,
    CommentList
} from "src/views/blocks";

import RatingComment from "../../../RatingPage/blocks/RatingComment";

import store, {TileComment} from "src/store";


interface Props {
    hasParameter?:boolean;
    parameterId?:string;
    quadKeys:string[];
    bounds?:IBounds;
}

interface State {
    value:number|null;
    count:number;
    page:number;
    orderBy:string;
    orderWay:string;
}

interface RState {
    find:(name:string, query?:any) => any[];
    getMeta:(name:string, query?:any) => any;
}

interface RActions {
    search:(name:string, query?:any, count?:number, page?:number) => Promise<any[]>;
}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class RatingComments extends React.Component<CProps, State> {
    static defaultProps = {
        hasParameter: false
    };

    get filters():any {
        const {
            hasParameter,
            bounds,
            quadKeys
        } = this.props;

        const {
            value,
            count,
            page,
            orderBy,
            orderWay
        } = this.state;

        return {
            ...(hasParameter ? {
                hasParameter: "true"
            } : {
                hasParameter: "false"
            }),
            ...(value !== null ? {value: value} : {}),
            ...(bounds ? {
                bounds: bounds
            } : {
                quadKeys: quadKeys
            }),
            orderBy: orderBy,
            orderWay: orderWay
        };
    }

    get isLoading():boolean {
        const {
            loading = false
        } = this.props.getMeta(TileComment.modelName, this.filters);

        return loading;
    }

    get maxPage():number {
        const {
            count
        } = this.state;

        const {
            total = 0
        } = this.props.getMeta(TileComment.modelName, this.filters);

        return Math.ceil(total / count);
    }

    constructor(props:CProps) {
        super(props);

        this.state = {
            value: null,
            count: 10,
            page: -1,
            orderBy: "date",
            orderWay: "desc"
        };
    }

    componentDidMount() {
        const {
            bounds,
            quadKeys
        } = this.props;

        if(bounds || quadKeys.length) {
            this.load();
        }
    }

    componentDidUpdate(prevProps:Readonly<CProps>, prevState:Readonly<State>, snapshot?:any) {
        const {
            bounds,
            quadKeys,
            location
        } = this.props;

        if(!lodash.isEqual(bounds, prevProps.bounds) || !lodash.isEqual(quadKeys, prevProps.quadKeys)) {
            if(bounds || quadKeys.length) {
                this.load();
            }
        }

        if(location.pathname !== prevProps.location.pathname) {
            if(bounds || quadKeys.length) {
                this.load();
            }
        }
    }

    setOrder(orderBy:string) {
        if(this.state.orderBy === orderBy) {
            this.setState({
                orderWay: this.state.orderWay === "asc" ? "desc" : "asc"
            }, () => {
                this.load();
            });
        }
        else {
            this.setState({
                orderBy: orderBy,
                orderWay: "desc"
            }, () => {
                this.load();
            });
        }
    }

    setValue(value:number|null) {
        this.setState({
            value: value
        }, () => {
            this.load();
        });
    }

    load() {
        this.setState({
            page: -1
        }, () => {
            this.nextPage();
        });
    }

    nextPage() {
        const {
            count,
            page
        } = this.state;

        if(page === -1 || (page + 1 < this.maxPage && !this.isLoading)) {
            this.setState({
                page: page + 1
            });

            let ignore = this.props.search(TileComment.modelName, this.filters, count, page + 1);
        }
    }

    render() {
        const meta = this.props.getMeta(TileComment.modelName, this.filters);
        const comments:ITileComment[] = this.props.find(TileComment.modelName, this.filters);

        return (
            <PageSidebar className="page-rating-view__comments">
                <PageSidebarHeader>
                    <Typography variant="h5">
                        <Route exact path={["/rating-view", "/rating-view/filtered"]}>
                            <span>General comments</span>
                        </Route>

                        <Route exact path="/rating-view/parameter">
                            <span>All parameter comments</span>
                        </Route>
                    </Typography>

                    <Grid container
                      alignItems="flex-end"
                      justifyContent="space-between">
                        <Grid item>
                            {
                                [
                                    {
                                        title: "Popularity",
                                        name: "likes"
                                    },
                                    {
                                        title: "Date",
                                        name: "date"
                                    }
                                ].map((field, index:number) => {
                                    let endIcon;

                                    const {
                                        orderBy,
                                        orderWay
                                    } = this.state;

                                    if(orderBy === field.name) {
                                        switch(orderWay) {
                                            case "asc":
                                                endIcon = <ArrowDownwardIcon />;
                                                break;

                                            case "desc":
                                                endIcon = <ArrowUpwardIcon />;
                                                break;
                                        }
                                    }

                                    return (
                                        <Button
                                          key={index}
                                          variant="text"
                                          size="small"
                                          endIcon={endIcon}
                                          onClick={() => this.setOrder(field.name)}>
                                            {field.title}
                                        </Button>
                                    );
                                })
                            }
                        </Grid>

                        <Grid item>
                            {
                                [
                                    {
                                        title: "All",
                                        value: null
                                    },
                                    {
                                        title: "Positive",
                                        value: 2
                                    },
                                    {
                                        title: "Neutral",
                                        value: 1
                                    },
                                    {
                                        title: "Negative",
                                        value: 0
                                    }
                                ].map((item, index:number) => {
                                    const {
                                        value
                                    } = this.state;

                                    return (
                                        <Button
                                          key={index}
                                          variant="text"
                                          size="small"
                                          aria-selected={item.value === value}
                                          onClick={() => this.setValue(item.value)}>
                                            {item.title}
                                        </Button>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </PageSidebarHeader>

                <PageScroll
                  loading={this.isLoading && comments.length === 0}
                  loadingBottom={this.isLoading && comments.length > 0}
                  onScrollEnd={() => this.nextPage()}>
                    <CommentList component={RatingComment}
                      ids={comments.map((comment:ITileComment) => comment.id)} />
                </PageScroll>
            </PageSidebar>
        );
    }
}


const putState = ():RState => {
    return {
        find: store.orm.getters.find,
        getMeta: store.orm.getters.getMeta
    };
};

const putActions = ():RActions => {
    return {
        search: store.orm.search
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions), withRouter
)(RatingComments);