import React from "react";
import {
    Grid,
    GridProps,
    Paper
} from "@material-ui/core";


interface Props extends GridProps {}


class PageSidebar extends React.Component<Props, any> {
    render() {
        const {
            children,
            className,
            ...rest
        } = this.props;

        return (
            <Grid
              container
              className={[
                "page-sidebar",
                className
              ].join(" ")}
              direction="column"
              {...rest}>
                <Paper
                  className="page-sidebar__paper">
                    {children}
                </Paper>
            </Grid>
        );
    }
}


export default PageSidebar;