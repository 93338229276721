import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Grid} from "@material-ui/core";

import {ISuggestComment} from "src/types";

import {
    PageSidebarHeader,
    PageScroll,
    ToolbarComment,
    CommentList
} from "src/views/blocks";

import SuggestCommentItem from "../SuggestCommentItem";

import store, {SuggestComment} from "src/store";


interface Props {
    suggestId:string;
}

interface RState {
    find:(type:string, query?:any) => any[];
    getMeta:(type:string, query?:any) => any;
}

interface RActions {
    search:(type:string, query?:any, count?:number, page?:number) => Promise<any>;
}

interface CProps extends Props, RState, RActions {}


class SuggestComments extends React.Component<CProps, any> {
    get query():any {
        const {
            suggestId
        } = this.props;

        const {
            value,
            orderBy,
            orderWay
        } = this.state;

        return {
            suggestId: [suggestId],
            ...(value !== null ? {value: value} : {}),
            orderBy,
            orderWay
        };
    }

    get loading():boolean {
        const {
            loading = false
        } = this.props.getMeta(SuggestComment.modelName, this.query);

        return loading;
    }

    constructor(props:CProps) {
        super(props);

        this.state = {
            value: null,
            count: 10,
            page: -1,
            orderBy: "date",
            orderWay: "desc"
        };
    }

    async componentDidMount() {
        await this.load();
    }

    async componentDidUpdate(prevProps:Readonly<CProps>) {
        const {
            suggestId
        } = prevProps;

        let hasChanges = false;

        if(this.props.suggestId !== suggestId) {
            hasChanges = true;
        }

        if(hasChanges) {
            await this.load();
        }
    }

    setOrder(orderBy:string, orderWay:string) {
        this.setState({
            orderBy: orderBy,
            orderWay: orderWay
        }, () => {
            this.load();
        });
    }

    setValue(value:null|number) {
        this.setState({
            value: value
        }, () => {
            this.load();
        });
    }

    load() {
        this.setState({
            page: 0
        }, () => {
            this.nextPage();
        });
    }

    async nextPage() {
        const {
            count,
            page
        } = this.state;

        if(!this.loading) {
            const res = await this.props.search(SuggestComment.modelName, this.query, count, page);

            if(res.status === "OK") {
                this.setState({
                    page: page + 1
                });
            }
        }
    }

    render() {
        const {
            value,
            orderBy,
            orderWay
        } = this.state;

        const comments:ISuggestComment[] = this.props.find(SuggestComment.modelName, this.query);
        const meta = this.props.getMeta(SuggestComment.modelName, this.query);

        const {
            total = 0
        } = meta;

        return (
            <Grid container
              style={{flex: 1}}
              direction="column">
                <Grid container
                  style={{
                    flex: 0,
                    height: "35px",
                    flexBasis: "35px",
                    padding: "0 20px"
                  }}
                  direction="column">
                    <PageSidebarHeader
                      style={{
                        height: "35px",
                        padding: 0,
                        flexBasis: "30px"
                      }}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between">
                        <ToolbarComment
                          total={total}
                          value={value}
                          orderBy={orderBy}
                          orderWay={orderWay}
                          onChangeOrder={(by:string, way:string) => this.setOrder(by, way)}
                          onChangeValue={(value:null|number) => this.setValue(value)} />
                    </PageSidebarHeader>
                </Grid>

                <PageScroll
                  loading={this.loading && comments.length === 0}
                  loadingBottom={this.loading && comments.length > 0}
                  onScrollEnd={() => this.nextPage()}>
                    <CommentList component={SuggestCommentItem}
                      ids={comments.map((comment:ISuggestComment) => comment.id)} />
                </PageScroll>
            </Grid>
        );
    }
}

const putState = ():RState => {
    return {
        find: store.orm.getters.find,
        getMeta: store.orm.getters.getMeta
    };
};

const putActions = ():RActions => {
    return {
        search: store.orm.search
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions)
)(SuggestComments);