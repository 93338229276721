import React from "react";
import {
    useLocation,
    matchPath,
    Link
} from "react-router-dom";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";

import {MENU} from "src/routing";

import "./index.scss";


const DashboardSidebar:React.FC = () => {
    const location = useLocation();

    return (
        <div className="dashboard-sidebar">
            <List className="dashboard-sidebar__list">
                {MENU.map((item, index:number) => {
                    const selected = matchPath(location.pathname, {
                        path: item.to
                    });

                    return (
                        <ListItem
                          {...item.to ? {
                              component: Link,
                              to: item.to
                          } : {}}
                          key={index}
                          classes={{
                            root: "dashboard-sidebar__list-item",
                            selected: "dashboard-sidebar__list-item--selected"
                          }}
                          button
                          selected={!!selected}>
                            {item.icon && (
                                <ListItemIcon
                                  className="dashboard-sidebar__icon">
                                    <img
                                      alt={item.title}
                                      src={`/icons/${item.icon}.png`} />
                                </ListItemIcon>
                            )}

                            <ListItemText
                              className="dashboard-sidebar__title"
                              primary={item.title} />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};


export default DashboardSidebar;