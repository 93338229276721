import React from "react";
import {compose} from "redux";
import {
    createStyles,
    withStyles,
    WithStyles,
    StyledComponentProps
} from "@material-ui/core";
import {
    XYPlot,
    LineSeries,
    AreaSeries,
    YAxis,
    HorizontalGridLines
} from "react-vis";

import "react-vis/dist/style.css";

import "./index.scss";


interface Props extends WithStyles {
    period?:any;
    height:number;
    periods:{
        title:string;
        value:string;
    }[];
    data:{
        title:string;
        value:number;
    }[];
    onChangePeriod?:(value:any) => void;
}

interface VisPlotLineProps extends StyledComponentProps {
    data?:{
        title:string;
        value:number;
    }[];
    height?:number;
    period?:any;
    periods?:any[];
    onChangePeriod?:(value:any) => void;
}


class PlotLine extends React.Component<Props, any> {
    static defaultProps = {
        period: "",
        height: 150,
        data: [],
        periods: [
            {title: "All time", value: "all"},
            {title: "12 months", value: 12}
        ]
    };

    wrapperRef:any;
    _resizeHandler = () => this.resize();

    constructor(props:Props) {
        super(props);

        this.state = {
            width: 0
        };

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 500);

        window.addEventListener("resize", this._resizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._resizeHandler);
    }

    setPeriod(period:any) {
        const {
            onChangePeriod
        } = this.props;

        if(onChangePeriod) {
            onChangePeriod(period);
        }
    }

    resize() {
        let width = this.wrapperRef ? this.wrapperRef.current.offsetWidth : 0;

        this.setState({
            width: width
        });
    }

    render() {
        const {
            height,
            data = []
        } = this.props;

        const {width} = this.state;

        let values = data.map((item) => {
            return item.value;
        });

        let valueMax = values.length ? Math.max(10, ...values) : 10;
        let valueMin = values.length ? Math.min(valueMax - 10, ...values) : valueMax - 10;

        let tickMax = values.length ? Math.ceil(valueMax / 10) * 10 : 0;
        let tickMin = values.length ? Math.floor(valueMin / 10) * 10 : 0;
        let tickDistance = tickMax - tickMin;
        let tickCount = tickDistance <= 10
                ? Math.round(tickDistance / 5)
                : (tickDistance % 20 === 0 && tickDistance > 20
                    ? Math.round(tickDistance / 20)
                    : Math.round(tickDistance / 10));

        let tickDelta = Math.round(tickDistance / tickCount);

        let tickValues = Array(Math.max(1, tickCount + 1)).fill(0).map((v, index) => {
            return tickMin + index * tickDelta;
        });

        return (
            <div
              ref={this.wrapperRef}
              className="plot-line">
                <div
                  style={{
                    height: height + "px"
                  }}>
                    <XYPlot
                      margin={{
                        top: 5,
                        right: 35,
                        left: 0,
                        bottom: 5
                      }}
                      width={width}
                      height={height}
                      yDomain={[tickMin, tickMax]}>
                        <HorizontalGridLines
                          tickValues={tickValues} />

                        <AreaSeries
                          opacity={1}
                          stroke="transparent"
                          fill="#F1F9FF"
                          data={data.map((item, index) => {
                            return {
                                x: index,
                                y: item.value
                            };
                          })} />

                        <LineSeries
                          opacity={1}
                          stroke="#75AFE5"
                          strokeStyle="solid"
                          data={data.map((item, index:number) => {
                            return {
                                x: index,
                                y: item.value
                            };
                          })} />

                        <YAxis
                          orientation="right"
                          hideLine
                          tickSize={0}
                          tickValues={tickValues}
                          tickFormat={(value) => value + "%"} />
                    </XYPlot>
                </div>
            </div>
        );
    }
}


const styles = () => createStyles({
    buttonRoot: {
        padding: "0"
    },
    buttonLabel: {
        color: "#6292BF",
        fontSize: "12px",
        padding: "0 6px"
    },
    buttonLabelActive: {
        textDecoration: "underline"
    }
});

export default compose<React.ComponentType<VisPlotLineProps>>(
    withStyles(styles)
)(PlotLine);