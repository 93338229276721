import React, {PropsWithChildren, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Drawer, makeStyles} from "@material-ui/core";

import {useShowMessages} from "src/hooks";
import {STATUS_ERROR, STATUS_SUCCESS} from "src/makes/Model";

import store, {useUIGetters} from "src/store";


type Props = {
    //
};

const DebugPanel:React.FC<Props> = (props:PropsWithChildren<Props>) => {
    const {
        children
    } = props;

    const classes = useStyles(props);
    const showMessages = useShowMessages();
    const {mode} = useSelector((state:any) => state.ui);
    const ui = useUIGetters();

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        const handleClick = (e:any) => {
            if(e.buttons === 4 && ui.isDev) {
                setOpen(true);
            }
        };

        window.addEventListener("mousedown", handleClick);

        return () => window.removeEventListener("mousedown", handleClick);
    }, [mode]);

    useEffect(() => {
        const handleClick = (e:any) => {
            if(e.shiftKey && e.code === "KeyD") {
                if(mode === "production") {
                    store.ui.setMode("development");

                    showMessages({
                        status: STATUS_ERROR,
                        message: "Mode: development"
                    });
                }
                else {
                    store.ui.setMode("production");

                    showMessages({
                        status: STATUS_SUCCESS,
                        message: "Mode: production"
                    });
                }
            }
        };

        window.addEventListener("keydown", handleClick);

        return () => window.removeEventListener("keydown", handleClick);
    }, [mode]);

    return (
        <Drawer
          classes={{paper: classes.drawerPaper}}
          anchor="bottom"
          open={isOpen && ui.isDev}
          onClose={() => setOpen(false)}>
            {children}
        </Drawer>
    );
};


const useStyles = makeStyles(() => {
    return {
        drawerPaper: {
            maxHeight: "60vh"
        }
    };
});

export default DebugPanel;