import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Typography,
    Grid,
    Divider,
    CircularProgress
} from "@material-ui/core";
import {
    ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";

import {IBounds} from "src/types";

import {
    PageSidebar,
    PageSidebarHeader,
    PageSidebarContent,
    PageRow,
    PageScroll,
    PageTabs,
    PageTab,
    Emotion,
    RatingTrend,
    NoVotesEmoji
} from "src/views/blocks";

import store, {
    Tile,
    TileAnalytic
} from "src/store";


interface Props {
    parameterId?:string;
    quadKeys?:string[];
    bounds?:IBounds;
}

interface State {
    period:any;
}

interface RState {
    getMeta:(name:string, query?:any) => any;
    getSummary:(type:string, query?:any) => any;
}

interface RActions {
    search:(type:string, query?:any) => Promise<any>;
}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class RatingViewGeneralRating extends React.Component<CProps, State> {
    setPeriod(period:any) {
        this.setState({
            period: period
        });
    }

    render() {
        const {
            location: {
                search
            },
            parameterId,
            bounds,
            quadKeys
        } = this.props;

        const {
            votes = 0,
            votesLocal = 0,
            votesNonLocal = 0,
            votesPositive = 0,
            votesNeutral = 0,
            votesNegative = 0,
            rate = 0,
            rateLocal = 0,
            rateNonLocal = 0,
            rateSurrounding = 0
        } = this.props.getSummary(Tile.modelName, {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        const {
            loaded: tileLoaded = false
        } = this.props.getMeta(Tile.modelName, {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        const {
            loaded: tileAnalyticLoaded = false
        } = this.props.getMeta(TileAnalytic.modelName, {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        const {
            totals = []
        } = this.props.getSummary(TileAnalytic.modelName, {
            withSummary: true,
            parameterId: parameterId,
            ...(bounds ? {
                bounds: bounds
            } : {
                ...(quadKeys ? {
                    quadKeys : quadKeys
                } : {})
            })
        });

        return (
            <PageSidebar className="page-rating-view__general">
                <PageSidebarHeader>
                    <Typography variant="h5">
                        General rating
                    </Typography>

                    <PageTabs value={0}>
                        <PageTab exact
                          path="/rating-view"
                          to={"/rating-view" + search}
                          label="Total" />

                        <PageTab
                          inDevelopment
                          icon={<ArrowDropDownIcon style={{width: "30px", height: "30px", marginBottom: 0}} />}
                          path="/rating-view/filtered"
                          to={"/rating-view/filtered" + search}
                          label="Filtered" />
                    </PageTabs>
                </PageSidebarHeader>

                <PageScroll>
                    <PageSidebarContent>
                        <PageRow alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        {
                                            votes > 0 ? (
                                                <Emotion
                                                  size="large"
                                                  rate={rate}
                                                  type={votes === 0 ? "neutral" : undefined}
                                                  color={votes === 0 ? "light" : undefined}
                                              />
                                            ) : (
                                                <NoVotesEmoji size="large" />
                                            )
                                        }
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        paddingLeft: "23px"
                                      }}
                                      variant="h1">
                                        {
                                            votes === 0 ? "" : `${rate}%`
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item component={Typography}
                              style={{
                                fontSize: "20px"
                              }}
                              variant="body1">
                                {votes} Votes
                            </Grid>
                        </PageRow>

                        <Divider />

                        <PageRow justifyContent="space-around">
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Emotion
                                          type="happy"
                                          rate={100}
                                          // this rate is only for coloring emoji
                                          size="medium" />
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        fontSize: "20px",
                                        paddingLeft: "15px"
                                      }}
                                      variant="body1">
                                        {votesPositive}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Emotion
                                          type="neutral"
                                          size="medium" />
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        fontSize: "20px",
                                        paddingLeft: "15px"
                                      }}
                                      variant="body1">
                                        {votesNeutral}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Emotion
                                          type="sad"
                                          rate={0}
                                          // this rate is only for coloring emoji
                                          size="medium" />
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        fontSize: "20px",
                                        paddingLeft: "15px"
                                      }}
                                      variant="body1">
                                        {votesNegative}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </PageRow>

                        <Divider />

                        <PageRow>
                            <Grid item xs={6}>
                                <Typography
                                  style={{
                                    fontSize: "17px",
                                    color: "#707070",
                                    paddingBottom: "15px"
                                  }}
                                  variant="body1">
                                    Locals
                                </Typography>

                                <Grid container alignItems="center">
                                    <Grid item>
                                        {
                                            votesLocal > 0 ? (
                                                <Emotion
                                                  size="large"
                                                  {...votesLocal > 0 ? {
                                                    rate: rateLocal
                                                } : {
                                                    type: "neutral",
                                                    color: "light"
                                                }} />
                                            ) : (
                                                <NoVotesEmoji size="large" />
                                            )
                                        }
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        fontSize: 18,
                                        paddingLeft: "15px"
                                      }}>
                                        {votesLocal ? rateLocal + "%" : ""}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography
                                  style={{
                                    fontSize: "17px",
                                    color: "#707070",
                                    paddingBottom: "15px"
                                  }}
                                  variant="body1">
                                    Non-locals
                                </Typography>

                                <Grid container alignItems="center">
                                    <Grid item>
                                        {
                                            votesNonLocal > 0 ? (
                                                <Emotion
                                                  size="large"
                                                  {...votesNonLocal > 0 ? {
                                                    rate: rateNonLocal
                                                } : {
                                                    type: "neutral",
                                                    color: "light"
                                                }}
                                              />
                                            ) : (
                                                <NoVotesEmoji size="large" />
                                            )
                                        }
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        fontSize: 18,
                                        paddingLeft: "15px"
                                      }}
                                      variant="body1">
                                        {votesNonLocal > 0 ? rateNonLocal + "%" : ""}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </PageRow>

                        <Divider />

                        <PageRow alignItems="center" justifyContent="space-between">
                            <Grid item component={Typography} variant="body2">
                                Percentile popularity in surrounding km<sup>2</sup>
                            </Grid>

                            <Grid item component={Typography} variant="h5">
                                {rateSurrounding}
                            </Grid>
                        </PageRow>

                        <Divider />

                        {(!tileLoaded || !tileAnalyticLoaded) && (
                            <Grid container
                              justifyContent="center"
                              alignItems="center"
                              spacing={4}>
                                <Grid item
                                  style={{
                                    marginTop: 10,
                                    marginBottom: 10
                                  }}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        )}

                        {tileLoaded && tileAnalyticLoaded && (
                            <RatingTrend
                              data={totals.map((total:any) => {
                                return {
                                    title: total.date,
                                    value: total.rate
                                };
                              })} />
                        )}
                    </PageSidebarContent>
                </PageScroll>
            </PageSidebar>
        );
    }
}


const putState = ():RState => {
    return {
        getMeta: store.orm.getters.getMeta,
        getSummary: store.orm.getters.getSummary
    };
};

const putActions = ():RActions => {
    return {
        search: store.orm.search
    };
};

export default compose<React.ComponentType<Props>>(
    withRouter,
    connect(putState, putActions)
)(RatingViewGeneralRating);