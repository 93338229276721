import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    PageColumn
} from "../../../../blocks";


interface Props {}

interface RState {}

interface RActions {}

interface CProps extends Props, RState, RActions {}


class RatingViewSuggestions extends React.Component<CProps, any> {
    render() {
        return (
            <PageColumn item
              style={{flex: 1}}
              alignItems="center"
              justifyContent="center">
                <Grid item
                  style={{
                    padding: "0 26%"
                  }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        fontStyle: "italic"
                      }}
                      variant="h5">
                        {"Reserved for future development"}
                    </Typography>
                </Grid>
            </PageColumn>
        );
    }
}


const putState = ():RState => {
    return {};
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(RatingViewSuggestions);