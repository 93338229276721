import React, {useState, useEffect, useCallback, useRef} from "react";
import {makeStyles} from "@material-ui/core";
import {
    useJsApiLoader,
    useGoogleMap,
    useLoadScript,
    GoogleMap,
    HeatmapLayer
} from "@react-google-maps/api";

import {addLatLng} from "src/tools";

import Loader from "../Loader";

import {
    Loader as MapLoader
} from "./blocks";

import {GOOGLE_MAP_KEY} from "src/env";


type TestRectProps = {
    lat:number;
    lng:number;
};

const TestRect:React.FC<TestRectProps> = (props:TestRectProps) => {
    return (
        <React.Fragment>
            123
        </React.Fragment>
    );
};


type Props = {
    points?:any[];
};

const LIBRARIES:any[] = [
    "visualization"
];

const MapGoogle:React.FC<Props> = (props:Props) => {
    const {
        points = []
    } = props;

    const classes = useStyles();
    const {
        isLoaded,
        loadError
    } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries: LIBRARIES
    });

    const [zoom, setZoom] = useState(21);
    const [radius, setRadius] = useState(0);
    const [center, setCenter] = useState({
        lat: 59.938043,
        lng: 30.337157
    });
    const [positions] = useState<any[]>([
        {
            ...center,
            weight: 1
        },
        {
            ...addLatLng(center, 0, 6),
            weight: 2
        },
        {
            ...addLatLng(center, 0, -6),
            weight: 3
        },
        {
            ...addLatLng(center, 6, 0),
            weight: 4
        },
        {
            ...addLatLng(center, -6, 0),
            weight: 5
        },
        {
            ...addLatLng(center, -6, -6),
            weight: 1
        },
        {
            ...addLatLng(center, -6 * 2, -6),
            weight: 2
        },
        {
            ...addLatLng(center, -6 * 3, -6),
            weight: 4
        },
        {
            ...addLatLng(center, -6 * 4, -6),
            weight: 8
        },
        {
            ...addLatLng(center, -6 * 5, -6),
            weight: 16
        }
    ]);
    const refMap = useRef<google.maps.Map|null>(null);

    // useEffect(() => {
    //
    // }, [zoom]);

    const handleLoad = useCallback((map:google.maps.Map) => {
        refMap.current = map;

        handleZoomChanged();
    }, []);

    const handleZoomChanged = useCallback(() => {
        if(refMap.current) {
            const zoom = refMap.current.getZoom();

            if(zoom) {
                const radius = getRadius(zoom);

                console.log("zoom: ", zoom, "radius: ", radius);

                setZoom(zoom);
                setRadius(radius);
            }
        }
    }, []);

    const handleDragEnd = useCallback((map:any) => {
        console.log(map);
    }, []);

    const getRadius = useCallback((zoom) => {
        return Math.pow(2, Math.max(0, zoom - 13));
    }, []);

    return (
        <div
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "stretch"
          }}>
            {!isLoaded && !loadError && (
                <Loader />
            )}

            {isLoaded && !loadError && (
                <GoogleMap
                  mapContainerClassName={classes.map}
                  zoom={zoom}
                  center={center}
                  onLoad={handleLoad}
                  onZoomChanged={handleZoomChanged}>
                    {google && (
                        <HeatmapLayer
                          options={{
                            radius: radius,
                            // maxIntensity: 1,
                            opacity: 0.8,
                            gradient: [
                                "#436846", // #436846 (for tiles with a score of 15-16)
                                // "#4D754C", // #4D754C (for tiles with a score of 14-14.9999)
                                "#5B8255", // #5B8255 (for tiles with a score of 13-13.9999)
                                // "#698E5E",
                                "#799B67",
                                // "#89A770",
                                "#99B479",
                                // "#ABC082",
                                "#bdcc8b",
                                // "#C5D398",
                                "#CDD9A5",
                                // "#D5DFB2",
                                "#DCE5BF",
                                // "#E4EBCD",
                                "#ECF1DA",
                                "#F3F6E8"
                            ].reverse()
                          }}
                          data={positions.map((point:any) => {
                            return new google.maps.LatLng(point.lat, point.lng);
                          })} />
                    )}
                </GoogleMap>
            )}

            {/*<Map
              yesIWantToUseGoogleMapApiInternals
              bootstrapURLKeys={{key: GOOGLE_MAP_KEY}}
              defaultCenter={center}
              defaultZoom={zoom}
              heatmapLibrary={true}
              heatmap={{
                positions,
                options: {
                    opacity: 0.7,
                    radius: 100,
                    // @ts-ignore
                    gradient: [
                        "#436846", // #436846 (for tiles with a score of 15-16)
                        "#4D754C", // #4D754C (for tiles with a score of 14-14.9999)
                        "#5B8255", // #5B8255 (for tiles with a score of 13-13.9999)
                        "#698E5E",
                        "#799B67",
                        "#89A770",
                        "#99B479",
                        "#ABC082",
                        "#bdcc8b",
                        "#C5D398",
                        "#CDD9A5",
                        "#D5DFB2",
                        "#DCE5BF",
                        "#E4EBCD",
                        "#ECF1DA",
                        "#F3F6E8"
                    ].reverse()
                }
              }}
              onZoomAnimationEnd={handleZoomAnimationEnd}
              onDragEnd={handleDragEnd}>
                <TestRect lat={center.lat} lng={center.lng} />
            </Map>*/}
        </div>
    );
};


const useStyles = makeStyles(() => {
    return {
        root: {

        },
        map: {
            height: "100%"
        }
    };
});

export default MapGoogle;