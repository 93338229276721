import React from "react";
import {compose} from "redux";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Grid,
    GridProps
} from "@material-ui/core";


interface Props extends GridProps {}

interface CProps extends Props, WithStyles {
    classes:any;
}


class PageNavbar extends React.Component<CProps, any> {
    render() {
        const {
            children,
            className,
            classes,
            ...rest
        } = this.props;

        return (
            <Grid container
              className={[
                "page-navbar",
                "page__navbar",
                classes.root,
                className
              ].join(" ")}
              alignItems="flex-end"
              {...rest}>
                {children}
            </Grid>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        [theme.breakpoints.down("md")]: {
            paddingTop: 40
        }
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(PageNavbar);