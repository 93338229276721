import React, {useState, useEffect} from "react";
import {shallowEqual} from "react-redux";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    useSelector,
    useORM
} from "src/hooks";

import {
    PageColumn,
    PageScroll
} from "src/views/blocks";
import CategoryList from "../CategoryList";
import CategoryItem from "../CategoryItem";

import store, {
    DemandCategory, IDemandCategory
} from "src/store";


type Props = {
    selectedCategoryId?:IDemandCategory["id"];
};

const Sidebar:React.FC<Props> = (props:Props) => {
    const {
        selectedCategoryId
    } = props;

    const {
        mapBounds,
        mode
    } = useSelector((state) => state.ui, shallowEqual);
    const orm = useORM();

    const [categoryIds, setCategoryIds] = useState<string[]>([]);

    const categories:IDemandCategory[] = orm.filter(DemandCategory.modelName, {
        parentId: null
    });

    useEffect(() => {
        store.orm.search(DemandCategory.modelName);
    }, []);

    useEffect(() => {
        const load = async () => {
            const res = await store.api.get("/api/dashboard/demand-category/visible", {
                headers: store.auth.getters.authHeaders,
                data: {
                    bounds: mapBounds
                }
            });

            if(res && res.data && Array.isArray(res.data)) {
                setCategoryIds(res.data);
            }
        };

        if(mapBounds) {
            load();
        }
    }, [mapBounds]);

    return (
        <PageColumn style={{flex: 1}}>
            {categories.length === 0 && (
                <Grid container
                  style={{height: "100%"}}
                  direction="column"
                  justifyContent="center"
                  alignItems="center">
                    <Typography
                      style={{
                        textAlign: "center",
                        fontWeight: "normal",
                        fontStyle: "italic"
                      }}
                      variant="h5">
                        Zoom in to see the results
                    </Typography>
                </Grid>
            )}

            {categories.length > 0 && (
                <PageScroll
                  style={{
                    padding: "0px 1px 0 1px"
                  }}
                  // classes={{content: classes.list}}
                  loading={categories.length === 0}>
                    {categories.map((category:IDemandCategory) => {
                        const childs:IDemandCategory[] = orm.filter(DemandCategory.modelName, {
                            parentId: category.id
                        });

                        const hasChildren = childs.reduce((res:boolean, child:IDemandCategory) => {
                            return res || categoryIds.includes(child.id);
                        }, false);

                        return (
                            <CategoryList
                              key={category.id}
                              disabled={!hasChildren}
                              title={category.title}>
                                {childs.map((child:IDemandCategory) => {
                                    return (
                                        <CategoryItem key={child.id}
                                          selected={child.id === selectedCategoryId}
                                          disabled={!categoryIds.includes(child.id)}
                                          id={child.id}
                                          title={child.title} />
                                    );
                                })}
                            </CategoryList>
                        );
                    })}
                </PageScroll>
            )}

            {mode === "development" && (
                <pre>{JSON.stringify({
                    RENDER_COUNT: RENDER_COUNT++,
                    mapBounds,
                    categoryIds
                }, null, 4)}</pre>
            )}
        </PageColumn>
    );
};

let RENDER_COUNT = 0;


export default Sidebar;