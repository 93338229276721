import React, {useCallback} from "react";
import {
    makeStyles,
    Grid,
    Paper,
    Grow,
    CircularProgress
} from "@material-ui/core";

import ParameterListItem from "../ParameterListItem";

import {
    useORM,
    ITileParameter,
    TileParameter
} from "src/store";


type Props = {
    open?:boolean;
    parameterId?:string;
    onClose:() => void;
};

const ParameterList:React.FC<Props> = (props:Props) => {
    const {
        parameterId,
        open = false,
        onClose
    } = props;

    const classes = useStyles(props);

    const {
        loaded = true
    } = useORM((orm) => {
        return orm.getMeta(TileParameter.modelName);
    });


    const parameters:ITileParameter[] = useORM((orm) => {
        return orm.find(TileParameter.modelName);
    });

    const handleClose = useCallback(() => {
        if(onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <Grow in={open}>
            <Paper>
                {!loaded && (
                    <Grid container justifyContent="center" alignContent="center">
                        <Grid item className="p-5">
                            <CircularProgress size={20} />
                        </Grid>
                    </Grid>
                )}

                {loaded && (
                    <Grid container className={classes.list}>
                        {parameters.map((parameter:ITileParameter) => {
                            return (
                                <Grid key={parameter.id} item
                                  className={classes.item}>
                                    <ParameterListItem
                                      id={parameter.id}
                                      active={parameter.id === parameterId}
                                      onClick={handleClose} />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Paper>
        </Grow>
    );
};


const useStyles = makeStyles(() => {
    return {
        list: {
            padding: "30px 10px 38px 27px"
        },
        item: {
            width: "20%"
        }
    };
});

export default ParameterList;