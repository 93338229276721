import {IModule} from "kpdux";


const storage:IModule<any> = {
    getters: {
        getItem() {
            return (key:string, defaultValue?:any):any|null => {
                let value = localStorage.getItem(key);

                if(value) {
                    try {
                        return JSON.parse(value);
                    }
                    catch(ignore) {
                        return value;
                    }
                }

                if(defaultValue !== "undefined") {
                    return defaultValue;
                }

                return null;
            };
        }
    },
    actions: {
        setItem(key:string, value:any) {
            let prepare = value;

            if(typeof value === "object") {
                if(value !== null) {
                    prepare = JSON.stringify(value);
                }
                else {
                    prepare = null;
                }
            }

            localStorage.setItem(key, prepare);
        },
        removeItem(key:string) {
            localStorage.removeItem(key);
        }
    }
};


export {storage};