import {
    Model,
    STATUS_SUCCESS,
    IResponseCreateOne,
    IResponseUpdateOne
} from "src/makes/Model";


interface IArea {
    id:string;
    type:string;
    quadKey:string;
    loaded:boolean;
}

class Area extends Model<IArea> {
    static modelName:string = "area";
    static filters = {
        type: (area:IArea, type:string) => {
            return area.type === type;
        },
        quadKey: (area:IArea, quadKey:string) => {
            return area.quadKey === quadKey;
        }
    };

    async createOne(data:any):IResponseCreateOne<IArea> {
        const {
            quadKey,
            type
        } = data;

        return {
            status: STATUS_SUCCESS,
            item: {
                id: `${quadKey}-${type}`,
                ...data
            }
        };
    }

    async updateOne(id:any, data:any):IResponseUpdateOne<IArea> {
        const item = this.getters.byId(Area.modelName, id);

        return {
            status: STATUS_SUCCESS,
            item: {
                ...item,
                ...data
            }
        };
    }
}


export type {IArea};

export {Area};