import React from "react";
import {Redirect} from "react-router-dom";

import {ROUTES} from "src/routing";
import {useAuth} from "src/hooks";


const Home:React.FC = () => {
    const {isLoggedIn} = useAuth();

    return (
        <React.Fragment>
            {!isLoggedIn && (
                <Redirect to={ROUTES.login} />
            )}

            {isLoggedIn && (
                <Redirect to={ROUTES.rating} />
            )}
        </React.Fragment>
    );
};


export default Home;