import {
    Theme,
    Switch,
    createStyles,
    withStyles
} from "@material-ui/core";


const SWITCH_WIDTH = 48;
const THUMB_SIZE = 24;
const SPACE = 2;

const styles = (theme:Theme) => createStyles({
    root: {
        width: SWITCH_WIDTH,
        height: 28,
        padding: 0,
        margin: theme.spacing(1),
        overflow: "visible"
    },
    switchBase: {
        padding: SPACE,
        "& + $track": {
            backgroundColor: "#75afe5",
            opacity: 1,
            border: "none"
        },
        "&$checked": {
            transform: `translateX(${SWITCH_WIDTH - THUMB_SIZE - SPACE * 2}px)`,
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#75afe5",
                opacity: 1,
                border: "none"
            }
        }
        // "&$focusVisible $thumb": {
        //     color: "#75afe5",
        //     border: "6px solid #fff"
        // }
    },
    thumb: {
        width: THUMB_SIZE,
        height: THUMB_SIZE
    },
    edgeEnd: {
        marginRight: -1
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {}
});


export default withStyles(styles)(Switch);