import React from "react";
import {
    Grid,
    GridProps,
    GridSpacing
} from "@material-ui/core";

import theme from "../../../../theme";


interface Props extends GridProps {
    deep?:GridSpacing;
}


class PageWrapper extends React.Component<Props, any> {
    render() {
        const {
            children,
            className,
            deep = 0,
            ...rest
        } = this.props;

        return (
            <Grid container
              style={{
                padding: theme.spacing(deep) / 2
              }}
              className={[
                "page-wrapper",
                className
              ].join(" ")}
              {...rest}>
                <Grid container item
                  className={[
                    "page-wrapper__inner"
                  ].join(" ")}
                  spacing={deep}>
                    {children}
                </Grid>
            </Grid>
        );
    }
}


export default PageWrapper;