import React from "react";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    // IParameterTotal
} from "../../../../../../../types";

import "./index.scss";


interface Props {
    items:any[];
    onClickItem?:(item:any) => void;
}

class ParameterTable extends React.Component<Props, any> {
    static defaultProps = {
        items: []
    };

    constructor(props:Props) {
        super(props);
    }

    onClickItem(item:any) {
        const {
            onClickItem
        } = this.props;

        if(onClickItem) {
            onClickItem(item);
        }
    }

    render() {
        const {
            items
        } = this.props;

        return (
            <Grid container
              className="parameter-table"
              direction="column">
                <Grid container
                  className="parameter-table__headers"
                  justifyContent="space-between">
                    <Grid item
                      className="parameter-table__header">
                        <Typography variant="h6">
                            Votes
                        </Typography>
                    </Grid>

                    <Grid item
                      className="parameter-table__header">
                        <Typography variant="h6" align="right">
                            Score
                        </Typography>
                    </Grid>
                </Grid>

                {
                    items.sort((a, b) => a.rate > b.rate ? -1 : 1).map((total:any, index:number) => {
                        return (
                            <Grid key={index} container
                              className="parameter-table__row"
                              justifyContent="space-between"
                              alignContent="center"
                              onClick={() => this.onClickItem(total)}>
                                <Grid item
                                  className="parameter-table__cell">
                                    <Typography variant="body1">
                                        {total.votes}
                                    </Typography>
                                </Grid>

                                <Grid item
                                  className={[
                                    "parameter-table__cell",
                                    "parameter-table__cell-negative"
                                  ].join(" ")}>
                                    <Grid container
                                      style={{height: "100%"}}
                                      justifyContent="flex-end"
                                      alignItems="center">
                                        {this.renderNegative(total)}
                                    </Grid>
                                </Grid>

                                <Grid item
                                  className={[
                                    "parameter-table__cell",
                                    "parameter-table__cell-positive"
                                  ].join(" ")}>
                                    <Grid container
                                      style={{height: "100%"}}
                                      justifyContent="flex-start"
                                      alignItems="center">
                                        {this.renderPositive(total)}
                                    </Grid>
                                </Grid>

                                <Grid item
                                  className="parameter-table__cell">
                                    <Typography variant="body1" align="right">
                                        {total.rate}%
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        );
    }

    renderPositive(total:any) {
        return total.rate > 50 ? (
            <div
              style={{width: (total.rate - 50) * 2 + "%"}}
              className={[
                "parameter-table__bar",
                "parameter-table__bar-positive"
              ].join(" ")}/>
        ) : (
            <Typography
              className="parameter-table__title"
              variant="body1">
                {total.title}
            </Typography>
        );
    }

    renderNegative(total:any) {
        return total.rate > 50 ? (
            <Typography
              className="parameter-table__title"
              variant="body1">
                {total.title}
            </Typography>
        ) : (
            <div
              style={{width: (50 - total.rate) * 2 + "%"}}
              className={[
                "parameter-table__bar",
                "parameter-table__bar-negative"
              ].join(" ")} />
        );
    }
}


export default ParameterTable;