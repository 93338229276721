import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    Switch
} from "../../../../controls";

import store from "../../../../../store";


interface Props {}

interface RState {
    mapMethod:string;
}

interface RActions {
    setMapMethod:(method:"single"|"multiple") => void;
}

interface CProps extends Props, RState, RActions {}


class RatingViewTool extends React.Component<CProps, any> {
    onMethodChange(e:any) {
        const {
            setMapMethod
        } = this.props;

        if(e.target.checked) {
            setMapMethod("multiple");
        }
        else {
            setMapMethod("single");
        }
    }

    render() {
        const {
            mapMethod
        } = this.props;

        return (
            <Grid container alignItems="center">
                <Grid item>
                    <Typography
                      variant="body2"
                      display="inline"
                      gutterBottom>
                        Pan / select tile
                    </Typography>
                </Grid>

                <Grid item>
                    <Switch
                      checked={mapMethod === "multiple"}
                      onChange={(e) => this.onMethodChange(e)} />
                </Grid>

                <Grid item>
                    <Typography
                      variant="body2"
                      display="inline"
                      gutterBottom>
                        Select area
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}


const putState = (state:any):RState => {
    return {
        mapMethod: state.ui.mapMethod
    };
};

const putActions = ():RActions => {
    return {
        setMapMethod: store.ui.setMapMethod
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions)
)(RatingViewTool);