import React from "react";
import {
    Grid,
    Typography,
    Button
} from "@material-ui/core";

import PlotLine from "../PlotLine";


interface Props {
    periods?:{
        title:string;
        value:string;
    }[];
    data?:{
        title:string;
        value:number;
    }[];
}

interface State {
    period:number;
}


class RatingTrend extends React.Component<Props, State> {
    static defaultProps = {
        periods: [
            {title: "All time", value: -1},
            {title: "12 months", value: 12}
        ],
        data: []
    };

    constructor(props:Props) {
        super(props);

        this.state = {
            period: 12
        };
    }

    setPeriod(period:number) {
        this.setState({
            period: period
        });
    }

    render() {
        const {
            periods = [],
            data = []
        } = this.props;

        const {
            period
        } = this.state;

        const items = period > 0 ? data.slice(-period) : data;

        const values = items.map((item) => {
            return item.value;
        });

        const valueMax = values.length ? Math.max(...values) : 0;
        const valueMin = values.length ? Math.min(...values) : 0;

        return (
            <Grid container
              style={{
                paddingTop: "10px",
                paddingBottom: "10px"
              }}
              direction="column">
                <Grid container alignItems="flex-end">
                    <Grid item
                      style={{
                        paddingRight: "10px"
                      }}>
                        <Typography variant="h6">
                            Trend
                        </Typography>
                    </Grid>

                    <Grid item>
                        {
                            periods.map((periodData:any, index:number) => {
                                const active = periodData.value === period;

                                return (
                                    <Button
                                      key={index}
                                      variant="text"
                                      size="small"
                                      aria-selected={active}
                                      onClick={() => this.setPeriod(periodData.value)}>
                                        {periodData.title}
                                    </Button>
                                );
                            })
                        }
                    </Grid>
                </Grid>

                <Grid
                  container
                  style={{padding: "10px 0 10px"}}>
                    <Grid item xs={12}>
                        <PlotLine
                          data={items} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className="plot-line__rate">
                        <Typography
                          style={{textTransform: "uppercase"}}
                          className="text-normal"
                          variant="caption">
                            Low
                        </Typography>

                        <Typography
                          style={{lineHeight: 1}}
                          className="plot-line__rate-value">
                            {valueMin}%
                        </Typography>
                    </Grid>

                    <Grid item className="plot-line__rate">
                        <Typography
                          style={{textTransform: "uppercase"}}
                          className="text-normal"
                          variant="caption">
                            High
                        </Typography>

                        <Typography
                          style={{lineHeight: 1}}
                          className="plot-line__rate-value">
                            {valueMax}%
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default RatingTrend;