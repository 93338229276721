import React, {
    UIEvent
} from "react";
import {compose} from "redux";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Grid,
    GridProps,
    CircularProgress
} from "@material-ui/core";


interface Props extends GridProps {
    onScrollEnd?:() => void;
    loading?:boolean;
    loadingBottom?:boolean;
    bottom?:React.ReactNode;
    classes?:{
        root?:string;
        content?:string;
    };
}

interface CProps extends Props, WithStyles {
    className?:string;
    classes:any;
}


class PageScroll extends React.Component<CProps, any> {
    onScroll(e:UIEvent<HTMLDivElement>) {
        const {
            onScrollEnd
        } = this.props;

        if(onScrollEnd) {
            const {
                scrollTop,
                scrollHeight,
                clientHeight
            } = e.target as any;

            if(scrollTop + clientHeight > scrollHeight - 100) {
                onScrollEnd();
            }
        }
    }

    render() {
        const {
            children,
            className,
            classes,
            loading,
            loadingBottom,
            bottom,
            onScrollEnd,
            ...rest
        } = this.props;

        return (
            <Grid
              className={[
                "page-scroll",
                className,
                classes.root
              ].join(" ")}
              {...rest}
              onScroll={(e) => this.onScroll(e)}>
                <div
                  className={[
                    "page-scroll__content",
                    classes.content
                  ].join(" ")}>
                    {
                        loading ? (
                            <Grid container
                              style={{
                                height: "100%"
                              }}
                              alignItems="center"
                              justifyContent="center">
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        ) : children}
                </div>

                <div className="page-scroll__bottom">
                    {
                        loadingBottom ? (
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        ) : null
                    }

                    {bottom}
                </div>
            </Grid>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        position: "relative",
        [theme.breakpoints.up("lg")]: {
            flex: 1
        }
    },
    content: {
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: "auto"
        }
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(PageScroll);