import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";

import {ISuggestComment} from "src/types";

import {
    Comment,
    Emotion
} from "src/views/blocks";

import store, {SuggestComment} from "src/store";


interface Props {
    id:string;
}

interface RState {
    record:ISuggestComment;
}

interface CProps extends Props, RState {}


class SuggestCommentItem extends React.Component<CProps, any> {
    render() {
        const {
            record
        } = this.props;

        const {
            user = null,
            value = 1,
            likes = 0,
            date = "",
            content = "",
            images = null
        } = record || {};

        let emotion:any = "neutral";

        switch(value) {
            case 2:
                emotion = "happy";
                break;

            case 1:
                emotion = "neutral";
                break;

            case 0:
                emotion = "sad";
                break;
        }

        return (
            <Comment
              user={user}
              date={date}
              content={content}
              likes={likes}
              images={images}>
                <Emotion
                  type={emotion}
                  size="small"
                  color="secondary" />
            </Comment>
        );
    }
}


const putState = (state:any, props:Props):RState => {
    return {
        record: store.orm.getters.byId(SuggestComment.modelName, props.id)
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(SuggestCommentItem);