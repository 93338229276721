import React from "react";
import {Link} from "react-router-dom";
import {
    Grid,
    Typography,
    Button
} from "@material-ui/core";


const Error404:React.FC = () => {
    return (
        <Grid container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}>
            <Grid item>
                <Typography variant="h4" align="center">
                    404
                </Typography>

                <Typography variant="h5" align="center">
                    Page not found
                </Typography>
            </Grid>

            <Grid item>
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  to="/">
                    Back to home
                </Button>
            </Grid>
        </Grid>
    );
};


export default Error404;