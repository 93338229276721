import React from "react";
import {
    makeStyles,
    Theme,
    CircularProgress
} from "@material-ui/core";


const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
};


const useStyles = makeStyles((theme:Theme) => {
    return {
        root: {
            display: "flex",
            justifyContent: "center",
            paddingTop: 40,
            "& > * + *": {
                marginLeft: theme.spacing(2)
            }
        }
    };
});

export default Loader;