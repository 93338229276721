import React from "react";
import {compose} from "redux";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Hidden,
    Grid
} from "@material-ui/core";

import {
    DashboardHeader,
    DashboardSidebar,
    DashboardDrawer
} from "./blocks";

import "./index.scss";


interface Props {}

interface CProps extends Props, WithStyles {}

class DashboardLayout extends React.Component<CProps, any> {
    render() {
        const {
            classes,
            children
        } = this.props;

        return (
            <Grid container
              className={[
                "dashboard-layout",
                classes.root
              ].join(" ")}
              alignContent="stretch"
              direction="column">
                <Grid item
                  className={[
                    "dashboard-layout__header",
                    classes.header
                  ].join(" ")}>
                    <DashboardHeader />
                </Grid>

                <Grid container item
                  className={[
                    "dashboard-layout__wrapper",
                    classes.wrapper
                  ].join(" ")}
                  alignContent="stretch"
                  wrap="nowrap">
                    <Hidden only={["xs", "sm", "md", "lg"]}>
                        <Grid item
                          className={[
                            "dashboard-layout__sidebar",
                            classes.sidebar
                          ].join(" ")}>
                            <DashboardSidebar />
                        </Grid>
                    </Hidden>

                    <Grid item
                      className={[
                        "dashboard-layout__content",
                        classes.content
                      ].join(" ")}>
                        {children}
                    </Grid>
                </Grid>

                <DashboardDrawer />
            </Grid>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        [theme.breakpoints.up("lg")]: {
            height: "100vh"
        }
    },
    header: {
        [theme.breakpoints.up("lg")]: {
            flexBasis: "81px",
            height: "81px"
        }
    },
    wrapper: {
        [theme.breakpoints.up("lg")]: {
            flexGrow: 1,
            height: "calc(100vh - 81px)"
        }
    },
    sidebar: {
        width: "250px",
        flexBasis: "250px",
        paddingTop: "23px"
    },
    content: {
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 17,
            flexBasis: "calc(100vw - 250px)",
            width: "calc(100vw - 250px)"
        }
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(DashboardLayout);