import React from "react";
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from "@material-ui/core";


type Props = {
    title?:string;
    headers?:any[];
    data?:any[];
};

const DebugTable:React.FC<Props> = (props:Props) => {
    const {
        title,
        headers = [],
        data = []
    } = props;

    return (
        <TableContainer>
            <Typography>
                {title}
            </Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header:any, index:number) => {
                            const {
                                name
                            } = header;

                            return (
                                <TableCell key={index}>
                                    {name}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((item:any, index:number) => {
                        return (
                            <TableRow key={index}>
                                {headers.map((header:any, index:number) => {
                                    const {name} = header;

                                    return (
                                        <TableCell key={index}>
                                            {item[name]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default DebugTable;