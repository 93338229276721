import React from "react";
import {
    XYPlot,
    VerticalBarSeries,
    XAxis
} from "react-vis";

import "react-vis/dist/style.css";

import "./index.scss";


interface Props {
    height:number;
    data:{
        title:string;
        value:number;
    }[];
}

interface PlotBarProps {
    height?:number;
    data?:{
        title:string;
        value:number;
    }[];
}

class PlotBar extends React.Component<Props, any> {
    static defaultProps = {
        height: 105,
        data: []
    };

    wrapperRef:any;
    _resizeHandler = () => this.resize();

    constructor(props:Props) {
        super(props);

        this.state = {
            width: 0
        };

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 500);

        window.addEventListener("resize", this._resizeHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._resizeHandler);
    }

    resize() {
        let width = this.wrapperRef && this.wrapperRef.current
                    ? this.wrapperRef.current.offsetWidth
                    : 0;

        this.setState({
            width: width
        });
    }

    render() {
        const {
            height,
            data
        } = this.props;

        const {
            width
        } = this.state;

        return (
            <div
              ref={this.wrapperRef}
              className="plot-bar">
                <div
                  style={{height: height + "px"}}>
                    <XYPlot
                      margin={{
                        top: 20,
                        left: 0,
                        right: 0,
                        bottom: 20
                      }}
                      width={width}
                      height={height}>
                        <XAxis
                          orientation="top"
                          hideLine
                          tickSize={0}
                          tickFormat={(index) => {
                              if(data[index]) {
                                  return data[index].value + "";
                              }

                              return "";
                          }} />

                        <XAxis
                          orientation="bottom"
                          hideLine
                          tickSize={0}
                          tickFormat={(index) => {
                              if(data[index]) {
                                  return data[index].title;
                              }

                              return "";
                          }} />

                        <VerticalBarSeries
                          style={{}}
                          barWidth={0.8}
                          stroke="#A4B5C5"
                          fill="#A4B5C5"
                          data={data.map((item, index) => {
                            return {
                                x: index,
                                y: item.value
                            };
                          })} />
                    </XYPlot>
                </div>
            </div>
        );
    }
}


export default PlotBar as React.ComponentType<PlotBarProps>;