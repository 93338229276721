import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Box,
    Grid,
    Typography
} from "@material-ui/core";

import {
    ISuggest
} from "../../../types";

import {parseQuadKeys} from "../../../tools";
import QueryString from "../../../makes/QueryString";

import {
    Page,
    PageHeader,
    PageNavbar,
    PageWrapper,
    PageContent,
    PageColumn,
    MapEventClick,
    DebugPanel
} from "../../blocks";

import {
    SuggestCard,
    SuggestMap
} from "./blocks";

import {
    SuggestList
} from "./sidebars";

import store from "../../../store";

import "./index.scss";


interface Props {}

interface State {
    orderBy:string;
    orderWay:string;
    count:number;
    page:number;
    ids:string[];
    mapZoomHeight:number;
}

interface RState {
    suggestId?:string;
    quadKeys:string[];
    byId:(type:string, id:any) => any;
    filterOne:(type:string, query?:any) => any;
}

interface RActions {
    search:(type:string, query?:any) => Promise<any>;
}

interface UrlParams {
    suggestId?:string;
}

interface CProps extends Props, RState, RActions, WithStyles, RouteComponentProps<UrlParams> {}


class SuggestPage extends React.Component<CProps, State> {
    constructor(props:CProps) {
        super(props);

        this.state = {
            orderBy: "rate",
            orderWay: "desc",
            page: -1,
            count: 20,
            ids: [],
            mapZoomHeight: Infinity
        };
    }

    async componentDidMount() {
        await this.props.search("city-center", {
            type: "suggestions"
        });

        await this.props.search("suggest-category");
    }

    onClickMap(e:MapEventClick) {
        const {
            history
        } = this.props;

        const {
            tile
        } = e;

        let suggest:ISuggest|null = null;

        if(tile) {
            suggest = this.props.filterOne("suggest", {
                quadKey: tile.getQuadKey()
            });
        }

        let search = QueryString.stringify({
            quadKeys: [tile.getQuadKey()]
        });

        if(suggest) {
            history.replace("/suggestion/map/" + suggest.id + "?" + search);
        }
        else {
            history.replace("/suggestion?" + search);
        }
    }

    render() {
        const {
            classes,
            suggestId,
            quadKeys
        } = this.props;

        let suggest:any = suggestId ? this.props.byId("suggest", suggestId) : null;

        return (
            <Page className="page-suggest">
                <PageHeader deep={3}>
                    <PageNavbar item spacing={3}>
                        <Grid item>
                            <Typography variant="h3">
                                Suggestions
                            </Typography>
                        </Grid>
                    </PageNavbar>
                </PageHeader>

                <PageWrapper deep={3}>
                    <PageContent item className={classes.sidebar}>
                        <SuggestList />
                    </PageContent>

                    <PageContent item className={classes.content}>
                        <PageColumn className="page-suggest__column-map">
                            {suggest && quadKeys.length && (
                                <SuggestCard
                                  quadKeys={quadKeys}
                                  suggestId={suggest.id} />
                            )}

                            <SuggestMap
                              suggestId={suggest ? suggest.id : null}
                              quadKeys={quadKeys}
                              onClick={(e:MapEventClick) => this.onClickMap(e)} />
                        </PageColumn>
                    </PageContent>
                </PageWrapper>

                <DebugPanel>
                    <Box padding={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <pre>{JSON.stringify({suggestId}, null, 4)}</pre>
                                <pre>{JSON.stringify(suggest, null, 4)}</pre>
                                <pre>{JSON.stringify(quadKeys, null, 4)}</pre>
                            </Grid>
                        </Grid>
                    </Box>
                </DebugPanel>
            </Page>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    sidebar: {
        flexGrow: 0,
        flexBasis: "33.333333%",
        maxWidth: "33.333333%",
        [theme.breakpoints.only("xl")]: {
            flexBasis: "544px",
            width: "auto",
            maxWidth: "544px"
        },
        [theme.breakpoints.down("md")]: {
            order: 2,
            flexBasis: "auto",
            maxWidth: "none"
        }
    },
    content: {
        width: "auto",
        flexGrow: 1,
        [theme.breakpoints.down("md")]: {
            order: 1,
            width: "100%",
            height: "500px"
        }
    },
    list: {
        [theme.breakpoints.up("lg")]: {
            paddingRight: 10
        }
    }
});

const putState = (state:any, props:Props & RouteComponentProps<UrlParams>):RState => {
    const {
        location: {
            search
        },
        match: {
            params: {
                suggestId
            }
        }
    } = props;

    return {
        quadKeys: parseQuadKeys(search),
        suggestId: suggestId,
        byId: store.orm.getters.byId,
        filterOne: store.orm.getters.filterOne
    };
};

const putActions = ():RActions => {
    return {
        search: store.orm.search
    };
};

export default compose<React.ComponentType<Props>>(
    withStyles(styles),
    connect(putState, putActions)
)(SuggestPage);