import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
    Grid,
    Typography
} from "@material-ui/core";

import {
    ISuggest,
    ISuggestCategory
} from "../../../../../types";

import QueryString from "../../../../../makes/QueryString";

import store from "../../../../../store";


interface Props {
    id:string;
}

interface RState {
    record:ISuggest;
    category:ISuggestCategory;
}

interface CProps extends Props, RState {}


class SuggestTableItem extends React.Component<CProps, any> {
    render() {
        const {
            record: suggest,
            category
        } = this.props;

        const {
            id = null,
            quadKeys = [],
            address = "",
            title = "",
            rate = 0
        } = suggest || {};

        return (
            <Grid container
              style={{
                padding: "15px 0"
              }}
              className="suggest-table-item"
              justifyContent="space-between"
              alignItems="center">
                <Grid item>
                    <Typography
                      style={{
                        paddingBottom: "5px"
                      }}
                      variant="caption"
                      display="block">
                        {category ? category.title : ""}
                    </Typography>

                    <Typography component={Link}
                      style={{
                        paddingBottom: "4px"
                      }}
                      variant="body1"
                      to={"/suggestion/view/" + id + "?" + QueryString.stringify({quadKeys})}>
                        {title}
                    </Typography>

                    {address && (
                        <Typography variant="body1">
                            {address}
                        </Typography>
                    )}
                </Grid>

                <Grid item>
                    <Grid container>
                        <Grid item>
                            <Typography
                              style={{
                                fontSize: "30px",
                                color: "#A4B5C5"
                              }}>
                                {rate}%
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


const putState = (state:any, props:Props):RState => {
    let suggest:ISuggest = store.orm.getters.byId("suggest", props.id);

    return {
        record: suggest,
        category: suggest && suggest.categoryId ? store.orm.getters.byId("suggest-category", suggest.categoryId) : null
    };
};

export default compose<React.ComponentType<Props>>(
    connect(putState)
)(SuggestTableItem);