import QuadTile from "./QuadTile";
import {measure} from "src/tools";


interface IPoint {
    lat:number; // x
    lng:number; // y
}

interface IBounds {
    north:number; // x
    east:number; // y
    south:number; // x1
    west:number; // y1

    // lat: -0.36529541015625
    // lng: 39.4683837890625

    // north: 40.55240784139518
    // east: -1.1002186685800555
    // south: 39.46693472341566
    // west: -5.126707926392556
    // south < north
    // west < east
}

class Bounds implements IBounds {
    east:number;
    west:number;
    south:number;
    north:number;

    constructor(bounds:IBounds) {
        this.east = bounds.east;
        this.west = bounds.west;
        this.south = bounds.south;
        this.north = bounds.north;
    }

    getCenter():IPoint {
        return {
            lat: (this.north + this.south) / 2,
            lng: (this.east + this.west) / 2
        };
    }

    getWidth() {
        return measure(
            this.getTopLeft(),
            this.getTopRight()
        );
    }

    getWidth2() {
        return measure(
            this.getBottomLeft(),
            this.getBottomRight()
        );
    }

    getHeight() {
        return measure(
            this.getTopLeft(),
            this.getBottomLeft()
        );
    }

    getTopLeft():IPoint {
        return this.getNorthEast();
    }

    getTopRight():IPoint {
        return this.getNorthWest();
    }

    getBottomLeft():IPoint {
        return this.getSouthEast();
    }

    getBottomRight():IPoint {
        return this.getSouthWest();
    }

    getNorthEast():IPoint {
        return {
            lat: this.north,
            lng: this.east
        };
    }

    getNorthWest():IPoint {
        return {
            lat: this.north,
            lng: this.west
        };
    }

    getSouthEast():IPoint {
        return {
            lat: this.south,
            lng: this.east
        };
    }

    getSouthWest():IPoint {
        return {
            lat: this.south,
            lng: this.west
        };
    }

    includes(bounds:IBounds):boolean {
        return (
            this.north >= bounds.north &&
            this.south <= bounds.south &&
            this.east >= bounds.east &&
            this.west <= bounds.west
        );
    }

    hasPoint(point:IPoint) {
        return (
            this.west <= point.lng && point.lng <= this.east &&
            this.south <= point.lat && point.lat <= this.north
        );
    }

    static fromBounds(...bounds:IBounds[]):Bounds|null {
        let area:IBounds|null = bounds.reduce((area:IBounds|null, bounds:IBounds) => {
            return {
                north: Math.max(area ? area.north : bounds.north, bounds.north),
                west: Math.min(area ? area.west : bounds.west, bounds.west),
                south: Math.min(area ? area.south : bounds.south, bounds.south),
                east: Math.max(area ? area.east : bounds.east, bounds.east)
            };
        }, null);

        if(area) {
            return new Bounds(area);
        }

        return null;
    }

    static fromPoints(...points:IPoint[]):Bounds|null {
        let bounds:IBounds|null = points.reduce((bounds:IBounds|null, point:IPoint) => {
            return {
                north: bounds ? Math.max(bounds.north, point.lat) : point.lat,
                west: bounds ? Math.min(bounds.west, point.lng) : point.lng,
                south: bounds ? Math.min(bounds.south, point.lat) : point.lat,
                east: bounds ? Math.max(bounds.east, point.lng) : point.lng
            };
        }, null);

        if(bounds) {
            return new Bounds(bounds);
        }

        return null;
    }

    static fromQuadTiles(...quadTiles:QuadTile[]):Bounds|null {
        return Bounds.fromPoints(
            ...quadTiles.reduce((points:IPoint[], quadTile:QuadTile) => {
                return [
                    ...points,
                    quadTile.getTopLeftCorner(),
                    quadTile.getBottomRightCorner()
                ];
            }, [])
        );
    }

    static fromQuadKeys(...quadKey:string[]):Bounds|null {
        return Bounds.fromQuadTiles(
            ...quadKey.map((quadKey:string) => {
                return QuadTile.fromQuadKey(quadKey);
            })
        );
    }

    toOBJECT():IBounds {
        return this.toObject();
    }

    toObject():IBounds {
        return {
            east: this.east,
            west: this.west,
            south: this.south,
            north: this.north
        };
    }

    toString():string {
        return JSON.stringify(this.toOBJECT());
    }
}


export {Bounds};

export type {
    IPoint,
    IBounds
};

export default Bounds;