import {
    IPoint,
    IBounds
} from "src/types";

import QuadTile from "src/makes/QuadTile";


export const MAP_TERRAIN = "terrain";
export const MAP_SATELLITE = "satellite";
export type MapType = typeof MAP_TERRAIN | typeof MAP_SATELLITE;

export interface IMapTile {
    quadKey:string;
    color:string;
    count?:number;
    opacity?:number;
}

export type IMapPoint = {
    center:IPoint;
    count?:number;
    color?:string;
};

export interface IPolygon {
    bounds:IBounds;
    color:string;
    opacity?:number;
}

export interface IMarker {
    title?:string;
    point:IPoint,
    onClick?:() => void;
}

export interface MapEvent {
    bounds:IBounds;
    zoom:number;
    height:number;
    center:IPoint;
}

export type MapMouseEvent = {
    center:IPoint;
    cursorPosition:IPoint;
};

export type MapMouseEventMove = MapMouseEvent;

export interface MapEventMove extends MapEvent {
    tiles:QuadTile[];
}

export interface MapEventClick extends MapEvent {
    tile:QuadTile;
}

export interface MapEventSelect extends MapEvent {
    selectBounds:IBounds;
    tiles:QuadTile[];
}