import React from "react";
import {
    Grid,
    GridProps
} from "@material-ui/core";


interface Props extends GridProps {}


class PageSidebarContent extends React.Component<Props, any> {
    render() {
        const {
            children,
            className,
            ...rest
        } = this.props;

        return (
            <Grid
              container
              className={[
                "page-sidebar__content",
                className
              ].join(" ")}
              direction="column"
              {...rest}>
                {children}
            </Grid>
        );
    }
}


export default PageSidebarContent;