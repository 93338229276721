import {IModule} from "kpdux";
import * as fns from "date-fns";

import {parseJWT} from "src/tools";

import {
    CLIENT_ID,
    CLIENT_SECRET
} from "src/env";


type State = {
    token:string|null;
    expiresIn:number|null;
};

const app:IModule<State> = {
    state: (() => {
        const state:State = {
            token: null,
            expiresIn: null
        };

        try {
            state.token = localStorage.getItem("app:token") || null;
        }
        catch(ignore) {}

        return state;
    })(),
    getters: {
        token(state:State) {
            return state.token;
        },
        tokenData(state:State) {
            const {
                token
            } = state;

            return token ? parseJWT(token) : {};
        },
        expires(state:State, getters) {
            const {
                exp = 0
            } = getters.tokenData;

            return exp * 1000;
        },
        expiresIn(state:State, getters) {
            return getters.expires;
        },
        isRegistered(state:State, getters):boolean {
            const {
                token
            } = state;

            return Boolean(token && !getters.isExpired);
        },
        isExpired(state:State, getters):boolean {
            return fns.isBefore(new Date(getters.expires), new Date());
        }
    },
    actions: {
        async register() {
            console.log("register...");

            try {
                const requestTime = (new Date()).getTime();

                const res = await this.dispatch("api/post", "/api/oauth/token", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    data: {
                        grant_type: "client_credentials",
                        client_id: CLIENT_ID,
                        client_secret: CLIENT_SECRET,
                        scope: "*"
                    }
                });

                if(res.access_token) {
                    const {
                        access_token: token,
                        expires_in: expiresIn
                    } = res;

                    this.setToken(token, requestTime + expiresIn - 60 * 1000);
                }
            }
            catch(err:any) {
                return {
                    status: "ERROR",
                    message: err.message
                };
            }
        },
        setToken(token:string, expiresIn:number) {
            this.dispatch("storage/setItem", "app:token", token);
            // this.dispatch("storage/setItem", "app:expiresIn", expiresIn);

            this.updateToken(token, expiresIn);
        }
    },
    mutations: {
        updateToken(state:State, token:string, expiresIn:number) {
            state.token = token;
            state.expiresIn = expiresIn;
        }
    }
} as IModule<State>;


export type {State as AppState};

export {app};