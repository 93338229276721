import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {SubmissionError} from "redux-form";
import {RouteComponentProps} from "react-router";
import {
    withSnackbar,
    WithSnackbarProps
} from "notistack";

import {
    SignupForm,
    SignupFormData
} from "../../forms";

import store from "../../../store";


interface Props extends RouteComponentProps {}

interface RState {
    isLoggedIn:boolean;
}

interface RActions {
    signup:(data:any) => Promise<any>;
}

interface CProps extends Props, RState, RActions, WithSnackbarProps {}


class AuthSignup extends React.Component<CProps> {
    state = {
        isSignedUp: false
    }
    async onSubmit(data:SignupFormData) {
        const res = await this.props.signup(data);

        if(res.status === "OK") {
            if(res.message) {
                this.props.enqueueSnackbar(res.message, {
                    variant: "success"
                });
            }

            this.setState({
                isSignedUp: true
            });

            // this.props.history.push("/");
        }
        else {
            if(res.message) {
                this.props.enqueueSnackbar(res.message, {
                    variant: "error"
                });
            }

            this.setState({
                isSignedUp: false
            });

            if(res.errors) {
                throw new SubmissionError(res.errors);
            }
        }
    }

    render() {
        const {isSignedUp} = this.state;

        return (
            <SignupForm
              onSubmit={(data:SignupFormData) => {
                return this.onSubmit(data);
              }}
              isSignedUp={isSignedUp}
            />
        );
    }
}


const putState = ():RState => {
    return {
        isLoggedIn: store.auth.getters.isLoggedIn
    };
};

const putActions = ():RActions => {
    return {
        signup: store.auth.signup
    };
};

export default compose<React.ComponentType<Props>>(
    withSnackbar,
    connect(putState, putActions)
)(AuthSignup);