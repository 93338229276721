import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useImperativeHandle,
    forwardRef,
    PropsWithChildren
} from "react";
import {
    LeafletEvent,
    LeafletMouseEvent,
    Icon
} from "leaflet";
import {
    Map as LeafletMap,
    Rectangle,
    Circle,
    SVGOverlay,
    LayersControl,
    LayerGroup,
    Marker,
    Viewport
} from "react-leaflet";
import {GoogleLayer} from "react-leaflet-google-v2";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import * as lodash from "lodash";

import {
    IMapTile,
    IMapPoint,
    MapType,
    MAP_TERRAIN,
    MAP_SATELLITE,
    MapEventMove,
    MapEventClick,
    MapEventSelect,
    MapMouseEvent,
    IMarker
} from "../../types";

import {addLatLng} from "src/tools";
import Bounds, {IPoint, IBounds} from "src/makes/Bounds";
import QuadTile from "src/makes/QuadTile";

import "./index.scss";

import {GOOGLE_MAP_KEY} from "src/env";


type Props = {
    className?:string;
    width?:string;
    height?:string;
    style?:React.CSSProperties;
    mapType:MapType;
    selectable?:boolean;
    multiple?:boolean;
    dragging?:boolean;
    debug?:boolean;
    zoom?:number;
    heatmap?:boolean;
    center?:IPoint;
    minZoom?:number;
    maxZoom?:number;
    bounds?:IBounds|null;
    selectBounds?:IBounds|null;
    selectKeys?:string[];
    tiles?:IMapTile[];
    points?:IMapPoint[];
    polygons?:any[];
    markers?:IMarker[];
    onMouseMove?:(e:MapMouseEvent) => void;
    onMove?:(e:MapEventMove) => void;
    onClick?:(e:MapEventClick) => void;
    onSelect?:(e:MapEventSelect) => void;
};

type Map2DRef = {
    getZoom:() => number;
    flyTo:(center:IPoint, height:number) => void;
};

const VIEWPORT = "VIEWPORT";
const areaZoom = 16;

const Map2D = forwardRef<Map2DRef, Props>((props:PropsWithChildren<Props>, ref) => {
    const {
        width,
        height,
        className,
        mapType,
        heatmap = false,
        dragging = true,
        selectable = false,
        multiple = false,
        debug = false,
        minZoom = 0,
        maxZoom = 20,
        zoom: defaultZoom = 3,
        center,
        bounds,
        markers,
        tiles = [],
        points = [],
        polygons = [],
        selectBounds = null,
        selectKeys = [],
        onMouseMove,
        onMove,
        onClick,
        onSelect
    } = props;

    const [select, setSelect] = useState(false);
    const [areaStart, setAreaStart] = useState<IPoint|null>(null);
    const [areaEnd, setAreaEnd] = useState<IPoint|null>(null);
    const [selectRect, setSelectRect] = useState<IBounds|null>(null);
    const [selectArea, setSelectArea] = useState<IBounds|null>(selectBounds);
    const [selectAreas, setSelectAreas] = useState<IBounds[]>(() => {
        return (selectKeys || []).map((quadKey:string) => {
            const quadTile = QuadTile.fromQuadKey(quadKey);

            return quadTile.getBounds().toObject();
        });
    });
    const [showPoints, setShowPoints] = useState<IMapPoint[]>(points);
    const [zoom, setZoom] = useState(defaultZoom);
    // const [zoom] = useState();

    let debugRect:Bounds|null = null;

    if(debug && selectArea) {
        let selectAreaBounds:Bounds|null = Bounds.fromBounds(selectArea);

        if(selectAreaBounds) {
            let selectCenter = selectAreaBounds.getCenter();

            const size = Math.sqrt(250 * 250 * 2);

            debugRect = Bounds.fromPoints(
                addLatLng(selectCenter, size, size),
                addLatLng(selectCenter, -size, -size)
            );
        }
    }

    // const [] = useState<IBounds|null>(null);
    // const [viewport, setViewport] = useState(null);

    const refMap = useRef<any>(null);

    useEffect(() => {
        if(!lodash.isEqual(showPoints, points)) {
            setShowPoints(points);
        }
    }, [points]);

    useEffect(() => {
        if(select && areaStart && areaEnd) {
            setSelectRect(Bounds.fromPoints(areaStart, areaEnd));
        }
        else {
            if(selectRect !== null) {
                setSelectRect(null);
            }
        }
    }, [select, areaStart, areaEnd]);

    useEffect(() => {
        setSelectArea(selectBounds);
    }, [selectBounds]);

    useEffect(() => {
        if(!selectBounds) {
            const areas = (selectKeys || []).map((quadKey:string) => {
                const quadTile = QuadTile.fromQuadKey(quadKey);

                return quadTile.getBounds().toObject();
            });

            if(!lodash.isEqual(areas, selectAreas)) {
                setSelectAreas(areas);
            }
        }
        else {
            if(selectAreas.length > 0) {
                setSelectAreas([]);
            }
        }
    }, [JSON.stringify(selectBounds), JSON.stringify(selectKeys)]);

    const getMap = () => {
        if(refMap.current) {
            return refMap.current.contextValue.map;
        }

        return null;
    };

    const getBounds = () => {
        const map = getMap();

        if(map) {
            const leafletBounds = map.getBounds();

            const point1 = leafletBounds.getNorthEast();
            const point2 = leafletBounds.getSouthWest();

            return Bounds.fromPoints(point1, point2);
        }

        return null;
    };

    const getZoom = () => {
        const map = getMap();

        if(map) {
            return map.getZoom();
        }

        return 3;
    };

    const flyTo = useCallback((center:IPoint, height:number, animate:boolean = true) => {
        const map = getMap();

        const bounds = Bounds.fromPoints(
            addLatLng(center, -height / 4, -height / 3),
            addLatLng(center, height / 4, height / 3)
        );

        if(map && bounds) {
            map.flyToBounds([
                [bounds.north, bounds.west],
                [bounds.south, bounds.east]
            ], {
                animate
            });
        }
    }, []);

    const handleLoad = useCallback((e:LeafletEvent) => {
        const map = getMap();
        const bounds = getBounds();

        if(onMove && map && bounds) {
            setZoom(map.getZoom());

            onMove({
                zoom: map.getZoom(),
                center: map.getCenter(),
                bounds: bounds.toObject(),
                height: bounds.getWidth() || 0,
                tiles: QuadTile.fromBounds(bounds, areaZoom)
            });
        }
    }, []);

    const handleViewportChanged = useCallback((viewport:Viewport) => {
        if(refMap.current) {
            const {
                zoom,
                center
            } = viewport;

            const bounds = getBounds();

            if(onMove && bounds && center && typeof zoom === "number") {
                const [lat, lng] = center;

                setZoom(zoom);
                onMove({
                    center: {
                        lat,
                        lng
                    },
                    zoom,
                    height: bounds.getWidth(),
                    bounds: bounds.toObject(),
                    tiles: QuadTile.fromBounds(bounds, areaZoom)
                });
            }
        }
        else {
            setTimeout(() => {
                handleViewportChanged(viewport);
            }, 100);
        }
    }, [onMove]);

    const handleClick = useCallback((e:LeafletMouseEvent) => {
        const map = getMap();
        const bounds = getBounds();
        const quadTile:QuadTile = QuadTile.fromPoint(e.latlng, 22);
        const selectBounds:Bounds = quadTile.getBounds();

        if(selectable && !multiple && map && bounds && selectBounds) {
            setSelect(false);
            setAreaStart(null);
            setAreaEnd(null);

            if(onClick) {
                onClick({
                    zoom: map.getZoom(),
                    center: map.getCenter(),
                    height: bounds.getHeight(),
                    bounds: selectBounds.toObject(),
                    tile: quadTile
                });
            }
        }
    }, [selectable, multiple]);

    const handleContext = useCallback(() => {
        return false;
    }, []);

    const handleMouseUp = useCallback((e:LeafletMouseEvent) => {
        const map = getMap();
        const bounds = getBounds();

        if(onSelect && map && bounds && select && areaStart) {
            setSelect(false);
            setAreaEnd(e.latlng);

            // @ts-ignore
            const quadTiles = QuadTile.fromBounds(Bounds.fromPoints(areaStart, e.latlng), 22);
            // @ts-ignore
            const selectBounds = Bounds.fromQuadTiles(...quadTiles);

            if(selectBounds) {
                onSelect({
                    zoom: map.getZoom(),
                    center: map.getCenter(),
                    bounds: bounds.toObject(),
                    height: bounds.getHeight(),
                    tiles: quadTiles,
                    selectBounds: selectBounds.toObject()
                });
            }
        }
    }, [onSelect, select, areaStart, areaEnd]);

    const handleMouseDown = useCallback((e:LeafletMouseEvent) => {
        if(selectable && multiple) {
            setSelect(true);
            setAreaStart(e.latlng);
            setAreaEnd(e.latlng);
        }
    }, [selectable, multiple]);

    const handleMouseMove = useCallback((e:LeafletMouseEvent) => {
        const map = getMap();

        if(select && areaStart) {
            setAreaEnd(e.latlng);
        }

        if(map && onMouseMove) {
            onMouseMove({
                cursorPosition: e.latlng,
                center: map.getCenter()
            });
        }
    }, [select, areaStart, onMouseMove]);

    const getRadius = useCallback((zoom) => {
        return Math.pow(2, Math.max(0, zoom - 13));
    }, []);

    const handleMouseEnd = useCallback((e:LeafletMouseEvent) => {
        //
    }, []);

    const handleDrag = useCallback(() => {
        return false;
    }, []);

    useImperativeHandle(ref, () => {
        return {
            flyTo,
            getZoom
        };
    });

    return (
        <LeafletMap
          ref={refMap}
          style={{width, height}}
          className={["map-2d", className].join(" ")}
          attributionControl={true}
          zoomControl={true}
          minZoom={minZoom}
          maxZoom={maxZoom}
          dragging={dragging}
          zoom={zoom}
          center={center || {
            lat: 0,
            lng: 0
          }}
          bounds={bounds ? [
            [bounds.north, bounds.west],
            [bounds.south, bounds.east]
          ] : undefined}
          onViewportChanged={handleViewportChanged}
          onclick={handleClick}
          oncontextmenu={handleContext}
          onmouseup={handleMouseUp}
          onmousedown={handleMouseDown}
          onmousemove={handleMouseMove}
          onmoveend={handleMouseEnd}
          ondrag={handleDrag}>
            <LayersControl hideSingleBase>
                <LayersControl.BaseLayer name="Base" checked={mapType === MAP_TERRAIN}>
                    <GoogleLayer
                      googlekey={GOOGLE_MAP_KEY}
                      maptype="TERRAIN"
                      errorTileUrl="https://mt0.google.com/vt/lyrs=p&x={x}&y={y}&z={z}"
                      onLoad={handleLoad} />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Terrain" checked={mapType === MAP_SATELLITE}>
                    <GoogleLayer
                      googlekey={GOOGLE_MAP_KEY}
                      maptype="SATELLITE"
                      errorTileUrl="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                      onLoad={handleLoad} />
                </LayersControl.BaseLayer>
            </LayersControl>

            {heatmap && (
                <HeatmapLayer
                  // max={16}
                  opacity={0.8}
                  radius={getRadius(zoom)}
                  gradient={{
                    [ 1 / 16]: "#F3F6E8", // (for tiles with a score > 0 but smaller than 1.
                    [ 2 / 16]: "#ECF1DA",
                    [ 3 / 16]: "#E4EBCD",
                    [ 4 / 16]: "#DCE5BF",
                    [ 5 / 16]: "#D5DFB2",
                    [ 6 / 16]: "#CDD9A5",
                    [ 7 / 16]: "#C5D398",
                    [ 8 / 16]: "#bdcc8b",
                    [ 9 / 16]: "#ABC082",
                    [10 / 16]: "#99B479",
                    [11 / 16]: "#89A770",
                    [12 / 16]: "#799B67",
                    [13 / 16]: "#698E5E", // (etc.)
                    [14 / 16]: "#5B8255", // (for tiles with a score of 13-13.9999)
                    [15 / 16]: "#4D754C", // (for tiles with a score of 14-14.9999)
                    [16 / 16]: "#436846"  // (for tiles with a score of 15-16)
                  }}
                  intensityExtractor={(point:IMapPoint) => {
                    return point.count;
                  }}
                  latitudeExtractor={(point:IMapPoint) => {
                    return point.center.lat;
                  }}
                  longitudeExtractor={(point:IMapPoint) => {
                    return point.center.lng;
                  }}
                  points={showPoints} />
            )}

            {markers && markers.map((marker:IMarker, index:number) => {
                return (
                    <Marker
                      key={index}
                      position={marker.point}
                      onclick={marker.onClick} />
                );
            })}

            {debugRect && (
                <LayerGroup pane="debug">
                    <Circle
                      center={debugRect.getCenter()}
                      color="#CCCCCC"
                      stroke={true}
                      opacity={0.3}
                      radius={500} />

                    <Rectangle
                      color="#FF55FF"
                      stroke={true}
                      weight={1}
                      opacity={0.3}
                      bounds={[
                        [debugRect.north, debugRect.west],
                        [debugRect.south, debugRect.east]
                      ]} />
                </LayerGroup>
            )}

            <LayerGroup pane="areas">
                {selectRect && (
                    <Rectangle
                      color={"#75afe5"}
                      stroke={true}
                      weight={1}
                      bounds={[
                        [selectRect.north, selectRect.west],
                        [selectRect.south, selectRect.east]
                      ]} />
                )}

                {selectArea && (
                    <SVGOverlay
                      className="map-2d__select-area"
                      bounds={[
                        [selectArea.north, selectArea.west],
                        [selectArea.south, selectArea.east]
                      ]}>
                        <rect
                          width="100%"
                          height="100%"
                          fill="transparent"
                          fillOpacity="0.2"
                          fillRule="evenodd"
                          stroke="#000000"
                          strokeWidth="1" />
                    </SVGOverlay>
                )}

                {selectAreas.map((bounds:IBounds, index:number) => {
                    return (
                        <SVGOverlay
                          key={index}
                          className="map-2d__select-area"
                          bounds={[
                            [bounds.north, bounds.west],
                            [bounds.south, bounds.east]
                          ]}>
                            <rect
                              width="100%"
                              height="100%"
                              fill="transparent"
                              fillOpacity="0.2"
                              fillRule="evenodd"
                              stroke="#000000"
                              strokeWidth="1" />
                        </SVGOverlay>
                    );
                })}
            </LayerGroup>

            <LayerGroup pane="tiles">
                {tiles && tiles.map((tile:IMapTile, index:number) => {
                    const quadTile:QuadTile = QuadTile.fromQuadKey(tile.quadKey);

                    const point1:IPoint = quadTile.getTopLeftCorner();
                    const point2:IPoint = quadTile.getBottomRightCorner();

                    return (
                        <Rectangle
                          key={"tile-" + (index)}
                          color={tile.color}
                          fillOpacity={tile.opacity || 0.8}
                          stroke={false}
                          bounds={[
                            [point1.lat, point1.lng],
                            [point2.lat, point2.lng]
                          ]}>
                            {typeof tile.count !== "undefined" && (
                                <SVGOverlay
                                  className="map-2d__tile-count"
                                  style={{zIndex: 10000}}
                                  viewBox="0 0 100 100"
                                  bounds={[
                                    [point1.lat, point1.lng],
                                    [point2.lat, point2.lng]
                                  ]}>
                                    <text
                                      x="50%" y="50" dy=".35em"
                                      fontSize="35"
                                      fontWeight="bold"
                                      textAnchor="middle"
                                      fill="#FFFFFF"
                                      stroke="#FFFFFF"
                                      strokeWidth="1px">{tile.count || 1}</text>
                                </SVGOverlay>
                            )}
                        </Rectangle>
                    );
                })}
            </LayerGroup>

            <LayerGroup pane="polygons">
                {polygons && polygons.map((polygon:any, index:number) => {
                    return (
                        <Rectangle
                          key={"polygons" + index}
                          color={polygon.color}
                          fillOpacity={polygon.opacity || 0.8}
                          stroke={false}
                          bounds={[
                            [polygon.bounds.north, polygon.bounds.east],
                            [polygon.bounds.south, polygon.bounds.west]
                          ]} />
                    );
                })}
            </LayerGroup>
        </LeafletMap>
    );
});

Map2D.displayName = "Map2D";

Icon.Default.prototype.options.imagePath = "/images/";


export type {
    Map2DRef
};

export default Map2D;