import React from "react";
import {compose} from "redux";
import {
    WrappedFieldProps
} from "redux-form";
import {
    createStyles,
    withStyles,
    WithStyles,
    Theme,
    TextField,
    Typography
} from "@material-ui/core";
import TextMask from "react-input-mask";


interface Props extends WrappedFieldProps {
    required?:boolean;
    mask?:string;
    maskChar?:string;
    alwaysShowMask?:boolean;
    label?:string;
}

interface CProps extends Props, WithStyles {}

class InputWrapper extends React.Component<CProps, any> {
    render() {
        const {
            classes,
            meta,
            input,
            required = false,
            mask,
            maskChar = "",
            alwaysShowMask = false,
            label,
            ...rest
        } = this.props;

        const labelNode = (
            <span>
                {label}
                <Typography key={2} color="error" display="inline">
                    {required ? "*" : ""}
                </Typography>
            </span>
        );

        return (
            <React.Fragment>
                {mask ? (
                    <TextMask
                      {...rest}
                      {...input}
                      mask={mask}
                      maskChar={maskChar}
                      alwaysShowMask={alwaysShowMask}>
                        {(props:any) => {
                            return (
                                <TextField
                                  {...rest}
                                  {...props}
                                  label={labelNode}
                                  className={classes.input}
                                  helperText={meta.touched && meta.error}
                                  error={meta.touched && meta.invalid} />
                            );
                        }}
                    </TextMask>
                ) : (
                    <TextField
                      {...rest}
                      {...input}
                      className={classes.input}
                      label={labelNode}
                      helperText={meta.touched && meta.error}
                      error={meta.touched && meta.invalid} />
                )}
            </React.Fragment>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    input: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
});

export default compose<React.ComponentType<Props>>(
    withStyles(styles)
)(InputWrapper);