import * as fns from "date-fns";
import * as lodash from "lodash";

import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetOne,
    IResponseGetList
} from "src/makes/Model";
import {Bounds, IBounds} from "src/makes/Bounds";
import {QuadTile} from "src/makes/QuadTile";
import {calcRate} from "src/tools";


export interface ISuggest {
    id:string;
    userId:string;
    categoryId:string;
    top5Ids:string[];
    address:string;
    quadKeys:string[];
    title:string;
    description:string;
    user:any;
    image:any;
    votes:number;
    votesPositive:number;
    votesNeutral:number;
    votesNegative:number;
    votesLocal:number;
    votesNonLocal:number;
    votesLocalPositive:number;
    votesLocalNeutral:number;
    votesLocalNegative:number;
    commentsTotal:number;
    rate:number;
    percentile:number;
    rateLocal:number;
    rateNonLocal:number;
    rateSurrounding:number;
    updateDays:number;
    date:string;
    updateDate:string;
    slug:string;
}

class Suggest extends Model<ISuggest> {
    static modelName:string = "suggest";
    static queryCount:string = "count";
    static queryPage:string = "page";

    static filters = {
        bounds: (suggest:ISuggest, bounds:IBounds) => {
            const b = new Bounds(bounds);

            return suggest.quadKeys.reduce((inBounds:boolean, quadKey:string) => {
                const quad = QuadTile.fromQuadKey(quadKey);

                return (
                    inBounds ||
                    b.hasPoint(quad.getTopLeftCorner()) ||
                    b.hasPoint(quad.getBottomRightCorner()) ||
                    b.hasPoint(quad.getCenter())
                );
            }, false);
        },
        quadKey: (suggest:ISuggest, quadKeys:string|string[]) => {
            return suggest.quadKeys.reduce((includes:boolean, quadKey:string) => {
                if(Array.isArray(quadKeys)) {
                    return includes || quadKeys.reduce((includes:boolean, qk:string) => {
                        return includes || quadKey.indexOf(qk) === 0;
                    }, false);
                }
                else {
                    return includes || quadKey.indexOf(quadKeys) === 0;
                }
            }, false);
        }
    };

    async getOne(id:any):IResponseGetOne<ISuggest> {
        const res = await this.dispatch("api/get", `/api/dashboard/${Suggest.modelName}/${id}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            }
        });

        if(res.httpStatus === 200) {
            const {
                response: item
            } = res;

            return {
                status: STATUS_SUCCESS,
                item
            };
        }

        return {
            status: STATUS_ERROR
        };
    }

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ISuggest> {
        const res = await this._getList({
            ...query,
            ...count > 0 || page > 0 ? {
                count,
                page: page + 1
            } : {}
        });

        if(res.httpCode === 200) {
            const {
                response: {
                    count: total = 0,
                    data: items = [],
                    summary = null
                }
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: Array.isArray(items) ? items : Object.values(items),
                summary
            };
        }

        return {
            status: STATUS_ERROR
        };
    }

    static prepare(data:any, method:string):ISuggest {
        const {
            image: images,
            votesTotal: votes = 0,
            address,
            ...other
        } = data;

        const {
            votesPositive = 0,
            votesNeutral = 0,
            votesNegative = 0,
            votesLocalPositive = 0,
            votesLocalNeutral = 0,
            votesLocalNegative = 0,
            updateDate
        } = data;

        const votesLocal = votesLocalPositive + votesLocalNeutral + votesLocalNegative;
        let update = fns.parse(updateDate, "yyyy-MM-dd", new Date());

        let imageSrc = lodash.get(images, "[0].links.sizes.original.view", null);

        return {
            ...other,
            address: address || "No address",
            votes: votes,
            votesLocal: votesLocal,
            votesNonLocal: votes - votesLocal,
            rate: calcRate(votesPositive, votesNeutral, votesNegative) || 0,
            rateLocal: calcRate(votesLocalPositive, votesLocalNeutral, votesLocalNegative) || 0,
            rateNonLocal: calcRate(votesPositive - votesLocalPositive, votesNeutral - votesLocalNeutral, votesNegative - votesLocalNegative) || 0,
            image: imageSrc ? {src: imageSrc} : null,
            updateDays: fns.isValid(update) ? fns.differenceInCalendarDays(new Date(), update) : 0
        };
    }
}


export {Suggest};