import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Theme,
    createStyles,
    withStyles,
    WithStyles,
    Grid,
    Avatar,
    Typography,
    Card,
    CardActionArea
} from "@material-ui/core";

import {
    ISuggest,
    ISuggestCategory
} from "src/types";

import {dateConvert} from "src/tools";
import QueryString from "src/makes/QueryString";

import store, {
    Suggest,
    SuggestCategory
} from "src/store";


interface Props {
    id:string;
}

interface RState {
    byId:(name:string, id:any) => any;
}

interface RActions {}

interface CProps extends Props, RState, RActions, WithStyles, RouteComponentProps {}


class SuggestListItem extends React.Component<CProps, any> {
    onClick() {
        const {
            id,
            history
        } = this.props;

        const suggest:ISuggest = this.props.byId(Suggest.modelName, id);

        if(suggest) {
            let query = QueryString.stringify({
                quadKeys: suggest.quadKeys
            });

            history.push("/suggestion/view/" + suggest.id + "?" + query);
        }
    }

    render() {
        const {
            classes,
            id
        } = this.props;

        const suggest:ISuggest = this.props.byId(Suggest.modelName, id);
        const category:ISuggestCategory = this.props.byId(SuggestCategory.modelName, suggest ? suggest.categoryId : "");

        const {
            address,
            title,
            image,
            votes,
            rate,
            date
        }:ISuggest = suggest || {};

        return (
            <Card
              style={{
                marginTop: "10px",
                marginBottom: "16px"
              }}
              className={classes.root}
              onClick={() => this.onClick()}>
                <CardActionArea>
                    <Grid container>
                        <Grid item xs={5} sm={4} md={3} lg={5}>
                            <Avatar
                              style={{
                                width: "100%",
                                height: "100%"
                              }}
                              variant="square"
                              src={image ? image.src : ""} />
                        </Grid>

                        <Grid item xs={7} sm={8} md={9} lg={7}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            padding: "14px 10px 14px 11px"
                          }}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="caption">
                                        {category ? (category.title || "").toUpperCase() : ""}
                                    </Typography>

                                    <Typography variant="body1">
                                        {title}
                                    </Typography>

                                    <Typography
                                      style={{
                                        paddingTop: "5px",
                                        color: "#707070"
                                      }}
                                      variant="subtitle2">
                                        {address ? address : null}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container
                              justifyContent="space-between"
                              alignItems="flex-end">
                                <Grid item>
                                    <Grid container>
                                        <Grid item
                                          style={{
                                            paddingRight: "22px"
                                          }}>
                                            <Typography
                                              color="textSecondary"
                                              variant="subtitle2">
                                                Date
                                            </Typography>

                                            <Typography variant="body1">
                                                {dateConvert(date, "yyyy-MM-dd", "dd.MM.yy")}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography
                                              color="textSecondary"
                                              variant="subtitle2">
                                                Votes
                                            </Typography>

                                            <Typography
                                              variant="body1">
                                                {votes}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography
                                      style={{
                                        fontSize: "30px",
                                        fontWeight: 600
                                      }}
                                      variant="h3">
                                        {votes > 0 ? `${rate}%`: ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        );
    }
}


const styles = (theme:Theme) => createStyles({
    root: {
        [theme.breakpoints.up("lg")]: {
            paddingRight: 0
        }
    }
});

const putState = ():RState => {
    return {
        byId: store.orm.getters.byId
    };
};

const putActions = ():RActions => {
    return {};
};

export default compose<React.ComponentType<Props>>(
    withRouter,
    withStyles(styles),
    connect(putState, putActions)
)(SuggestListItem);