import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {SubmissionError} from "redux-form";
import {RouteComponentProps} from "react-router";
import {
    withSnackbar,
    WithSnackbarProps
} from "notistack";

import {
    PWResetForm,
    PWResetFormData
} from "../../forms";

import store from "../../../store";


interface Props extends RouteComponentProps {}

interface RState {}

interface RActions {
    passwordReset:(data:any) => Promise<any>;
}

interface CProps extends Props, RState, RActions, WithSnackbarProps {}


class AuthPWReset extends React.Component<CProps, any> {
    async onSubmit(data:PWResetFormData) {
        const res = await this.props.passwordReset(data);

        if(res.status === "OK") {
            if(res.message) {
                this.props.enqueueSnackbar(res.message, {
                    variant: "success"
                });
            }

            this.props.history.push("/");
        }
        else {
            if(res.message) {
                this.props.enqueueSnackbar(res.message, {
                    variant: "error"
                });
            }

            if(res.errors) {
                throw new SubmissionError(res.errors);
            }
        }
    }

    render() {
        return (
            <PWResetForm
              onSubmit={(data:PWResetFormData) => this.onSubmit(data)} />
        );
    }
}


const putState = ():RState => {
    return {};
};

const putActions = ():RActions => {
    return {
        passwordReset: store.auth.passwordReset
    };
};

export default compose<React.ComponentType<Props>>(
    withSnackbar,
    connect(putState, putActions)
)(AuthPWReset);