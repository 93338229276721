import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Paper,
    Grid,
    Avatar,
    Typography,
    IconButton
} from "@material-ui/core";
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Close as CloseIcon
} from "@material-ui/icons";

import {
    ISuggest
} from "../../../../../types";

import {dateConvert} from "../../../../../tools";
import QueryString from "../../../../../makes/QueryString";

import {
    Emotion
} from "../../../../blocks";

import store from "../../../../../store";


interface Props {
    suggestId?:string;
    quadKeys?:string[];
}

interface RState {
    suggest?:ISuggest;
    suggests:ISuggest[];
    index:number;
    byId:(type:string, id:any) => any;
    filter:(type:string, filters:any) => any[];
}

interface RActions {}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class SuggestCard extends React.Component<CProps, any> {
    onClose() {
        const {
            history
        } = this.props;

        history.push("/suggestion");
    }

    onPrev() {
        const {
            suggests,
            index,
            quadKeys,
            history
        } = this.props;

        let suggest = suggests[index > 0 ? index - 1 : suggests.length - 1];

        let query = QueryString.stringify({
            quadKeys: quadKeys
        });

        history.push("/suggestion/map/" + suggest.id + (query ? "?" + query : ""));
    }

    onNext() {
        const {
            suggests,
            index,
            quadKeys,
            history
        } = this.props;

        let suggest = suggests[index < suggests.length - 1 ? index + 1 : 0];

        let query = QueryString.stringify({
            quadKeys: quadKeys
        });

        history.push("/suggestion/map/" + suggest.id + (query ? "?" + query : ""));
    }

    onClick() {
        const {
            suggest,
            history
        } = this.props;

        if(suggest) {
            let query = QueryString.stringify({
                quadKeys: suggest.quadKeys
            });

            history.push("/suggestion/view/" + suggest.id + "?" + query);
        }
    }

    render() {
        const {
            suggest,
            suggests,
            index
        } = this.props;

        const {
            title = "",
            address = "",
            image = null,
            votes = null,
            commentsTotal = null,
            rate = 0,
            updateDays = null,
            date = null
        } = suggest || {};

        return (
            <Paper className="page-suggest__card suggest-card">
                <Grid container
                  className="suggest-card__nav"
                  alignItems="center">
                    <IconButton
                      classes={{root: "suggest-card__nav-button"}}
                      onClick={() => this.onPrev()}>
                        <ChevronLeftIcon
                          fontSize="small" />
                    </IconButton>

                    <Typography
                      className="suggest-card__nav-text">
                        {index + 1} of {suggests.length}
                    </Typography>

                    <IconButton
                      classes={{root: "suggest-card__nav-button"}}
                      onClick={() => this.onNext()}>
                        <ChevronRightIcon
                          fontSize="small" />
                    </IconButton>
                </Grid>

                <IconButton
                  className="suggest-card__close"
                  onClick={() => this.onClose()}>
                    <CloseIcon fontSize="small" />
                </IconButton>

                <div className="suggest-card__wrapper" onClick={() => this.onClick()}>
                    <Avatar
                      style={{
                        width: "100%",
                        height: "176px"
                      }}
                      variant="square"
                      src={image ? image.src : ""} />

                    <Grid container
                      className="suggest-card__content"
                      direction="column">
                        <Grid container>
                            <Grid item>
                                <Typography variant="body1">
                                    {address}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container
                          className="suggest-card__rate"
                          alignItems="center">
                            {votes && votes > 0 ? (
                                <>
                                    <Grid item
                                      className="suggest-card__rate-emotion">
                                        <Emotion
                                          size="medium"
                                          rate={rate} />
                                    </Grid>

                                    <Grid item>
                                        <Typography className="suggest-card__rate-value">
                                            {rate}%
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item
                                  className="suggest-card__rate-emotion">
                                    <div className="no-votes-dash" />
                                </Grid>
                            )}
                        </Grid>

                        <Grid container>
                            <Grid item className="suggest-card__text">
                                <Typography variant="body1">
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item className="suggest-card__parameter">
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2">
                                    Date
                                </Typography>

                                <Typography variant="body1">
                                    {date ? dateConvert(date, "yyyy-MM-dd", "dd.MM.yy") : ""}
                                </Typography>
                            </Grid>

                            <Grid item className="suggest-card__parameter">
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2">
                                    Votes
                                </Typography>

                                <Typography variant="body1">
                                    {votes}
                                </Typography>
                            </Grid>

                            <Grid item className="suggest-card__parameter">
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2">
                                    Comments
                                </Typography>

                                <Typography>
                                    {commentsTotal}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item className="suggest-card__parameter">
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2">
                                    Days since last activity
                                </Typography>

                                <Typography variant="body1">
                                    {updateDays}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        );
    }
}


const putState = (state:any, props:Props):RState => {
    const {
        suggestId,
        quadKeys
    } = props;

    const suggest:ISuggest = store.orm.getters.byId("suggest", suggestId);
    const suggests:ISuggest[] = store.orm.getters.filter("suggest", {
        quadKey: quadKeys
    });

    return {
        suggest: suggest,
        suggests: suggests,
        index: suggests.findIndex((suggest:any) => {
            return suggest.id === suggestId;
        }),
        byId: store.orm.getters.byId,
        filter: store.orm.getters.filter
    };
};

const putActions = ():RActions => {
    return {};
};

export default compose<React.ComponentType<Props>>(
    withRouter,
    connect(putState, putActions)
)(SuggestCard);