import {IModule} from "kpdux";
import * as fns from "date-fns";

import {
    STATUS_SUCCESS,
    STATUS_ERROR
} from "src/makes/Model";
import {parseJWT} from "src/tools";

import {
    PASSWORD_ID,
    PASSWORD_SECRET
} from "src/env";


type State = {
    token:string|null;
    refreshToken:string|null;
    roles:any[];
};

const auth:IModule<State> = {
    state: (() => {
        const state:State = {
            token: null,
            refreshToken: null,
            roles: []
        };

        try {
            state.token = localStorage.getItem("auth:token") || null;
            state.refreshToken = localStorage.getItem("auth:refreshToken") || null;
        }
        catch(ignore) {}

        try {
            state.roles = JSON.parse(localStorage.getItem("auth:roles") || "");
        }
        catch(ignore) {
            state.roles = [];
        }

        return state;
    })(),
    getters: {
        token(state:State):string|null {
            const {
                token
            } = state;

            return token;
        },
        refreshToken(state:State) {
            const {refreshToken} = state;

            return refreshToken;
        },
        tokenData(state:State) {
            const {
                token
            } = state;

            return token ? parseJWT(token) : {};
        },
        expires(state:State, getters) {
            const {
                exp = 0
            } = getters.tokenData;

            return exp * 1000;
        },
        isLoggedIn(state:State, getters):boolean {
            return Boolean(getters.token && !getters.isExpired);
        },
        isExpired(state:State, getters):boolean {
            return fns.isBefore(new Date(getters.expires), new Date());
        },
        isAdmin(state:State, getters):boolean {
            const {
                roles
            } = state;

            if(getters.isLoggedIn && roles && Array.isArray(roles)) {
                return !!(roles).find((role) => {
                    return role.name === "admin";
                });
            }

            return false;
        },
        authHeaders(state:State):HeadersInit {
            return {
                Authorization: `Bearer ${state.token}`
            };
        }
    },
    actions: {
        async login(data) {
            try {
                const requestTime = (new Date()).getTime();
                const res = await this.dispatch("api/post", "/api/oauth/token", {
                    data: {
                        ...data,
                        grant_type: "password",
                        client_id: PASSWORD_ID,
                        client_secret: PASSWORD_SECRET,
                        scope: "*"
                    }
                });

                if(res.access_token) {
                    const {
                        access_token: token,
                        refresh_token: refreshToken
                    } = res;

                    this.setToken(token);
                    this.setRefreshToken(refreshToken);

                    return this.getUserRoles();
                }

                const {
                    response: {
                        message = "",
                        validationMessages: errors = {}
                    } = {}
                } = res;

                return {
                    status: "ERROR",
                    message: message,
                    errors: errors
                };
            }
            catch(err:any) {
                return {
                    status: "ERROR",
                    message: err.message || "Server connection error"
                };
            }
        },
        async logout() {
            this.setToken(null);
            this.setRefreshToken(null);
            this.setRoles([]);

            return {
                status: "OK"
            };
        },
        async refresh() {
            if(!this.getters.refreshToken) {
                return {
                    status: "ERROR"
                };
            }

            const res = await this.dispatch("api/post", "/api/oauth/token", {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                },
                data: {
                    grant_type: "refresh_token",
                    client_id: PASSWORD_ID,
                    client_secret: PASSWORD_SECRET,
                    scope: "*",
                    refresh_token: this.getters.refreshToken
                }
            });

            if(res && res.access_token) {
                const {
                    access_token: token,
                    refresh_token: refreshToken
                } = res;

                this.setToken(token);
                this.setRefreshToken(refreshToken);

                return {
                    status: "OK"
                };
            }

            return {
                status: "ERROR"
            };
        },
        async signup(data:any) {
            try {
                const res = await this.dispatch("api/post", "/api/dashboard/admin/create", {
                    headers: {
                        "Authorization": `Bearer ${this.rootGetters.app.token}`,
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    data: {
                        ...data,
                        client_id: PASSWORD_ID,
                        client_secret: PASSWORD_SECRET
                    }
                });

                if(res.id || res.httpCode === 200) {
                    return {
                        status: STATUS_SUCCESS,
                        message: "User created"
                    };
                }

                return {
                    status: STATUS_ERROR,
                    ...res.response ? {
                        message: res.response.message,
                        errors: res.response.validationMessages
                    } : {}
                };
            }
            catch(err:any) {
                return {
                    status: "ERROR",
                    message: err.message
                };
            }
        },
        async getUserRoles() {
            try {
                const res = await this.dispatch("api/get", "/api/user-roles", {
                    headers: this.getters.authHeaders
                });

                if(res.httpCode === 200) {
                    const {
                        response: roles = []
                    } = res;

                    this.setRoles(roles);

                    return {
                        status: "OK",
                        items: roles
                    };
                }

                return {
                    status: "ERROR",
                    message: "Error message"
                };
            }
            catch(err:any) {
                return {
                    status: "ERROR",
                    message: err.message
                };
            }
        },
        async passwordReset(data:any) {
            const res = await this.dispatch("api/post", "/api/user/password-reset", {
                headers: {
                    "Authorization": `Bearer ${this.getters.appToken}`,
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                },
                data: data
            }).catch((err:any) => {
                console.error("auth.passwordReset(", data, ") -> ", err);

                return null;
            });

            if(res) {
                if(res.httpCode === 200 && res.operationStatus === "success") {
                    return {
                        status: STATUS_SUCCESS,
                        message: "Success"
                    };
                }
                else {
                    return {
                        status: STATUS_ERROR,
                        ...res.response ? {
                            message: res.response.message,
                            errors: res.response.validationMessages
                        } : {}
                    };
                }
            }

            return {
                status: STATUS_ERROR,
                message: "Server Error",
                errors: {}
            };
        },
        async passwordUpdate(data:any) {
            try {
                const {
                    email,
                    password,
                    code
                } = data;

                const res = await this.dispatch("api/put", "/api/user/password-reset", {
                    headers: {
                        "Authorization": `Bearer ${this.getters.appToken}`,
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
                    },
                    data: {
                        email: email,
                        password: password,
                        token: code
                    }
                });

                if(res.status === STATUS_SUCCESS) {
                    return res;
                }
                else if(res.status === STATUS_ERROR) {
                    return res;
                }
                else if(res.httpCode === 200 && res.operationStatus === "success") {
                    return {
                        status: STATUS_SUCCESS
                    };
                }

                return {
                    status: STATUS_ERROR,
                    ...res.response ? {
                        message: res.response.message,
                        errors: res.response.validationMessages
                    } : {}
                };
            }
            catch(err:any) {
                return {
                    status: "ERROR",
                    message: err.message
                };
            }
        }
    },
    mutations: {
        setToken(state:State, token:State["token"]) {
            if(token) {
                localStorage.setItem("auth:token", token);
            }
            else {
                localStorage.removeItem("auth:token");
            }

            state.token = token;
        },
        setRefreshToken(state:State, refreshToken:State["refreshToken"]) {
            if(refreshToken) {
                localStorage.setItem("auth:refreshToken", refreshToken);
            }
            else {
                localStorage.removeItem("auth:refreshToken");
            }

            state.refreshToken = refreshToken;
        },
        setRoles(state:State, roles:State["roles"]) {
            if(roles) {
                localStorage.setItem("auth:roles", JSON.stringify(roles));
            }
            else {
                localStorage.removeItem("auth:roles");
            }

            state.roles = roles;
        }
    }
};


export type {State as AuthState};

export {auth};