import React from "react";
import * as fns from "date-fns";
import {
    Grid,
    Typography,
    Avatar
} from "@material-ui/core";
import {Favorite as FavoriteIcon} from "@material-ui/icons";
import ModalImage from "react-modal-image";

import {PageHtml} from "src/views/blocks";

import "./index.scss";


interface Props {
    user:{
        fullName:string;
        avatar:string;
    };
    date:string;
    likes:number;
    content:string;
    images?:any[];
}


class Comment extends React.Component<Props, any> {
    render() {
        const {
            children,
            user,
            date,
            likes,
            content,
            images
        } = this.props;

        return (
            <Grid container
              className="comment"
              direction="column">
                <Grid container alignItems="center">
                    <Grid item className="comment__leftbar">
                        <Avatar
                          style={{
                            width: "55px",
                            height: "55px"
                          }}
                          src={user ? user.avatar : ""} />
                    </Grid>

                    <Grid item className="comment__rightbar">
                        <Grid container
                          alignItems="center"
                          justifyContent="space-between">
                            <Grid item>
                                <Typography
                                  variant="body1">
                                    {user ? user.fullName : ""}
                                </Typography>

                                <Typography
                                  variant="body1">
                                    {date ? fns.format(fns.parse(date, "yyyy.MM.dd", new Date()), "MMM do yyyy") : ""}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Grid container
                                  alignItems="center">
                                    <Grid item component={Typography}
                                      variant="body1">
                                        <FavoriteIcon
                                          style={{
                                            fontSize: "24px"
                                          }}
                                          color={likes && likes > 0 ? "primary" : "disabled"} />
                                    </Grid>

                                    <Grid item component={Typography}
                                      style={{
                                        marginLeft: "3px",
                                        fontSize: "12px"
                                      }}
                                      className="text-center"
                                      variant="body1"
                                      color="textSecondary">
                                        {likes && likes > 0 ? likes : "0"}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className="comment__sidebar text-center">
                        {children}
                    </Grid>

                    <Grid item className="comment__rightbar">
                        <PageHtml
                          html={content} />

                        {(Array.isArray(images) ? images : []).map((image) => {
                            return (
                                <div key={image.id}
                                  className="comment__picture">
                                    <ModalImage
                                      className="smallModalImage"
                                      modalOpen
                                      hideDownload
                                      hideZoom
                                      alt=""
                                      large={image.source}
                                      small={image.source} />
                                </div>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default Comment;