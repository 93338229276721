import React from "react";
import {Route} from "react-router";
import {
    makeStyles,
    Tooltip
} from "@material-ui/core";

import {
    Comment,
    Emotion,
    ParameterIcon
} from "src/views/blocks";

import {ROUTES} from "src/routing";

import {
    useORM,
    TileComment,
    TileParameter,
    ITileParameter,
    ITileComment
} from "src/store";


type Props = {
    id:string;
};

const RatingComment:React.FC<Props> = (props:Props) => {
    const {
        id
    } = props;

    const classes = useStyles(props);

    const comment:ITileComment|null = useORM((orm) => {
        return orm.byId(TileComment.modelName, id);
    });

    const {
        user = {},
        date = "",
        likes = 0,
        value = 1,
        content = "",
        images = null
    } = comment || {};

    let emotion:any = "neutral";
    let color = "#D8D8D8";

    switch(value) {
        case 2:
            emotion = "happy";
            color = "#73AD56";
            break;

        case 1:
            emotion = "neutral";
            color = "#D8D8D8";
            break;

        case 0:
            emotion = "sad";
            color = "#E66C72";
            break;
    }

    const parameter:ITileParameter|null = useORM((orm) => {
        if(comment) {
            return orm.byId(TileParameter.modelName, comment.parameterId);
        }

        return null;
    });

    return (
        <Comment
          user={user}
          date={date}
          likes={likes}
          content={content}
          images={images}>
            <Route exact path={ROUTES.ratingView}>
                <Emotion
                  type={emotion}
                  size="small"
                  color="secondary" />
            </Route>

            <Route path={ROUTES.ratingViewParameters}>
                {parameter && (
                    <Tooltip title={parameter.title} aria-label="icon">
                        <ParameterIcon
                          className={classes.icon}
                          color={color}
                          src={parameter.icon} />
                    </Tooltip>
                )}
            </Route>
        </Comment>
    );
};


const useStyles = makeStyles(() => {
    return {
        icon: {
            width: 25,
            height: 25
        }
    };
});

export default RatingComment;