import React, {
    Fragment
} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import {
    ChevronRight as ChevronRightIcon
} from "@material-ui/icons";

import {
    ISuggest
} from "../../../../../types";

import QueryString from "../../../../../makes/QueryString";

import {
    Emotion
} from "../../../../blocks";

import {
    RatingParameterTotals
} from "../../../RatingPage/blocks";

import store from "../../../../../store";


interface Props {
    suggestId:string;
    quadKeys:string[];
}

interface RState {
    byId:(type:string, id:any) => any;
    getSummary:(type:string, query?:any) => any;
}

interface CProps extends Props, RState {}


class SuggestRating extends React.Component<CProps, any> {
    render() {
        const {
            suggestId,
            quadKeys = []
        } = this.props;

        const {
            votes = 0,
            rate = 0
        } = this.props.getSummary("tile", {
            withSummary: true,
            quadKeys
        });

        return (
            <Fragment>
                <Typography variant="h4">
                    Space rating for these tiles
                </Typography>

                <Grid container
                  style={{
                    padding: "20px 0 10px 0"
                  }}
                  justifyContent="space-between"
                  alignContent="center"
                  alignItems="center">
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item style={{paddingRight: "10px"}}>
                                <Emotion
                                  size="medium"
                                  {...votes > 0 ? {
                                    rate: rate
                                  } : {
                                    type: "neutral",
                                    color: "primary"
                                  }} />
                            </Grid>

                            <Grid item>
                                <Typography style={{fontSize: "20px"}}>
                                    {rate}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant="body1">
                            {votes} Votes
                        </Typography>
                    </Grid>
                </Grid>

                <RatingParameterTotals
                  quadKeys={quadKeys} />

                {
                    quadKeys.length ? (
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button component={Link}
                                  classes={{
                                    label: "text-uppercase"
                                  }}
                                  size="small"
                                  to={"/rating?" + QueryString.stringify({quadKeys})}
                                  endIcon={<ChevronRightIcon />}>
                                    Space rating details
                                </Button>
                            </Grid>
                        </Grid>
                    ) : null
                }
            </Fragment>
        );
    }
}


const putState = ():RState => {
    return {
        byId: store.orm.getters.byId,
        getSummary: store.orm.getters.getSummary
    };
};

export default compose(
    connect(putState)
)(SuggestRating);