import React from "react";
import {compose} from "redux";
import {
    reduxForm,
    Form,
    InjectedFormProps,
    ConfigProps,
    DecoratedFormProps,
    FormErrors
} from "redux-form";
import {Link} from "react-router-dom";
import {
    Box,
    Grid,
    Typography,
    MenuItem,
    Button
} from "@material-ui/core";

import {
    isRequired,
    isEmail
} from "../../../tools";

import {
    Logo,
    DebugPanel
} from "../../blocks";
import {
    InputField,
    SelectField
} from "../../fields";

import "./index.scss";


export interface FormData {
    first_name:string;
    last_name:string;
    organization:string;
    email:string;
    password:string;
    password_confirmation:string;
}

interface FProps {
}

interface Props extends Partial<ConfigProps<FormData, FProps>> {
    isSignedUp:boolean;
}

interface CProps extends InjectedFormProps<FormData, FProps> {
    isSignedUp:boolean;
}


class SignupForm extends React.PureComponent<CProps> {
    debugFillForm() {
        this.props.autofill("first_name", "Test");
        this.props.autofill("last_name", "Test");
        this.props.autofill("organization", "Test");
        // this.props.autofill("email", process.env.REACT_APP_TEST_EMAIL);
        this.props.autofill("email", "dnc.nikolay.ispandiarov+test4@gmail.com");
        this.props.autofill("password", "1234567890");
        this.props.autofill("password_confirmation", "1234567890");
    }

    render() {
        const {
            handleSubmit,
            submitting,
            isSignedUp
        } = this.props;

        return (
            <Form
              className="form-signup"
              noValidate={true}
              onSubmit={handleSubmit}>
                <Box p={3 / 2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Logo
                                  size="large"
                                  alt="Logo"
                                  variant="circular"
                                  src="/logo.svg" />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Typography component={Box}
                                  variant="h5"
                                  textAlign="center"
                                  pb={1}>
                                    Sign up
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputField
                              validate={isRequired}
                              required
                              type="text"
                              fullWidth
                              label="First name"
                              name="first_name" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputField
                              validate={[isRequired]}
                              required
                              type="text"
                              fullWidth
                              label="Last name"
                              name="last_name" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired]}
                              type="text"
                              required
                              fullWidth
                              label="Company name"
                              name="organization" />
                        </Grid>

                        <Grid item xs={12}>
                            <InputField
                              validate={[isRequired, isEmail]}
                              type="email"
                              required
                              fullWidth
                              label="Email"
                              name="email" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputField
                              validate={[isRequired]}
                              type="password"
                              required
                              fullWidth
                              label="Password"
                              name="password" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputField
                              validate={[isRequired]}
                              type="password"
                              required
                              fullWidth
                              label="Confirm password"
                              name="password_confirmation" />
                        </Grid>

                        <Grid item xs={12}>
                            <Box pt={2} pb={1}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  disabled={submitting}>
                                    Sign up
                                </Button>

                                {isSignedUp && <Typography color="secondary" align="center" style={{marginTop: 8}}>Thank you. Please wait for account to be approved.</Typography>}
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Button component={Link}
                              to="/login">
                                Sign in
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <DebugPanel>
                    <Box p={3 / 2}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item>
                                <Button type="button"
                                  variant="contained"
                                  onClick={() => this.debugFillForm()}>
                                    Fill form
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DebugPanel>
            </Form>
        );
    }
}


export default compose<React.ComponentType<Props>>(
    reduxForm<FormData, FProps>({
        form: "signup",
        destroyOnUnmount: true,
        forceUnregisterOnUnmount: false,
        validate(values:FormData, props:DecoratedFormProps<FormData, FProps>):FormErrors<FormData> {
            const errors:FormErrors<FormData> = {};

            if(values.password && values.password_confirmation) {
                if(values.password !== values.password_confirmation) {
                    errors.password_confirmation = "Passwords do not match";
                }
            }

            return errors;
        }
    })
)(SignupForm);