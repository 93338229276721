import {
    Model,
    STATUS_SUCCESS,
    STATUS_ERROR,
    IResponseGetList
} from "src/makes/Model";


interface ISuggestComment {
    id:string;
    user:any;
    value:number;
    likes:number;
    content:string;
    images:any;
    date:string;
}


class SuggestComment extends Model<ISuggestComment> {
    static modelName:string = "suggest-comment";

    async getList(query:any = {}, count:number = 0, page:number = 0):IResponseGetList<ISuggestComment> {
        const res = await this.dispatch("api/get", `/api/dashboard/${SuggestComment.modelName}`, {
            headers: {
                Authorization: `Bearer ${this.rootGetters.auth.token}`
            },
            data: {
                ...query,
                ...count > 0 || page > 0 ? {
                    count,
                    page: page + 1
                } : {}
            }
        });

        if(typeof res.count === "number") {
            const {
                count: total,
                data: items
            } = res;

            return {
                status: STATUS_SUCCESS,
                total,
                items: Array.isArray(items) ? items : Object.values(items)
            };
        }

        return {
            status: STATUS_ERROR
        };
    }
}


export type {ISuggestComment};

export {SuggestComment};