import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    Route,
    withRouter,
    RouteComponentProps
} from "react-router";
import {
    Grid, Tooltip,
    Typography
} from "@material-ui/core";

import {
    ITileParameter,
    IBounds
} from "../../../../../types";

import {
    PageSidebar,
    PageSidebarHeader,
    PageSidebarContent,
    PageScroll,
    PageBackButton,
    PageTabs,
    PageTab,
    SvgImage
} from "../../../../blocks";

import {
    RatingOverview,
    RatingComments
} from "./blocks";

import store from "../../../../../store";


interface Props {
    parameterId?:string;
    bounds?:IBounds|undefined;
    quadKeys?:string[];
}

interface RState {
    parameter?:ITileParameter;
    getItem:(type:string, id:string) => any;
}

interface RActions {}

interface CProps extends Props, RState, RActions, RouteComponentProps {}


class RatingViewParameter extends React.Component<CProps, any> {
    get isLoading() {
        const {
            parameter
        } = this.props;

        return !parameter;
    }

    onClickBack() {
        const {
            location: {
                search
            },
            history
        } = this.props;

        history.push("/rating-view/parameter" + search);
    }

    render() {
        const {
            location: {
                search
            },
            parameterId,
            parameter,
            bounds,
            quadKeys
        } = this.props;

        return (
            <PageSidebar className="page-rating-view__parameter">
                <Grid container wrap="nowrap">
                    <Grid item
                      className="page-sidebar-parameter__logo">
                        <div style={{
                          position: "relative",
                          width: 116,
                          height: 116
                        }}>
                            <div
                              style={{
                                backgroundImage: `url("${parameter ? parameter.icon || "" : ""}")`,
                                width: 80,
                                height: 80,
                                position: "absolute",
                                zIndex: 2,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)"
                              }} />
                            <div
                              style={{
                                background: "#223840",
                                width: 116,
                                height: 116,
                                position: "absolute"
                              }} />
                        </div>
                    </Grid>

                    <Grid item style={{flex: 1, padding: 0}}>
                        <PageSidebarContent>
                            <PageSidebarHeader
                              style={{padding: 0}}
                              className="page-sidebar-parameter__header"
                              justifyContent="flex-end">
                                <Typography variant="h5">
                                    <PageBackButton
                                      onClick={() => this.onClickBack()} />

                                    {parameter ? parameter.title : ""}
                                </Typography>

                                <PageTabs variant="fullWidth">
                                    <PageTab exact
                                      path="/rating-view/parameter/:parameterId"
                                      to={"/rating-view/parameter/" + parameterId + search}
                                      label="Overview" />

                                    <PageTab
                                      path="/rating-view/parameter/:parameterId/comments"
                                      to={"/rating-view/parameter/" + parameterId + "/comments" + search}
                                      label="Comments" />

                                    <PageTab
                                      path="/rating-view/parameter/:parameterId/intel"
                                      to={"/rating-view/parameter/" + parameterId + "/intel" + search}
                                      label="Intel"
                                      inDevelopment />
                                </PageTabs>
                            </PageSidebarHeader>
                        </PageSidebarContent>
                    </Grid>
                </Grid>

                <Route exact
                  path="/rating-view/parameter/:parameterId">
                    <PageScroll loading={this.isLoading}>
                        <Grid container>
                            <Grid item style={{flex: 1}}>
                                <PageSidebarContent>
                                    <RatingOverview
                                      parameterId={parameterId}
                                      quadKeys={quadKeys}
                                      bounds={bounds} />
                                </PageSidebarContent>
                            </Grid>
                        </Grid>
                    </PageScroll>
                </Route>

                <Route exact
                  path="/rating-view/parameter/:parameterId/comments">
                    <RatingComments
                      parameterId={parameterId}
                      quadKeys={quadKeys}
                      bounds={bounds} />
                </Route>
            </PageSidebar>
        );
    }
}


const putState = (state:any, props:Props):RState => {
    return {
        parameter: store.orm.getters.byId("tile-parameter", props.parameterId),
        getItem: store.orm.getters.byId
    };
};

const putActions = ():RActions => {
    return {};
};

export default compose<React.ComponentType<Props>>(
    connect(putState, putActions),
    withRouter
)(RatingViewParameter);